import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import {
  menuThenMenuSources,
  sources,
  sourcesFilterOptions,
} from "@/IssuesTable/constants";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent, CardHeader } from "@/component/shadcn/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import type {
  Account,
  Category,
  CustomerCompany,
  CustomerGroup,
  GetTopicsResponse,
  ScopeResponse,
  Teams,
  Workflow,
  WorkflowsFilterType,
} from "@/interfaces/serverData";
import { CompanyDropdown } from "@/reusable_components/dropdowns/CompanyDropdown";
import { ContactDropdown } from "@/reusable_components/dropdowns/ContactDropdown";
import {
  WorkflowNodesDropdownTrigger,
  getTopicColors,
} from "@/utilities/methods";
import { ComponentBooleanIcon, TrashIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { Handle, Position } from "@xyflow/react";
import { useEffect, useState } from "react";
import { ConditionIcon } from "../Icons";
import { handleStyle } from "../Icons";

const ConditionNode: React.FC<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any;
  isConnectable: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onUpdate: (id: string, metadata: any) => void;
  categoriesQuery: UseQueryResult<Category[], Error>;
  topicsQuery: UseQueryResult<GetTopicsResponse[], Error>;
  teamsQuery: UseQueryResult<Teams[], Error>;
  customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
  channelsQuery: UseQueryResult<Map<string, ScopeResponse[]>, Error>;
  customers: Account[];
  companies?: Account[];
  workflow?: Workflow;
}> = ({
  data,
  isConnectable,
  onUpdate,
  categoriesQuery,
  topicsQuery,
  teamsQuery,
  customerGroupsQuery,
  channelsQuery,
  customers,
  companies,
  workflow,
}) => {
    // Each element in selected values is [type, value]
    const [selectedValues, setSelectedValues] = useState<WorkflowsFilterType[]>([
      { type: "", enabled: true, id: crypto.randomUUID() },
    ]);

    useEffect(() => {
      if (data.metadata?.filters) {
        const newSelectedValues: WorkflowsFilterType[] = [];
        for (const [key, value] of data.metadata.filters) {
          const [type, id] = key.split("_");
          newSelectedValues.push({
            type: type,
            enabled: true,
            id: id,
          });
        }
        // Add an additional "Add a filter"
        newSelectedValues.push({
          type: "",
          enabled: true,
          id: crypto.randomUUID(),
        });

        setSelectedValues(newSelectedValues);
      }
    }, [workflow]);

    const [filters, setFilters] = useState<
      Map<
        string,
        Set<{
          label: string;
          value: string;
          key: string;
          color: string;
          channels?: ScopeResponse[];
        }>
      >
    >(data.metadata?.filters ?? new Map());

    const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
    const [conditionType, setConditionType] = useState<string>(
      data.metadata?.subtype ?? (workflow ? undefined : "all"),
    );
    const [activeMenu, setActiveMenu] = useState<string | null>(null);
    const [companyIDs, setCompanyIDs] = useState<Map<number, string>>(
      new Map(),
    );
    const [contactIDs, setContactIDs] = useState<Map<number, string[]>>(new Map());

    useEffect(() => {
      // Check if company or customer filters exist in the metadata
      if (data?.metadata?.filters) {
        const filters = data.metadata.filters;

        const initialCompanyIDs = new Map<number, string>();
        const initialContactsIDs = new Map<number, string[]>();
        if (filters instanceof Map) {
          for (const [key, value] of filters.entries()) {
            const parts = key.split("_");
            if (parts[0] === "Company" && value?.size === 1) {
              initialCompanyIDs.set(Number(parts[1]), [...value][0].key);
            } else if (parts[0] === "Customer") {
              initialContactsIDs.set(Number(parts[1]),
                Array.from(value as { label: string; value: string; key: string; color: string; channels?: ScopeResponse[] }[]).map((val) => val.key)
              );
            }
          }
          setCompanyIDs(initialCompanyIDs);
          setContactIDs(initialContactsIDs)
        }
      }
    }, [data]);

    const [companyEditing, setCompanyEditing] = useState<boolean>(false);
    const [contactEditing, setContactEditing] = useState<boolean>(false);

    // Update node's metadata
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedMetadata = { ...data.metadata, name: e.target.value };
      onUpdate(data.id, updatedMetadata); // Update the metadata of the node
      setNameVal(e.target.value);
    };
    const handleSubtypeChange = (value: string) => {
      const updatedMetadata = { ...data.metadata, subtype: value };
      setConditionType(value);
      onUpdate(data.id, updatedMetadata); // Update the metadata of the node
    };
    useEffect(() => {
      const updatedMetadata = { ...data.metadata, filters: filters };
      onUpdate(data.id, updatedMetadata);
    }, [filters, onUpdate]);

    const handleItemSelect =
      (
        type: string,
        option: {
          label: string;
          value: string;
          key: string;
          color: string;
        },
        index?: number,
      ) =>
        () => {
          // Open up another context menu for these sources
          if (sources.map((source) => source.value).includes(option.value)) {
            if (menuThenMenuSources.includes(option.value)) {
              setActiveMenu(option.value);
            } else {
              setActiveMenu(`Source_${index}`);
            }
          }

          const newFilters = new Map(filters);
          if (sources.map((source) => source.value).includes(type)) {
            newFilters.set(`Source_${index}`, new Set([option]));
            setFilters(newFilters);
            return;
          }
          newFilters.set(`${type}_${index}`, new Set([option]));
          setFilters(newFilters);
        };

    const setCompany = (c: CustomerCompany, index?: number) => {
      const newCompanyIDs = new Map(companyIDs);
      newCompanyIDs.set(Number(index), c.id);
      setCompanyIDs(newCompanyIDs);
      const newFilters = new Map(filters);
      newFilters.set(
        `Company_${index}`,
        new Set([
          {
            label: c.name,
            value: c.id,
            key: c.id,
            color: "",
          },
        ]),
      );
      setFilters(newFilters);
      return;
    };

    const toggleContact = (contact: Account, existingContacts: Account[], setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>, index?: number) => {
      let newContacts = Array.from(existingContacts);
      // Remove if apart of the current contacts
      if (newContacts.map((t) => t.id).includes(contact.id)) {
        newContacts = newContacts.filter((t) => t.id !== contact.id);
      } else {
        // Add if it's a new customer that we're adding
        newContacts.push(contact);
      }
      setSelectedContacts(newContacts)
      const newFilters = new Map(filters);
      newFilters.set(
        `Customer_${index}`,
        new Set([
          {
            label: contact.name,
            value: contact.id,
            key: contact.id,
            color: "",
          },
        ]),
      );
      setFilters(newFilters);
      return;
    };

    const handleSelectChange = (value: string, id: string) => {
      const newSelectedValues = [...selectedValues];
      const index = newSelectedValues.findIndex((v) => v.id === id);
      // If setting the last filter to a value, automatically add another filter
      if (index === newSelectedValues.length - 1 && value !== "") {
        newSelectedValues.push({
          type: "",
          enabled: true,
          id: crypto.randomUUID(),
        });
      }
      newSelectedValues[index] = { type: value, enabled: true, id };
      setSelectedValues(newSelectedValues);
      setActiveMenu(`${value}_${id}`);
      const currentFilters = new Map(filters);
      for (const key of currentFilters.keys()) {
        if (key.split("_")[1] === id) {
          currentFilters.delete(key);
        }
      }
      currentFilters.set(`${value}_${id}`, new Set());
      setFilters(currentFilters);
    };

    const handleDeleteFilter = (value: string, id: string) => {
      const newSelectedValues = [...selectedValues];

      // Update filters
      const newFilters = new Map(filters);
      newFilters.delete(`${value}_${id}`);
      setFilters(newFilters);

      setSelectedValues(newSelectedValues.filter((v) => v.id !== id));
    };

    return (
      <div className="flex flex-col items-start">
        <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
          <div className="flex flex-row items-center justify-center gap-1">
            <ComponentBooleanIcon className="text-[#5e6ad2] h-3 w-3" />
            <p className="text-xs">Condition</p>
          </div>
        </Badge>
        <Card
          className={`w-[475px] shadow-none border rounded-tr-lg rounded-bl-lg rounded-br-lg z-10 ${data.errorStyle}`}
        >
          <CardHeader className="px-4">
            <div className="flex flex-col items-start gap-3 w-full">
              <div className="flex items-center gap-2 w-full">
                <ConditionIcon />
                <div className="flex items-center gap-3 w-full">
                  <p className="text-xs font-medium flex-shrink-0">
                    If
                  </p>
                  <Select
                    defaultValue={conditionType}
                    onValueChange={handleSubtypeChange}
                  >
                    <SelectTrigger className="w-[40px] focus:outline-none focus:ring-0 text-xs font-medium hover:bg-muted px-2 py-1 rounded outline outline-1 outline-[#eeeff1] h-5">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">all</SelectItem>
                      <SelectItem value="any">any</SelectItem>
                    </SelectContent>
                  </Select>
                  <p className="text-xs font-medium flex-grow">
                    of these filters match:
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3 w-full">
                {selectedValues.map((filterType) => {
                  const val =
                    Array.from(
                      filters.get(
                        `${filterType.type}_${filterType.id}`,
                      ) ?? [],
                    )[0]?.value ?? "";
                  if (!filterType.enabled) {
                    return null; // If disabled, do not render this element
                  }
                  return (
                    <div
                      className="flex items-center gap-3 w-full ml-8"
                      key={filterType.id}
                    >
                      <Select
                        value={filterType.type}
                        onValueChange={(value) =>
                          handleSelectChange(
                            value,
                            filterType.id,
                          )
                        }
                      >
                        <SelectTrigger
                          className={`w-[120px] text-xs font-medium hover:bg-muted px-2 py-1 rounded h-5 ${conditionType === "all" &&
                            filterType.type && filterType.type !== "Topic" &&
                            Array.from(filters.keys())
                              .filter(
                                (key) =>
                                  key !==
                                  `${filterType.type}_${filterType.id}`,
                              )
                              .some(
                                (key) =>
                                  key.split(
                                    "_",
                                  )[0] ===
                                  filterType.type,
                              )
                            ? "outline outline-1 outline-red-500"
                            : "outline outline-1 outline-[#eeeff1]"
                            }`}
                        >
                          <SelectValue placeholder="Add a filter..." />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="Source">
                            Source
                          </SelectItem>
                          <SelectItem value="Team">
                            Team
                          </SelectItem>
                          <SelectItem value="Company">
                            Company
                          </SelectItem>
                          <SelectItem value="Customer">
                            Customer
                          </SelectItem>
                          <SelectItem value="Customer Group">
                            Customer Group
                          </SelectItem>
                          <SelectItem value="Tag">
                            Interaction Type
                          </SelectItem>
                          <SelectItem value="Topic">
                            Tag
                          </SelectItem>
                          <SelectItem value="Status">
                            Status
                          </SelectItem>
                        </SelectContent>
                      </Select>

                      {filterType.type !== "" && (
                        <>
                          <p className="text-xs font-medium">
                            is
                          </p>
                          {filterType.type ===
                            "Company" ? (
                            <div className="w-[170px]">
                              <CompanyDropdown
                                companyID={
                                  companyIDs.get(
                                    Number(
                                      filterType.id,
                                    ),
                                  ) ?? ""
                                }
                                setCompany={setCompany}
                                editing={companyEditing}
                                setEditing={
                                  setCompanyEditing
                                }
                                index={filterType.id}
                                includeOutline={false}
                              />
                            </div>
                          ) : filterType.type ===
                            "Customer" ? (
                            <div className="w-[170px]">
                              <ContactDropdown
                                selectedContactIDs={contactIDs.get(
                                  Number(
                                    filterType.id,
                                  ),
                                ) ?? []}
                                setContact={toggleContact}
                                editing={contactEditing}
                                setEditing={
                                  setContactEditing
                                }
                                index={filterType.id}
                                condensed={false}
                                includeOutline={false}
                              />
                            </div>
                          ) : (
                            <DropdownMenu>
                              <DropdownMenuTrigger
                                asChild
                                type="button"
                                onChange={() =>
                                  setActiveMenu(
                                    `${filterType.type}_${filterType.id}`,
                                  )
                                }
                              >
                                <Button
                                  variant="outline"
                                  className={`${!filters.get(`${filterType.type}_${filterType.id}`)?.size && "outline outline-1 outline-red-500"} mr-2 justify-between h-8 text-xs px-3 overflow-hidden text-ellipsis break-word w-[170px]`}
                                >
                                  {WorkflowNodesDropdownTrigger(
                                    filterType.type,
                                    filters,
                                    [],
                                    customers,
                                    companies,
                                    categoriesQuery.data ??
                                    [],
                                    getTopicColors(
                                      topicsQuery.data ??
                                      [],
                                    ),
                                    filterType.id,
                                  )}
                                </Button>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent
                                align="start"
                                className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                              >
                                {(filterType.type !==
                                  "Source" ||
                                  activeMenu ===
                                  `Source_${filterType.id}` ||
                                  (activeMenu &&
                                    sourcesFilterOptions
                                      .map(
                                        (
                                          opt,
                                        ) =>
                                          opt.type,
                                      )
                                      .includes(
                                        val.split(
                                          " -- ",
                                        )[0],
                                      ))) && (
                                    <FilterDropdownElement
                                      type={
                                        sourcesFilterOptions
                                          .map(
                                            (
                                              opt,
                                            ) =>
                                              opt.type,
                                          )
                                          .includes(
                                            val,
                                          )
                                          ? val
                                          : filterType.type
                                      }
                                      categories={
                                        categoriesQuery.data ??
                                        []
                                      }
                                      filters={
                                        filters
                                      }
                                      handleItemSelect={
                                        handleItemSelect
                                      }
                                      topics={getTopicColors(
                                        topicsQuery.data ??
                                        [],
                                      )}
                                      users={[]}
                                      companies={
                                        companies
                                      }
                                      customers={
                                        customers
                                      }
                                      customerGroups={
                                        customerGroupsQuery.data ??
                                        []
                                      }
                                      teams={
                                        teamsQuery.data ??
                                        []
                                      }
                                      isSavedViewFilter={
                                        false
                                      }
                                      channels={
                                        channelsQuery?.data ??
                                        new Map()
                                      }
                                      index={filterType.id}
                                    />
                                  )}
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}

                          <Button
                            variant="outline"
                            className="px-1.5"
                            onClick={() =>
                              handleDeleteFilter(
                                filterType.type,
                                filterType.id,
                              )
                            }
                          >
                            <TrashIcon className="w-3.5 h-3.5" />
                          </Button>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </CardHeader>

          <CardContent className="flex flex-col gap-2">
            <Separator />
            <Input
              className="text-xs border-none px-0"
              placeholder="Name..."
              value={nameVal}
              onChange={handleNameChange}
            />
          </CardContent>

          <Handle
            type="source"
            position={Position.Top}
            id="a"
            isConnectable={isConnectable}
            className={`${handleStyle} top-[18px] `}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            isConnectable={isConnectable}
            className={handleStyle}
          />
        </Card>
      </div>
    );
  };

export default ConditionNode;
