import type React from "react";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "./shadcn/ui/dialog";
import { Button } from "./shadcn/ui/button";

interface PopupProps {
    isDialogOpen: boolean;
    closeDialog: () => void;
    description: string;
    isButton: boolean;
    buttonText?: string;
    title: string;
    onButtonClick?: () => void;
    clickOutOf: boolean; // lets a user click out of dialog.
}

// reusable dialog component to use when errors happen!
const DialogComponentHandler: React.FC<PopupProps> = ({
    isDialogOpen,
    description,
    closeDialog,
    isButton,
    buttonText,
    title,
    onButtonClick,
    clickOutOf,
}) => {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        if (!clickOutOf) {
            event.preventDefault();
        }
    };
    return (
        <Dialog open={isDialogOpen} onOpenChange={(open) => closeDialog()}>
            <DialogContent
                onEscapeKeyDown={handlePointerDownOutside}
                onPointerDownOutside={handlePointerDownOutside}
            >
                <div>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogDescription>{description}</DialogDescription>
                    {isButton && (
                        <Button onClick={onButtonClick} mt="30px">
                            {buttonText}
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default DialogComponentHandler;
