import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { ContactsAPI } from "@/constant";
import { URLS } from "@/constant";
import { useDebounce } from "@/hooks/useDebounce";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    CustomerCompany,
    ListCustomersResponse,
} from "@/interfaces/serverData";
import { cn } from "@/lib/utils";
import { CheckIcon } from "@radix-ui/react-icons";
import { Spinner } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Check, X } from "lucide-react";
import { useEffect, useState } from "react";

interface CompanyDropdownProps {
    company?: CustomerCompany;
    companyID: string;
    teamID?: string;
    onSave?: (id: string) => void;
    editing: boolean;
    setEditing: (editing: boolean) => void;
    setCompany: (c: CustomerCompany, index?: number) => void;
    index?: number;
    width?: number;
    includeOutline?: boolean;
}

export const CompanyDropdown = ({
    company,
    companyID,
    teamID,
    onSave,
    editing,
    setEditing,
    setCompany,
    index,
    width,
    includeOutline = true
}: CompanyDropdownProps) => {
    const [companiesSearchQueryValue, setCompaniesSearchQueryValue] =
        useState<string>("");
    const [selectedCompany, setSelectedCompany] = useState<Account>()
    const api = useApi();
    const debouncedSearchQuery = useDebounce(companiesSearchQueryValue, 300);

    useEffect(() => {
        let endpoint = "";
        if (teamID && teamID !== "") {
            endpoint = `/team/${teamID}`;
        }

        const { url, method } = ContactsAPI.listCompanies;
        api.get(
            `${URLS.serverUrl}${url}${endpoint}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                params: {
                    limit: 1,
                    offset: 0,
                    id: companyID,
                },
            },
        ).then((res) => {
            if ((res.status === 200) && (res.data?.data?.data?.length === 1)) {
                setSelectedCompany(res.data.data.data[0]);
            }
        })
            .catch((res) => {
                console.log("Could not grab initial selected company");
            });
    }, [companyID])

    const fetchCompanies = async ({
        pageParam = 0,
        query = "",
    }: {
        pageParam?: number;
        query?: string;
    }): Promise<ListCustomersResponse> => {
        try {
            let endpoint = "";
            if (teamID && teamID !== "") {
                endpoint = `/team/${teamID}`;
            }
            const { url, method } = ContactsAPI.listCompanies;
            const response = await api.get(
                `${URLS.serverUrl}${url}${endpoint}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 100,
                        offset: pageParam,
                        query: query,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data: companiesData,
        fetchNextPage: fetchCompaniesNextPage,
        hasNextPage: companiesHasNextPage,
        isFetchingNextPage: isFetchingCompaniesNextPage,
        refetch: refetchCompanies,
        isLoading: companiesIsLoading,
        isError: companiesIsError,
    } = useInfiniteQuery({
        queryKey: ["companies_query", debouncedSearchQuery],
        queryFn: ({ pageParam = 0 }) =>
            fetchCompanies({ pageParam, query: debouncedSearchQuery }),
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined;
        },
        initialPageParam: 0,
        refetchOnWindowFocus: true,
    });

    const getBadgeForCompany = (company: CustomerCompany) => {
        return (
            <div className="flex items-center gap-1">
                {company.image_url !== "" &&
                    company.image_url !== undefined && (
                        <div className="lb-avatar rounded w-5 h-5">
                            <img
                                className="lb-avatar-image"
                                src={company.image_url}
                                alt={company.name}
                            />

                            <span>{company.name ?? ""}</span>
                        </div>
                    )}
                <div className="flex flex-col items-start gap-1">
                    {company.name !== "" && company.name !== undefined ? (
                        <p className="text-xs pl-1 font-semibold">
                            {company.name}
                        </p>
                    ) : (
                        <p className="text-xs pl-1 font-semibold">
                            <i>Untitled</i>
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const companiesCombinedData =
        companiesData && Array.isArray(companiesData.pages)
            ? [
                ...(company ? [company] : []),
                ...companiesData.pages
                    .filter((page) => page !== null && page !== undefined)
                    .flatMap((page) =>
                        Array.isArray(page.data)
                            ? page.data.filter(
                                (item) =>
                                    item !== null && item !== undefined,
                            )
                            : [],
                    ),
            ]
            : company
                ? [company]
                : [];

    return (
        <div className="flex items-center">
            <Popover modal={true}>
                <PopoverTrigger asChild>
                    <Button
                        variant="outline"
                        className={`${includeOutline && "outline mr-2 outline-1 outline-gray-300"} justify-between h-8 text-xs px-2.5 min-w-[${width ?? 170}px] w-[${width ?? 170}px]`}
                    >
                        <div className="truncate">
                            {companiesCombinedData.find((c) => c.id === companyID)
                                ?.name || "Select company..."}
                        </div>
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-[200px] p-0">
                    <Command>
                        <CommandInput
                            placeholder="Search Company..."
                            className="h-7 text-xs"
                            value={companiesSearchQueryValue}
                            onValueChange={(value) => {
                                setCompaniesSearchQueryValue(value);
                            }}
                        />
                        <CommandList className="max-h-[200px]">
                            {companiesIsLoading ? (
                                <CommandEmpty>
                                    <div className="flex items-center justify-center py-1">
                                        <Spinner className="h-3 w-3 animate-spin" />
                                    </div>
                                </CommandEmpty>
                            ) : (
                                <CommandEmpty>No company found.</CommandEmpty>
                            )}
                            {/* todo: add a "no company" select option */}
                            <CommandGroup>
                                {companiesCombinedData.map((c) => (
                                    <CommandItem
                                        key={c.id}
                                        value={c.name}
                                        onSelect={() => {
                                            setCompany(c, index);
                                        }}
                                        className="text-xs"
                                    >
                                        {getBadgeForCompany(c)}
                                        <Check
                                            className={cn(
                                                "ml-auto",
                                                c.id === companyID
                                                    ? "opacity-100"
                                                    : "opacity-0",
                                            )}
                                        />
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                            {companiesHasNextPage && (
                                <CommandGroup>
                                    <CommandItem
                                        className="text-xs"
                                        onSelect={() => {
                                            if (
                                                companiesHasNextPage &&
                                                !isFetchingCompaniesNextPage
                                            ) {
                                                fetchCompaniesNextPage();
                                            }
                                        }}
                                    >
                                        Show More...
                                    </CommandItem>
                                </CommandGroup>
                            )}
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>

            {editing && setEditing && onSave && (
                <div className="flex flex-row items-center">
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-6 w-6 hover:bg-iris4"
                        aria-label="Cancel edit"
                        onClick={() => {
                            setEditing(false);
                        }}
                    >
                        <X className="h-3 w-3 text-red-500" />
                    </Button>
                    <Button
                        variant="ghost"
                        size="icon"
                        className="h-6 w-6 hover:bg-iris4"
                        aria-label="Save company"
                        onClick={() => {
                            setEditing(false);
                            onSave(companyID);
                        }}
                    >
                        <CheckIcon className="h-3 w-3 text-green-500" />
                    </Button>
                </div>
            )}
        </div>
    );
};
