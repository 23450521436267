import DialogTriggerButton from "@/component/DialogTriggerButton";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Integration,
    SLACreatePayload,
    SLAResponse,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import { PlusIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import type React from "react";
import { useEffect, useState } from "react";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import SLACard from "./SLACard";
import SLAPopup from "./SLAPopup";

const SLAs: React.FC<{ orgID: string }> = ({ orgID }) => {
    const [slas, setSlas] = useState<SLAResponse[]>([]);

    const api = useApi();
    const { toast } = useToast();

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getSLAs}`, {
            headers: { "Content-Type": "application/json" },
        })
            .then((generalRes) => {
                if (generalRes.status === 200) {
                    const data = generalRes.data.data as SLAResponse[];
                    setSlas(data);
                }
            })
            .catch((error) => {
                console.error(
                    "Error fetching general notification settings:",
                    error,
                );
            });
    }, [api]);

    const clearSLADetails = async () => {
        setSelectedChannels([]);
        setSelectedSource("");
        setSelectedTeams([]);
        setShowSelectedChannels(false);
        setShowSelectedTeams(false);
        setBreachTargetInput("0");
        setBreachTargetTimeUnit("");
        setNotificationTimeInput("0");
        setNotificationTimeUnit("");
        setIcon(undefined);
    };

    const saveSLA = async () => {
        const requestData: SLACreatePayload = {
            operation: "CREATE",
            enabled: true,
            source: selectedSource,
            channels: selectedChannels,
            teams: selectedTeams,
            breach_target: Number(breachTargetInput),
            breach_target_unit: breachTargetTimeUnit,
            notification: Number(notificationTimeInput),
            notification_time_unit: notificationTimeUnit,
        };
        api.post(`${URLS.serverUrl}${API.saveSLA}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                    });
                } else {
                    toast({
                        title: "Updated Settings!",
                        description:
                            "Your settings have been updated successfully.",
                    });
                    console.log("reached here!!>>");
                    setSlas(res.data.data);
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                });
            })
            .finally(() => {
                clearSLADetails();
            });
    };

    const [selectedSource, setSelectedSource] = useState<string>("");
    const [options, setOptions] = useState<ScopeResponse[]>([]);
    const [icon, setIcon] = useState<React.ElementType>();
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [selectedTeams, setSelectedTeams] = useState<Teams[]>([]);
    const [breachTargetInput, setBreachTargetInput] = useState<string>("0");
    const [breachTargetTimeUnit, setBreachTargetTimeUnit] = useState("hours");

    const [notificationTimeInput, setNotificationTimeInput] =
        useState<string>("0");
    const [notificationTimeUnit, setNotificationTimeUnit] = useState("hours");

    const [showSelectedChannels, setShowSelectedChannels] =
        useState<boolean>(false);
    const [showSelectedTeams, setShowSelectedTeams] = useState<boolean>(false);

    const handleSLASourceChange = (newSLASource: string) => {
        setSelectedSource(newSLASource);
        setIcon(integrationBackEndDataMappingToSvg.get(newSLASource));
        switch (newSLASource) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(
                    `${URLS.serverUrl}${API.getBotSettingsV2}/${newSLASource}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                )
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => {});
                break;
            }
            case "Gmail": {
                api.get(
                    `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                )
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name,
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems);
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    };

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">SLAs</h2>
                <p className=" text-sm text-gray11">Manage your SLAs</p>
                <div className="flex flex-col items-end gap-2">
                    <SLAPopup
                        triggerElement={
                            <DialogTriggerButton>
                                Add SLA
                                <PlusIcon />
                            </DialogTriggerButton>
                        }
                        selectedSource={selectedSource}
                        setSelectedSource={setSelectedSource}
                        selectedTeams={selectedTeams}
                        setSelectedTeams={setSelectedTeams}
                        options={options}
                        setOptions={setOptions}
                        selectedChannels={selectedChannels}
                        setSelectedChannels={setSelectedChannels}
                        breachTargetInput={breachTargetInput}
                        setBreachTargetInput={setBreachTargetInput}
                        breachTargetTimeUnit={breachTargetTimeUnit}
                        setBreachTargetTimeUnit={setBreachTargetTimeUnit}
                        notificationTimeInput={notificationTimeInput}
                        setNotificationTimeInput={setNotificationTimeInput}
                        notificationTimeUnit={notificationTimeUnit}
                        setNotificationTimeUnit={setNotificationTimeUnit}
                        showSelectedChannels={showSelectedChannels}
                        setShowSelectedChannels={setShowSelectedChannels}
                        showSelectedTeams={showSelectedTeams}
                        setShowSelectedTeams={setShowSelectedTeams}
                        saveSLA={saveSLA}
                        icon={icon}
                        handleSLASourceChange={handleSLASourceChange}
                        subChannelsView={false}
                        editing={false}
                        clearSLADetails={clearSLADetails}
                        orgID={orgID}
                    />
                </div>

                <div className="flex flex-col gap-3 mt-4">
                    {slas.map((sla) => {
                        return (
                            <SLACard
                                key={sla.id}
                                sla={sla}
                                setSlas={setSlas}
                                clearSLADetails={clearSLADetails}
                                orgID={orgID}
                            />
                        );
                    })}
                </div>
            </Box>
        </div>
    );
};

export default SLAs;
