import IssuesList from "@/IssuesTable/IssuesList";
import { Button } from "@/component/shadcn/ui/button";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    AccountsLabel,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    IconEntry,
    Integration,
    OrgInfoResponse,
    QueriesWithPaginationResponse,
    ScopeResponse,
    Teams,
    Topic,
} from "@/interfaces/serverData";
import { TeamBadges } from "@/pages/WorkspacePreferences/TeamBadges";
import {
    assigneeBadge,
    getBadgeForAccountsLabel,
    lastInteractionBadge,
    ticketCountBadge,
} from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import {
    ImageIcon,
    Pencil2Icon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Callout,
    DropdownMenu,
    Flex,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import { MailIcon, SendIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { useParams } from "react-router-dom";
import { IssueListType } from "../AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "../Integrations/constant";
import AccountPopup from "./AccountPopup";
import type { AccountsListType } from "./Accounts";
import { SendNewMessageDialog } from "./SendNewMessageDialog";

export interface CustomerProps {
    orgID: string;
    userID: string;
    listType: AccountsListType;
}

export const CustomerPage = ({ orgID, userID, listType }: CustomerProps) => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const queryClient = useQueryClient();

    const { customer_id } = useParams<{ customer_id: string }>();

    const api = useApi();

    // const [customer, setCustomer] = useState<Account>();

    const [loadingState, setLoadingState] = useState<number>(0);
    const [needsResponseCount, setNeedsResponseCount] = useState<number>();
    const [breachingCount, setBreachingCount] = useState<number>();



    const fetchCustomer = async () => {
        const { url } = ContactsAPI.getCustomer;
        const response = await api.get(
            `${URLS.serverUrl}${url}/${customer_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        return response.data.data;
    };

    const {
        data: customer,
        isLoading: loadingCustomer,
        isError,
        refetch: refetchCustomer,
    } = useQuery({
        queryKey: ["customer", customer_id],
        queryFn: fetchCustomer,
    });

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesByAccount,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        customer_id: customer_id,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
        refetch: refetchTickets,
    } = useInfiniteQuery({
        queryKey: [`customer_tickets_${customer_id}`],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                )
            : [];

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    useEffect(() => {
        let nrCount = 0;
        let bCount = 0;

        for (const interaction of combinedData) {
            if (interaction.ticket_status === "NeedsResponse") {
                nrCount += 1;
            }
            if (interaction.ticket_status === "Breaching") {
                bCount += 1;
            }
        }
        setNeedsResponseCount(nrCount);
        setBreachingCount(bCount);
    }, [combinedData]);

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["customer_tickets"],
            exact: true,
        });
        refetchCustomer();
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const [topics, setTopics] = useState<Topic[]>([]);
    const [topicsMap, setTopicsMap] = useState(new Map());

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: Topic[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                            key: topic.id,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customer_groups"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const channelsQuery = useQuery<Map<string, ScopeResponse[]>>({
        queryKey: ["channels"],
        queryFn: async () => {
            const theMap = new Map<string, ScopeResponse[]>();
            // Fetching the orgInfo first
            const res = await fetch(URLS.serverUrl + API.getItemsByOrgID, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
                body: JSON.stringify({
                    types: [
                        "Slack",
                        "CommunitySlack",
                        "Discord",
                        "Google",
                        "API",
                    ],
                }),
            });

            if (res.ok) {
                const orgInfo: OrgInfoResponse = (await res.json()).data;

                // Handle Slack scopes asynchronously
                if (orgInfo.Slack) {
                    api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/Slack`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Slack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle CommunitySlack scopes asynchronously
                if (orgInfo.CommunitySlack) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/CommunitySlack`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("CommunitySlack", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Handle Discord scopes asynchronously
                if (orgInfo.Discord) {
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/Discord`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const dataItems: ScopeResponse[] =
                                    res.data.data?.asm_ticket_channels;
                                theMap.set("Discord", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab Google Integrations
                if (orgInfo.Google) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/Google`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("Gmail", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }

                // Grab API integrations
                if (orgInfo.API) {
                    api.get(
                        `${URLS.serverUrl}${API.getUniqueIntegrations}/API`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const integrationsResponse: Integration[] =
                                    res.data.data;
                                const dataItems: ScopeResponse[] = [];
                                for (const integration of integrationsResponse) {
                                    const scope: ScopeResponse = {
                                        key: integration.id,
                                        name: integration.unique_name,
                                    };
                                    dataItems.push(scope);
                                }
                                theMap.set("API", dataItems);
                            }
                        })
                        .catch((res) => {
                            console.error("Error fetching scope data:", res);
                        });
                }
            }

            return theMap;
        },
    });

    // Only showing teams that the member is apart of in the filter
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const [serviceIcons, setServiceIcons] = useState<Set<IconEntry>>(
        new Set<IconEntry>(),
    );
    useEffect(() => {
        const serviceNames = Object.keys(customer?.metadata ?? {});
        const serviceIcons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                serviceIcons.add({
                    Component: IconComponent,
                    props: {
                        width: 20,
                        height: 20,
                        style: {
                            marginLeft: "-2px",
                            marginRight: "-2px",
                        },
                    },
                });
            }
        });
        setServiceIcons(serviceIcons);
    }, [customer?.metadata]);

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <div className="flex items-center justify-between">
                    <div className="flex flex-row items-center gap-3.5 pb-4 ml-5">
                        {customer?.image_url !== "" &&
                            customer?.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-12 h-12">
                                <img
                                    className="lb-avatar-image"
                                    src={customer.image_url}
                                    alt={customer.name}
                                />

                                <span>{customer.name ?? ""}</span>
                            </div>
                        ) : (
                            <ImageIcon className="w-6 h-6 ml-2" />
                        )}
                        <div className="flex flex-col gap-1">
                            <div className="flex items-center gap-3 text-lg font-semibold">
                                {customer?.name}
                                {customer?.last_ticket &&
                                    lastInteractionBadge(customer.last_ticket)}
                            </div>
                            <div className="flex items-center gap-1.5">
                                {customer?.domain !== "" && (
                                    <Badge
                                        color="iris"
                                        size="2"
                                        radius="full"
                                        variant="outline"
                                        className="text-xs"
                                    >
                                        <MailIcon className="w-3.5 h-3.5" />
                                        {customer?.domain}
                                    </Badge>
                                )}
                                {customer?.company && (
                                    <Badge
                                        className="text-muted-foreground flex items-center gap-1"
                                        variant="outline"
                                    >
                                        {customer.company.image_url !== "" &&
                                            customer.company.image_url !==
                                            undefined ? (
                                            <div className="lb-avatar rounded w-5 h-5">
                                                <img
                                                    className="lb-avatar-image w-4 h-4 my-0.5"
                                                    src={
                                                        customer.company
                                                            .image_url
                                                    }
                                                    alt={customer.company.name}
                                                />
                                            </div>
                                        ) : (
                                            <div className="lb-avatar rounded w-5 h-5">
                                                <ImageIcon className="w-4 h-4 mx-1" />
                                            </div>
                                        )}
                                        {customer.company.name}
                                    </Badge>
                                )}
                                {customer?.metadata && (
                                    <div className="flex flex-row mx-1">
                                        {Array.from(serviceIcons).map((icon) =>
                                            React.createElement(
                                                icon.Component,
                                                icon.props,
                                            ),
                                        )}
                                    </div>
                                )}
                                {breachingCount !== undefined &&
                                    ticketCountBadge(
                                        "Breaching",
                                        breachingCount,
                                    )}
                                {needsResponseCount !== undefined &&
                                    ticketCountBadge(
                                        "NeedsResponse",
                                        needsResponseCount,
                                    )}
                                {customer?.labels?.map((label: AccountsLabel) =>
                                    getBadgeForAccountsLabel(label),
                                )}
                                <TeamBadges
                                    teams={customer?.teams ?? []}
                                    defaultIsWorkspace={false}
                                />
                                {assigneeBadge(
                                    customer?.assignee_user_id,
                                    usersQuery.data ?? [],
                                )}
                                <SendNewMessageDialog
                                    accountType="customer"
                                    triggerElement={
                                        <Button className="text-xs" size="sm">
                                            <div className="flex items-center gap-1.5 w-full">
                                                <SendIcon className="w-3 h-3" />
                                                <div>Send Checkin</div>
                                            </div>
                                        </Button>}
                                    account={customer}
                                    refetchAccount={refetchCustomer}
                                    refetchTickets={refetchTickets} />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center absolute top-4 right-4 my-4">
                        <DropdownMenu.Root>
                            <DropdownMenu.Trigger>
                                <Button
                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                    size="sm"
                                    variant="outline"
                                >
                                    Edit
                                    <Pencil2Icon />
                                </Button>
                            </DropdownMenu.Trigger>
                            <DropdownMenu.Content>
                                {!loadingCustomer && (
                                    <AccountPopup
                                        triggerElement={
                                            <DropdownMenu.Item
                                                onSelect={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                Edit Customer
                                            </DropdownMenu.Item>
                                        }
                                        editing={true}
                                        type="Customer"
                                        updateData={updateData}
                                        editingObject={customer}
                                        teamsQuery={teamsQuery}
                                        usersQuery={usersQuery}
                                        userID={userID}
                                        orgID={orgID}
                                        listType={listType}
                                        company={customer?.company}
                                    />
                                )}
                            </DropdownMenu.Content>
                        </DropdownMenu.Root>
                    </div>
                </div>
                {loadingState === 0 && (
                    <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {loadingState === 2 && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === 1 && combinedData.length !== 0 && (
                    <div className="flex flex-col gap-2 px-2">
                        <IssuesList
                            issues={combinedData}
                            topics={topics}
                            topicsMap={topicsMap}
                            userID={userID}
                            usersQuery={usersQuery}
                            customerGroupsQuery={customerGroupsQuery}
                            listType={IssueListType.Issues}
                            channelsQuery={channelsQuery}
                            teamsQuery={teamsQuery}
                            refetch={refetchTickets}
                        />
                    </div>
                )}

                {loadingState === 1 && combinedData.length === 0 && (
                    <div className="flex w-full h-[50%] justify-center items-center">
                        <p className="text-md text-gray12">No tickets yet.</p>
                    </div>
                )}
            </Box>
        </Flex>
    );
};
