import DialogTriggerButton from "@/component/DialogTriggerButton";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Card } from "@/component/shadcn/ui/card";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";
import { TooltipProvider } from "@/component/shadcn/ui/tooltip";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    UpdateWorkflowRequest,
    Workflow,
} from "@/interfaces/serverData";
import {
    lastTriggered,
    workflowEnabled,
    workflowFrequencyBadge,
    workflowTypeBadge,
    workflowsAreEqual,
} from "@/utilities/methods";
import { ContextMenuItem } from "@radix-ui/react-context-menu";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import WorkflowDisplay from "./WorkflowDisplay";

interface WorkflowsPageProps {
    isAdmin: boolean;
    teamID?: string;
}

const WorkflowsPage: React.FC<WorkflowsPageProps> = ({ isAdmin, teamID }) => {
    const api = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const { toast } = useToast();

    const [workflowSelected, setWorkflowSelected] = useState<Workflow>();
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [enableDialogOpen, setEnableDialogOpen] = useState(false);

    const navigateToWorklowEditor = async (workflowID?: string) => {
        navigate("/preferences/workflows/create");
    };

    useEffect(() => {
        if (location.state?.showToast) {
            toast({
                title: location.state.toastText,
                variant: location.state.toastText.includes("Oops")
                    ? "destructive"
                    : "default",
            });
            window.history.replaceState(
                {},
                document.title,
                window.location.href,
            );
        }
    }, [location, toast]);

    // Grab the list of topics for the org
    useEffect(() => {
        let endpoint = URLS.serverUrl + API.getTopics;
        if (teamID) {
            endpoint = `${URLS.serverUrl}${API.getTopics}/team/${teamID}`;
        }
        api.get(endpoint, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const fetchWorkflows = async (): Promise<Workflow[]> => {
        const response: AxiosResponse<{ data: Workflow[] }> = await api.get(
            `${URLS.serverUrl}${API.getWorkflowsNew}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch workflows");
    };

    const fetchTeamWorkflows = async (): Promise<Workflow[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getWorkflowsNew}/team/${teamID}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };

    const {
        data: workflows = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Workflow[]>({
        queryKey: teamID ? [`teamWorkflowsNew_${teamID}`] : ["workflowsNew"],
        queryFn: teamID ? () => fetchTeamWorkflows() : () => fetchWorkflows(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const deleteWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            return api.delete(
                `${URLS.serverUrl}${API.deleteWorkflowNew}/${id}`,
            );
        },
        onSuccess: () => {
            refetch();
            setDeleteDialogOpen(false);
            setWorkflowSelected(undefined);
            toast({
                title: "Deleted workflow!",
                variant: "default",
            });
        },
        onError: (error) => {
            console.error("Delete workflow failed:", error.message);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const disableWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            const requestData: UpdateWorkflowRequest = {
                enabled: false,
            };
            return api.patch(
                `${URLS.serverUrl}${API.updateWorkflowNew}/${id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
        },
        onSuccess: () => {
            refetch();
            toast({
                title: "Disabled workflow!",
                variant: "default",
            });
        },
        onError: (error) => {
            console.error("Disable workflow failed:", error.message);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const enableWorkflowMutation = useMutation({
        mutationFn: (id: string) => {
            const requestData: UpdateWorkflowRequest = {
                enabled: true,
            };
            return api.patch(
                `${URLS.serverUrl}${API.updateWorkflowNew}/${id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
        },
        onSuccess: () => {
            refetch();
            toast({
                title: "Enabled workflow!",
                variant: "default",
            });
        },
        onError: (error) => {
            console.error("Enable workflow failed:", error.message);
            toast({
                title: "Oops! Something's wrong.",
                description: "Please try again at a later time.",
                variant: "destructive",
            });
        },
    });

    const handleEnableWorkflow = (workflow: Workflow) => {
        if (workflow.enabled) {
            // Disable the workflow
            disableWorkflowMutation.mutate(workflow.id ?? "");
        } else {
            // Enable the workflow
            enableWorkflowMutation.mutate(workflow.id ?? "");
        }
    };

    useEffect(() => {
        const newWorkflow = workflows.find(
            (w) => w.id === workflowSelected?.id,
        );
        if (!workflowsAreEqual(newWorkflow, workflowSelected)) {
            setWorkflowSelected(newWorkflow);
        }
    }, [workflows]);

    return (
        <div>
            <Box mt={"3%"} ml={"3%"} mr={"3%"} className="flex flex-col gap-2">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-semibold">Workflows</h2>
                        <p className="text-sm text-gray11">
                            View and monitor your existing workflows.
                        </p>
                    </div>
                    {isAdmin && (
                        <DialogTriggerButton
                            onClick={() => navigateToWorklowEditor()}
                        >
                            Add Workflow
                            <PlusIcon />
                        </DialogTriggerButton>
                    )}
                </div>

                {isLoading && (
                    <div>
                        <Skeleton>
                            <Text>
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    </div>
                )}
                {!isLoading && !isError && (
                    <div className="max-h-full">
                        <TooltipProvider delayDuration={0}>
                            <ResizablePanelGroup
                                direction="horizontal"
                                onLayout={(sizes: number[]) => {
                                    document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                                        sizes,
                                    )}`;
                                }}
                                className="h-fit items-stretch relative z-10"
                            >
                                <ResizablePanel
                                    defaultSize={
                                        workflowSelected !== undefined
                                            ? window.innerWidth * 0.35
                                            : window.innerWidth
                                    }
                                    key="workflowsList"
                                    className="flex py-2 pr-2 max-h-[calc(85vh)] overflow-y-auto"
                                >
                                    {" "}
                                    {workflows?.length === 0 &&
                                        !isLoading &&
                                        !isError && (
                                            <p className="text-sm">
                                                No workflows found. Create a new
                                                workflow to get started!
                                            </p>
                                        )}
                                    <div className="flex-1 overflow-y-auto">
                                        {workflows?.map((workflow) => (
                                            <Card
                                                key={workflow.id}
                                                className="rounded-lg shadow-none flex flex-col gap-2.5 overflow-hidden my-1"
                                            >
                                                <ContextMenu>
                                                    <ContextMenuTrigger
                                                        className={`hover:bg-muted items-start w-full px-4 py-6 ${workflowSelected?.id === workflow.id ? "bg-sidebarBorder" : ""}`}
                                                        onClick={() =>
                                                            setWorkflowSelected(
                                                                workflow,
                                                            )
                                                        }
                                                    >
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex flex-col gap-0.5 items-start">
                                                                <div className="font-semibold text-sm flex flex-row items-center gap-2 w-full flex-nowrap">
                                                                    {workflowEnabled(
                                                                        workflow,
                                                                    )}
                                                                    <span className="text-ellipsis overflow-hidden whitespace-nowrap">
                                                                        {
                                                                            workflow.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    {
                                                                        workflow.description
                                                                    }
                                                                </p>
                                                                {workflowSelected && (
                                                                    <div className="flex flex-wrap items-center gap-2 pt-1">
                                                                        {workflowTypeBadge(
                                                                            workflow,
                                                                        )}
                                                                        {lastTriggered(
                                                                            workflow,
                                                                        )}
                                                                        {workflowFrequencyBadge(
                                                                            workflow,
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {!workflowSelected && (
                                                                <div className="flex items-center gap-2">
                                                                    {workflowTypeBadge(
                                                                        workflow,
                                                                    )}
                                                                    {lastTriggered(
                                                                        workflow,
                                                                    )}
                                                                    {workflowFrequencyBadge(
                                                                        workflow,
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </ContextMenuTrigger>

                                                    <ContextMenuContent className="w-25">
                                                        <AlertDialog
                                                            open={
                                                                deleteDialogOpen
                                                            }
                                                            onOpenChange={
                                                                setDeleteDialogOpen
                                                            }
                                                        >
                                                            <AlertDialogTrigger
                                                                asChild
                                                            >
                                                                <ContextMenuItem
                                                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5 p-2"
                                                                    onSelect={(
                                                                        e,
                                                                    ) =>
                                                                        e.preventDefault()
                                                                    }
                                                                >
                                                                    <TrashIcon className="w-3.5 h-3.5" />
                                                                    Delete
                                                                </ContextMenuItem>
                                                            </AlertDialogTrigger>
                                                            <AlertDialogContent>
                                                                <div>
                                                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                        <AlertDialogTitle>
                                                                            {`Delete "${workflow.name}"?`}
                                                                        </AlertDialogTitle>
                                                                        <AlertDialogDescription>
                                                                            <div>
                                                                                Scheduled
                                                                                workflow
                                                                                steps
                                                                                will
                                                                                be
                                                                                cancelled
                                                                                so
                                                                                in-progress
                                                                                workflow
                                                                                runs
                                                                                may
                                                                                not
                                                                                fully
                                                                                complete.
                                                                                This
                                                                                will
                                                                                permanently
                                                                                delete
                                                                                the
                                                                                workflow
                                                                                and
                                                                                cannot
                                                                                be
                                                                                undone.
                                                                            </div>
                                                                        </AlertDialogDescription>
                                                                    </AlertDialogHeader>
                                                                </div>
                                                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                    <AlertDialogCancel
                                                                        onClick={() =>
                                                                            setDeleteDialogOpen(
                                                                                false,
                                                                            )
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </AlertDialogCancel>
                                                                    <AlertDialogAction
                                                                        onClick={() => {
                                                                            deleteWorkflowMutation.mutate(
                                                                                workflow.id ??
                                                                                "",
                                                                            );
                                                                        }}
                                                                        className="bg-red-500 text-white hover:bg-red-700"
                                                                    >
                                                                        Delete
                                                                    </AlertDialogAction>
                                                                    <AlertDialogCancel
                                                                        className="shadow-none absolute top-0 right-2 px-2 py-2 "
                                                                        onClick={() =>
                                                                            setDeleteDialogOpen(
                                                                                false,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Cross2Icon />
                                                                    </AlertDialogCancel>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                        <AlertDialog
                                                            open={
                                                                enableDialogOpen
                                                            }
                                                            onOpenChange={
                                                                setEnableDialogOpen
                                                            }
                                                        >
                                                            <AlertDialogTrigger
                                                                asChild
                                                            >
                                                                <ContextMenuItem
                                                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5 p-2"
                                                                    onSelect={(
                                                                        e,
                                                                    ) =>
                                                                        e.preventDefault()
                                                                    }
                                                                >
                                                                    {workflow.enabled ? (
                                                                        <>
                                                                            <CrossCircledIcon className="w-3.5 h-3.5" />
                                                                            Disable
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <CheckCircledIcon className="w-3.5 h-3.5" />
                                                                            Enable
                                                                        </>
                                                                    )}
                                                                </ContextMenuItem>
                                                            </AlertDialogTrigger>
                                                            <AlertDialogContent>
                                                                <div>
                                                                    <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                                        <AlertDialogTitle>
                                                                            {workflow.enabled
                                                                                ? `Disable "${workflow.name}"?`
                                                                                : `Enable "${workflow.name}"?`}
                                                                        </AlertDialogTitle>
                                                                        <AlertDialogDescription>
                                                                            <div>
                                                                                {workflow.enabled
                                                                                    ? "Scheduled workflow steps will be cancelled so in-progress workflow runs may not fully complete."
                                                                                    : "If the workflow has a time is trigger block, enabling the workflow will automatically run the workflow right now."}
                                                                            </div>
                                                                        </AlertDialogDescription>
                                                                    </AlertDialogHeader>
                                                                </div>
                                                                <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                                    <AlertDialogCancel
                                                                        onClick={() =>
                                                                            setEnableDialogOpen(
                                                                                false,
                                                                            )
                                                                        }
                                                                    >
                                                                        Cancel
                                                                    </AlertDialogCancel>
                                                                    <AlertDialogAction
                                                                        onClick={() => {
                                                                            handleEnableWorkflow(
                                                                                workflow,
                                                                            );
                                                                        }}
                                                                    >
                                                                        {workflow.enabled
                                                                            ? "Disable"
                                                                            : "Enable"}
                                                                    </AlertDialogAction>
                                                                    <AlertDialogCancel
                                                                        className="shadow-none absolute top-0 right-2 px-2 py-2 "
                                                                        onClick={() =>
                                                                            setEnableDialogOpen(
                                                                                false,
                                                                            )
                                                                        }
                                                                    >
                                                                        <Cross2Icon />
                                                                    </AlertDialogCancel>
                                                                </AlertDialogFooter>
                                                            </AlertDialogContent>
                                                        </AlertDialog>
                                                    </ContextMenuContent>
                                                </ContextMenu>
                                            </Card>
                                        ))}
                                    </div>
                                </ResizablePanel>
                                {workflowSelected !== undefined && (
                                    <>
                                        <ResizableHandle />
                                        <ResizablePanel
                                            defaultSize={
                                                window.innerWidth * 0.65
                                            }
                                            key="ticketInfo"
                                            className="flex flex-col h-full mx-3"
                                        >
                                            <WorkflowDisplay
                                                workflow={workflowSelected}
                                                refetch={refetch}
                                                isAdmin={isAdmin}
                                            />
                                        </ResizablePanel>
                                    </>
                                )}
                            </ResizablePanelGroup>
                        </TooltipProvider>
                    </div>
                )}
                {isError && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </Box>
        </div>
    );
};
export default WorkflowsPage;
