import { Badge } from "@/component/shadcn/ui/badge";
import { ChevronDown } from "lucide-react";

export interface EmailRecipient {
    email: string;
    name?: string;
}

interface FromPluginTopProps {
    initialFrom?: EmailRecipient;
    setIsReplyFromPluginOpen: (isOpen: boolean) => void;
    isReplyFromPluginOpen: boolean;
    gmailEnabled: boolean;
    className?: string;
}

export function FromPluginTop({
    initialFrom,
    setIsReplyFromPluginOpen,
    isReplyFromPluginOpen,
    gmailEnabled,
    className,
}: FromPluginTopProps) {
    return (
        <div className={`${className} -mb-1 ml-[1px] pb-[4px] cursor-pointer`}>
            {initialFrom && (
                <Badge
                    className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#e2e8f0] outline-1 hover:bg-[#eceefb] hover:text-[#5e6ad2] active:bg-[#eceefb] active:text-[#5e6ad2]"
                    onClick={() =>
                        setIsReplyFromPluginOpen(!isReplyFromPluginOpen)
                    }
                >
                    <div className="flex flex-row items-center justify-center gap-1">
                        <p className="text-xs text-black">via </p>
                        <div>
                            {initialFrom.name && `${initialFrom.name} `}
                            <span className="text-[#5e6ad2]">
                                {initialFrom.email}
                            </span>
                        </div>
                        {gmailEnabled && (
                            <div className="transition-transform duration-200">
                                <ChevronDown
                                    className={`h-3 w-3 transform ${
                                        isReplyFromPluginOpen
                                            ? "rotate-180"
                                            : ""
                                    }`}
                                />
                            </div>
                        )}
                    </div>
                </Badge>
            )}
        </div>
    );
}
