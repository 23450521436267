import {
    Box,
    Callout,
    Text,
} from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { loadingTypes } from "../../../constant";
import { useApi } from "../../../interfaces/api";

import "../toast_styles.css";
import { PlusIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { API, URLS } from "../../../constant";
import type { ScopeResponse } from "../../../interfaces/serverData";
import { useSearch } from "../../SearchContext";
import { Dialog, DialogContent, DialogTrigger } from "@/component/shadcn/ui/dialog";
import { Button } from "@/component/shadcn/ui/button";
import DialogTriggerButton from "@/component/DialogTriggerButton";
import { Skeleton } from "@/component/shadcn/ui/skeleton";
import { Separator } from "@/component/shadcn/ui/separator";
import { Card } from "@/component/shadcn/ui/card";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/component/shadcn/ui/select";

interface ConnectorProps {
    saveSettings: (
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
        connectionPriority: string,
    ) => void;
    enabledIntegrations: string[];
    botType: string;
    description: string;
}

const Shortcuts = (props: ConnectorProps) => {
    console.log("enabled integrations", props.enabledIntegrations)
    const api = useApi();
    const integrationMap = new Map<string, string>([
        ["GitHub Ticket", "GitHubTicket"],
        ["Linear Ticket", "Linear"],
        ["Jira Ticket", "Jira"],
        ["Intercom Ticket", "Intercom"],
        ["Intercom Low Priority Ticket", "Intercom"],
        ["Intercom Medium Priority Ticket", "Intercom"],
        ["Intercom High Priority Ticket", "Intercom"],
    ]);


    const { searchBarFilters, loading } = useSearch();

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    ); // 0: loading, 1: loaded, 2: error

    const [loadingDropdown, setLoadingDropdown] = useState<number>(
        loadingTypes.loading,
    );

    const [integrations, setIntegrations] = useState<string[]>([]);
    const [selectedIntegration, setSelectedIntegration] = useState<string>(
        integrations[0],
    );
    console.log("integrations are", integrations)

    const [modalOpen, setModalOpen] = useState(false);

   
    function save() {
        setModalOpen(false);
        const priorityMapping = new Map<string, string>([
            ["Intercom Low Priority Ticket", "Low"],
            ["Intercom Medium Priority Ticket", "Medium"],
            ["Intercom High Priority Ticket", "High"],
        ]);

        const mapping = integrationMap.get(selectedIntegration);
        console.log("mapping is ", mapping)
        const priority = priorityMapping.get(selectedIntegration) ?? ""
        if (mapping !== undefined) {
            if (props.enabledIntegrations.includes(mapping)) {
                props.saveSettings(mapping, [selectedSourceMetadata], priority);
            } else {
                // navigate(-1);
                console.log("errored")
            }
        } else {
            console.log("errored else")
            // navigate(-1);
        }
    }

    const [selectedSourceMetadata, setSelectedSourceMetadata] =
        useState<ScopeResponse>({ key: "", name: "" });
    function updateSelectedSourceMetadata(value: string) {
        const selectedItem: ScopeResponse = JSON.parse(value);
        setSelectedSourceMetadata(selectedItem);
    }

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [sources, setSources] = useState<ScopeResponse[]>([]);

    useEffect(() => {
        if (!modalOpen) return;
        const integrationMap = new Map<string, string>([
            ["GitHubTicket", "GitHub Ticket"],
            ["Linear", "Linear Ticket"],
            ["Jira", "Jira Ticket"],
        ]);

        const integrationsList: string[] = [];
        for (const integration of props.enabledIntegrations) {
            // Show different priorities for intercom shortcut
            if (integration === "Intercom") {
                integrationsList.push("Intercom Low Priority Ticket")
                integrationsList.push("Intercom Medium Priority Ticket")
                integrationsList.push("Intercom High Priority Ticket")
            } else {
                const mapping = integrationMap.get(integration);
                if (mapping !== undefined) {
                    integrationsList.push(mapping);
                }
            }
        }
        setIntegrations(integrationsList);
        console.log("enabled integrations", integrationsList)
        console.log("in here")
        if (integrationsList.length > 0) {
            setSelectedIntegration(integrationsList[0]);
        }

        setLoadingState(loadingTypes.loaded);
    }, [props.enabledIntegrations, modalOpen]);

    useEffect(() => {
        setLoadingDropdown(loadingTypes.loading);
        const mapping = integrationMap.get(selectedIntegration);
        if (mapping) {
            const requestData = {
                type: mapping,
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setSources(dataItems);
                    if (dataItems.length > 0) {
                        setSelectedSourceMetadata(dataItems[0]);
                    }
                    setLoadingDropdown(loadingTypes.loaded);
                })
                .catch((res) => {
                    // TODO: remove this once intercom is supported, hardcoding for now
                    console.log("selected integration is", selectedIntegration)
                    if (selectedIntegration === "Intercom Ticket") {
                        const dummyScopeResponse: ScopeResponse = {
                            key: "intercomEx",
                            name: "intercomEx"
                        }
                        setSources([dummyScopeResponse])
                        setSelectedSourceMetadata(dummyScopeResponse);
                        setLoadingDropdown(loadingTypes.loaded);
                    } else {
                        setLoadingDropdown(loadingTypes.error);
                    }
                });
        }
    }, [selectedIntegration, api]);

    return (
        <div>
            <Dialog open={modalOpen} onOpenChange={setModalOpen}>
                <DialogTrigger>
                    <DialogTriggerButton>
                        <PlusIcon />
                    </DialogTriggerButton>
                </DialogTrigger>
                <DialogContent
                    style={{
                        width: "fit-content",
                        maxWidth: "450px",
                        paddingBottom: "30px",
                        paddingRight: "30px",
                        paddingLeft: "30px",
                    }}
                >
                    <div className="flex flex-col gap-1">
                        <div className="flex flex-col">
                            <div className="flex justify-between items-center">
                                <h1 className="text-[16px] font-medium text-[#333333]">
                                    Create Shortcuts from {props.botType}
                                </h1>
                            </div>
                            <Separator
                                className="w-full my-4"
                            />
                        </div>

                        {loadingState === loadingTypes.loading && (
                            <Card style={{ flex: 1, padding: "20px" }}>
                                <Skeleton>
                                    <Text>{loremIpsum}</Text>
                                </Skeleton>
                            </Card>
                        )}
                        {loadingState === loadingTypes.error && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong! Please notify us
                                    at support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}
                        {loadingState === loadingTypes.loaded && (
                            <div className="flex flex-col gap-2 items-start">
                                <span className="mb-4 text-[14px] text-[#333333] font-normal">
                                    {props.description}
                                </span>

                                <Text size="2" weight={"medium"}>
                                    Integration:
                                </Text>

                                {props.enabledIntegrations.length > 0 ? (
                                    <Select
                                        onValueChange={setSelectedIntegration}
                                        defaultValue={integrations[0]}
                                    >
                                        <SelectTrigger className="mb-4 box-border border p-4">
                                            <SelectValue placeholder="Select an integration" />
                                        </SelectTrigger>
                                        <SelectContent className="p-0">
                                            {integrations.map((integration) => {
                                                if (integration === "Intercom Ticket") {
                                                    ["Intercom Low Priority Ticket", "Intercom Medium Priority", "Intercom High Priority"].map(
                                                        intercomPriority => {
                                                            return <SelectItem
                                                                key={intercomPriority}
                                                                value={intercomPriority}
                                                            >
                                                                {intercomPriority}
                                                            </SelectItem>
                                                        }
                                                    )
                                                }
                                                return (
                                                    <SelectItem
                                                        key={integration}
                                                        value={integration}
                                                    >
                                                        {integration}
                                                    </SelectItem>
                                                );
                                            })}
                                        </SelectContent>
                                    </Select>
                                ) : (
                                    <Text color="red" size="1">
                                        Oops! Looks like you'll need to set up
                                        your GitHub, Intercom, Jira, or Linear integration
                                        first to use the bot.
                                    </Text>
                                )}
                                {loadingDropdown === loadingTypes.loading && (
                                    <Skeleton>
                                        <div className="flex flex-col items-center">
                                            <Text className="text-[16px] font-medium text-[#333333]">
                                                Select where to create a ticket:
                                            </Text>
                                            <Box height="3vh" width="40vh" />
                                        </div>
                                    </Skeleton>
                                )}
                                {loadingDropdown === loadingTypes.loaded && (
                                    <div className="flex flex-col gap-2 w-full">
                                        <Text className="text-[14px] font-medium text-[#333333]">
                                            Select where to create a ticket:
                                        </Text>
                                        <Select
                                            onValueChange={
                                                updateSelectedSourceMetadata
                                            }
                                            defaultValue={JSON.stringify(
                                                sources[0],
                                            )}
                                        >
                                            <SelectTrigger
                                                className="mb-4 box-border p-4"
                                            >
                                                <SelectValue placeholder="Select a source" />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {sources.map((channel) => {
                                                    const valueItem =
                                                        channel.name;
                                                    return (
                                                        <SelectItem
                                                            key={valueItem}
                                                            value={JSON.stringify(
                                                                channel,
                                                            )}
                                                        >
                                                            {valueItem}
                                                        </SelectItem>
                                                    );
                                                })}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                )}

                                {loadingDropdown === loadingTypes.error && (
                                    <Skeleton>
                                        <Select />
                                    </Skeleton>
                                )}

                                <Button
                                    style={{
                                        width: "auto",
                                        alignSelf: "end",
                                    }}
                                    onClick={save}
                                    disabled={
                                        loadingDropdown === loadingTypes.error
                                    }
                                >
                                    {props.enabledIntegrations.length > 0
                                        ? "Add"
                                        : "Go to Manage Integrations"}
                                </Button>
                            </div>
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Shortcuts;
