import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Input } from "@/component/shadcn/ui/input";
import { Check, Plus, X, XIcon } from "lucide-react";
import { useState } from "react";

export interface EmailRecipient {
    email: string;
    name?: string;
}

interface ReplyFromPluginProps {
    to: EmailRecipient[];
    setTo: (to: EmailRecipient[]) => void;
    setCc: (cc: EmailRecipient[]) => void;
    initialFrom?: EmailRecipient;
    cc: EmailRecipient[];
    className?: string;
}

export function ReplyFromPlugin({
    to,
    setTo,
    setCc,
    initialFrom,
    cc,
    className,
}: ReplyFromPluginProps) {
    const [newToRecipient, setNewToRecipient] = useState("");
    const [newCcRecipient, setNewCcRecipient] = useState("");
    const [isAddingTo, setIsAddingTo] = useState(false);
    const [isAddingCc, setIsAddingCc] = useState(false);

    const handleAddRecipient = (type: "to" | "cc") => {
        const recipient = type === "to" ? newToRecipient : newCcRecipient;
        if (!recipient) return;

        const newRecipient: EmailRecipient = {
            email: recipient,
            name: recipient.split("@")[0],
        };

        if (type === "to") {
            setTo([...to, newRecipient]);
            setNewToRecipient("");
            setIsAddingTo(false);
        } else {
            setCc([...cc, newRecipient]);
            setNewCcRecipient("");
            setIsAddingCc(false);
        }
    };

    const handleRemoveRecipient = (type: "to" | "cc", email: string) => {
        if (type === "to") {
            setTo(to.filter((r) => r.email !== email));
        } else {
            setCc(cc.filter((r) => r.email !== email));
        }
    };

    return (
        <div className={`rounded-lg p-4 ${className}`}>
            <div className="space-y-3">
                {/* To Field */}
                <div className="flex items-start">
                    <span className="w-8 text-xs text-muted-foreground pt-1">
                        To:
                    </span>
                    <div className="flex-1 flex flex-wrap gap-2 items-center pt-0.5">
                        {to.map((recipient) => (
                            <Badge
                                key={recipient.email}
                                variant="secondary"
                                className="text-xs"
                            >
                                {recipient.name && `${recipient.name} `}
                                <span className="text-muted-foreground">
                                    &lt;{recipient.email}&gt;
                                </span>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-3 w-3 p-0 ml-1.5 hover:bg-transparent"
                                    onClick={() =>
                                        handleRemoveRecipient(
                                            "to",
                                            recipient.email,
                                        )
                                    }
                                >
                                    <X className="h-2.5 w-2.5" />
                                </Button>
                            </Badge>
                        ))}
                        {isAddingTo ? (
                            <div className="flex">
                                <Input
                                    className="h-6 w-[180px] text-xs"
                                    value={newToRecipient}
                                    onChange={(e) =>
                                        setNewToRecipient(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.key === "Enter" &&
                                        handleAddRecipient("to")
                                    }
                                    placeholder="Enter email address"
                                    autoFocus
                                />
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 px-1.5 rounded-l-none border-l-0"
                                    onClick={() => {
                                        setIsAddingTo(false);
                                        setNewToRecipient("");
                                    }}
                                >
                                    <XIcon className="h-3 w-3" color="red" />
                                </Button>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 px-1.5 rounded-l-none border-l-0"
                                    onClick={() => handleAddRecipient("to")}
                                >
                                    <Check className="h-3 w-3" color="green" />
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="ghost"
                                size="sm"
                                className="h-6 px-1.5"
                                onClick={() => setIsAddingTo(true)}
                            >
                                <Plus className="h-3 w-3" />
                            </Button>
                        )}
                    </div>
                </div>

                {/* Cc Field */}
                <div className="flex items-start">
                    <span className="w-8 text-xs text-muted-foreground pt-1">
                        Cc:
                    </span>
                    <div className="flex-1 flex flex-wrap gap-2 items-center">
                        {cc.map((recipient) => (
                            <Badge
                                key={recipient.email}
                                variant="secondary"
                                className="text-xs"
                            >
                                {recipient.name && `${recipient.name} `}
                                <span className="text-muted-foreground">
                                    &lt;{recipient.email}&gt;
                                </span>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-3 w-3 p-0 ml-1.5 hover:bg-transparent"
                                    onClick={() =>
                                        handleRemoveRecipient(
                                            "cc",
                                            recipient.email,
                                        )
                                    }
                                >
                                    <X className="h-2.5 w-2.5" />
                                </Button>
                            </Badge>
                        ))}
                        {isAddingCc ? (
                            <div className="flex">
                                <Input
                                    className="h-6 w-[180px] text-xs"
                                    value={newCcRecipient}
                                    onChange={(e) =>
                                        setNewCcRecipient(e.target.value)
                                    }
                                    onKeyDown={(e) =>
                                        e.key === "Enter" &&
                                        handleAddRecipient("cc")
                                    }
                                    placeholder="Enter email address"
                                    autoFocus
                                />

                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 px-1.5 rounded-l-none border-l-0"
                                    onClick={() => {
                                        setIsAddingCc(false);
                                        setNewCcRecipient("");
                                    }}
                                >
                                    <XIcon className="h-3 w-3" color="red" />
                                </Button>
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    className="h-6 px-1.5 rounded-l-none border-l-0"
                                    onClick={() => handleAddRecipient("cc")}
                                >
                                    <Check className="h-3 w-3" color="green" />
                                </Button>
                            </div>
                        ) : (
                            <Button
                                variant="ghost"
                                size="sm"
                                className="h-6 px-2"
                                onClick={() => setIsAddingCc(true)}
                            >
                                <Plus className="h-3 w-3" />
                            </Button>
                        )}
                    </div>
                </div>
            </div>

            {/* Editor content */}
            <div className="mt-3">
                {/* Your editor component will be rendered here */}
            </div>
        </div>
    );
}
