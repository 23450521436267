import { useLogoutFunction } from "@propelauth/react";
import type React from "react";
import { useDialog } from "../DialogContext";
import { Dialog, DialogContent, DialogDescription, DialogTitle } from "./shadcn/ui/dialog";
import { Button } from "./shadcn/ui/button";

const UnauthorizedDialog: React.FC = () => {
    const { isDialogOpen, closeDialog } = useDialog();
    const logout = useLogoutFunction();
    const closeDialogAndRedirect = async () => {
        closeDialog();
        await logout(false);
    };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        event.preventDefault();
    };
    return (
        <Dialog open={isDialogOpen} onOpenChange={(open) => closeDialog()}>
            <DialogContent
                onEscapeKeyDown={handlePointerDownOutside}
                onPointerDownOutside={handlePointerDownOutside}
            >
                <DialogTitle>Your session has expired</DialogTitle>
                <DialogDescription
                    color="gray"
                    style={{ paddingBottom: "30px" }}
                >
                    Please log in again to continue using the app.
                </DialogDescription>
                <Button onClick={closeDialogAndRedirect}>Login Again</Button>
            </DialogContent>
        </Dialog>
    );
};

export default UnauthorizedDialog;
