import { Input } from "@/component/shadcn/ui/input";
import { API, TeamsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { GetUserResponse } from "@/interfaces/serverData";
import {
    PlusIcon,
} from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { generateMembersColumns } from "./DataTable/columns";
import type { MembersRowState } from "./DataTable/constants";
import { MembersDataTable } from "./DataTable/members-data-table";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useToast } from "@/component/shadcn/ui/use-toast";

const FormSchema = z.object({
    teamName: z.string().min(2, {
        message: "Team name must be at least 2 characters.",
    }),
});

const NewTeam = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const api = useApi();
    const { toast } = useToast();

    const [rowState, setRowState] = useState<Map<string, MembersRowState>>(
        new Map(),
    );
    const { data = [] } = useQuery({
        queryKey: ["members"],
        queryFn: () => fetchMembers(),
        refetchInterval: 30000, // refetch every 30 secs
        refetchOnWindowFocus: true,
    });

    const [selectedMembersData, setSelectedMembersData] = useState<
        GetUserResponse[]
    >([]);

    const addMemberToRowState = (member: string) => {
        const newMember = data.find((user) => user.id === member);
        if (newMember) {
            const newId = rowState.size.toString();
            setSelectedMembersData([...selectedMembersData, newMember]);
            updateRowState(newId, {
                id: newMember.id,
                name: `${newMember.first_name} ${newMember.last_name}`,
                email: newMember.email,
                username: newMember.username,
                picture_url: newMember.picture_url,
                role: newMember.user_role,
            });
        }
    };

    const updateRowState = (id: string, newState: Partial<MembersRowState>) => {
        setRowState((prevState) => {
            const newStateMap = new Map(prevState);
            const currentState = newStateMap.get(id) || {
                id: "",
                name: "",
                email: "",
                username: "",
                picture_url: "",
                role: "",
            };
            newStateMap.set(id, { ...currentState, ...newState });
            return newStateMap;
        });
    };

    const deleteRowState = (id: string) => {
        const member = rowState.get(id);
        if (member) {
            setSelectedMembersData(
                selectedMembersData.filter((x) => x.id !== member.id),
            );
        }
        setRowState((prevState) => {
            const newStateMap = new Map();

            let shiftKey = 0;
            for (const [key, value] of prevState) {
                if (key !== id) {
                    const num = Number(key) - shiftKey;
                    newStateMap.set(num.toString(), value); // shift all keys left by 1 to account for deletion
                } else {
                    shiftKey = 1;
                }
            }
            return newStateMap;
        });
    };

    const fetchMembers = async (): Promise<GetUserResponse[]> => {
        const response = await api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (response.status === 200) {
            setLoadingState(1);
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };

    const actions = {
        create: "create",
        update: "update",
    };

    type SaveTeamRequest = {
        name: string;
        member_ids: string[];
        action: string;
    };

    const saveTeam = async () => {
        const memberIds: string[] = [];
        for (const member of selectedMembersData) {
            memberIds.push(member?.id);
        }

        const requestData: SaveTeamRequest = {
            name: form.getValues().teamName,
            member_ids: memberIds,
            action: actions.create,
        };
        const [url] = TeamsAPI.createTeam;

        api.put(`${URLS.serverUrl}${url}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                toast({
                    title: "Team created successfully!",
                    description: "Your team has been created successfully.",
                    variant: "default",
                })
            })
            .catch((err) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                })
            })
    };

    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            teamName: "",
        },
    });

    return (
        <div>
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <h2 className="text-2xl font-semibold">Create a new team</h2>
                <p className="text-sm text-gray11 pb-10">
                    Create a new team to manage separate groups of users.
                </p>

                <Form {...form}>
                    <form onSubmit={form.handleSubmit(saveTeam)}>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2">
                                <FormField
                                    control={form.control}
                                    name="teamName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Team Name</FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder="team name"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row gap-2 items-center justify-between">
                                    <p className="text-sm font-semibold">
                                        Members
                                    </p>

                                    <DropdownMenu>
                                        <DropdownMenuTrigger>
                                            <Button
                                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                                size="sm"
                                                variant="outline"
                                            >
                                                Add Member
                                                <PlusIcon />
                                            </Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent>
                                            <FilterDropdownElement
                                                key={"Assignee"}
                                                type={"Assignee"}
                                                filters={new Map()}
                                                handleItemSelect={(
                                                    type: string,
                                                    option: { label: string; value: string; key: string; color: string; }
                                                ) => () => {
                                                    if (type === "Assignee") {
                                                        console.log(option.value);
                                                        addMemberToRowState(
                                                            option.value
                                                        );
                                                    }
                                                }}
                                                topics={[]}
                                                users={data.filter(
                                                    (user) => !selectedMembersData.some(
                                                        (selectedUser) => selectedUser.id ===
                                                            user.id
                                                    )
                                                )}
                                                customerGroups={[]}
                                                isSavedViewFilter={false} categories={[]} teams={[]} channels={new Map()} />
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>
                            </div>
                            {loadingState === loadingTypes.loaded && (
                                <div className="flex flex-col items-end gap-2">
                                    <MembersDataTable
                                        data={selectedMembersData}
                                        columns={generateMembersColumns(
                                            rowState,
                                            updateRowState,
                                            deleteRowState,
                                            true,
                                        )}
                                    />
                                </div>
                            )}
                            <div className="flex flex-col items-end gap-2">
                                <Button
                                    className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                    size="sm"
                                    variant="outline"
                                >
                                    Create Team
                                </Button>
                            </div>
                        </div>
                    </form>
                </Form>
            </Box>
        </div>
    );
};

export default NewTeam;
