import { Badge } from "@radix-ui/themes";

import Room from "@/Ticket/Room";
import SidebarCard from "@/Ticket/SidebarCard";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, ContactsAPI, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    HistoryResponse,
    Integration,
    IssueScore,
    ScopeResponse,
    Teams,
    Ticket,
} from "@/interfaces/serverData";
import { arraysAreEqual } from "@/utilities/methods";
import { RoomProvider } from "@liveblocks/react/suspense";
import { useAuthInfo } from "@propelauth/react";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useQuery,
} from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

function processTitle(ticket: Ticket): string {
    // Older tickets don't have a generated title, just use the query
    if (ticket.title == null) {
        return ticket.query;
    } else if (ticket.title.startsWith('"') && ticket.title.endsWith('"')) {
        // If the generated title is surrounded by quotes, remove the quotes
        return ticket.title.slice(1, -1);
    } else {
        return ticket.title;
    }
}

const areEqual = (prevProps: TicketProps, nextProps: TicketProps) => {
    return (
        arraysAreEqual(prevProps.threadData, nextProps.threadData) &&
        prevProps.refetchTicketData === nextProps.refetchTicketData &&
        prevProps.refetchThreadData === nextProps.refetchThreadData &&
        prevProps.loadingTicketState === nextProps.loadingTicketState &&
        prevProps.loadingThreadState === nextProps.loadingThreadState &&
        prevProps.analytics === nextProps.analytics &&
        prevProps.userID === nextProps.userID &&
        prevProps.teamID === nextProps.teamID
    );
};

interface TicketProps {
    analytics: Ticket;
    userID: string;
    threadData: HistoryResponse[];
    loadingTicketState: number;
    loadingThreadState: number;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    teamID?: string;
}

function TicketDisplay({
    analytics,
    userID,
    threadData,
    loadingTicketState,
    loadingThreadState,
    refetchThreadData,
    refetchTicketData,
    teamID,
}: TicketProps) {
    const api = useApi();
    const authInfo = useAuthInfo();
    const [similarIssues, setSimilarIssues] = useState<IssueScore[]>([]);
    const [loadingSimilarIssues, setLoadingSimilarIssues] =
        useState<boolean>(true);

    const [ticketThread, setTicketThread] = useState<HistoryResponse[]>([]);
    const [attributesIsOpen, setAttributesIsOpen] = useState<boolean>(true);
    const [labelsIsOpen, setLabelsIsOpen] = useState<boolean>(true);
    const [customerIsOpen, setCustomerIsOpen] = useState<boolean>(true);
    const [extIssuesIsOpen, setExtIssuesIsOpen] = useState<boolean>(false);
    const [insightsIsOpen, setInsightsIsOpen] = useState<boolean>(false);
    const [similarIssuesIsOpen, setSimilarIssuesIsOpen] =
        useState<boolean>(true);
    const [noteIsOpen, setNoteIsOpen] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(false);
    const [gmailScopes, setGmailScopes] = useState<ScopeResponse[]>();

    const emptyTicket: Ticket = {
        id: "",
        ticket_identifier: "",
        number: 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        query: "",
        title: "",
        bot_category: "",
        source: "",
        source_unique_name: "",
        topic: [],
        ticket_status: "",
        url: "",
        source_specific_id: "",
        internal_note: "",
        assignee_user_id: "",
        external_issues: [],
        channel_name: "",
        insights: [],
        teams: [],
        integration_id: "",
        user_info: {
            id: "",
            name: "",
        },
        ai_response: ""
    };

    const authInfoRef = useRef(authInfo);
    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const handleClick =
        (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            const url = `/issue/${id}`;
            window.open(url, "_blank");
        };

    useEffect(() => {
        if (threadData && threadData.length === 0) {
            const message: HistoryResponse[] = [
                {
                    id: analytics.id,
                    content: analytics.query,
                    source: analytics.source ?? "",
                    source_unique_name: analytics.source_unique_name ?? "",
                    timestamp: analytics.created_at,
                    type: "Message",
                    metadata: "",
                    user_data: {
                        name: "Anonymous",
                        id: "Anonymous",
                    },
                    reactions: [],
                    files: [],
                },
            ];
            setTicketThread(message);
        } else {
            setTicketThread(threadData);
        }
    }, [threadData]);

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

    const source: string = analytics.source ?? "";
    // User info of first message in the ticket
    const customerUserInfo = analytics.user_info;
    const [customerAccount, setCustomerAccount] = useState<Account>();
    useEffect(() => {
        const { url } = ContactsAPI.getCustomer;
        if (customerUserInfo?.assembly_customer_id) {
            api.get(
                `${URLS.serverUrl}${url}/${customerUserInfo?.assembly_customer_id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status === 200) {
                        const customer: Account = res.data.data;
                        setCustomerAccount(customer);
                    }
                })
                .catch((res) => {
                    console.error(
                        "Error fetching full Assembly Customer:",
                        res,
                    );
                });
        }
    }, [api, customerUserInfo]);

    const [orgId, setOrgId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            navigate("/*");
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper, navigate]);

    useEffect(() => {
        setLoadingSimilarIssues(true);
        const fetchSimilarIssues = async () => {
            try {
                const res = await api.post(
                    URLS.serverUrl + API.similarIssuesForTicket,
                    {
                        query: analytics.query,
                        curr_ticket_number: analytics.number,
                    },
                );
                if (res.status === 200) {
                    const response: IssueScore[] = res.data.data;
                    if (response) {
                        setSimilarIssues(response);
                    }
                    setLoadingSimilarIssues(false);
                } else {
                    console.log("Failed to fetch similar issues");
                }
            } catch (error) {
                console.log("System is down.");
            }
        };
        fetchSimilarIssues();
    }, [analytics.query, analytics.number]);

    useEffect(() => {
        api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const integrationsResponse: Integration[] = res.data.data;
                    const dataItems: ScopeResponse[] = [];
                    for (const integration of integrationsResponse) {
                        const scope: ScopeResponse = {
                            key: integration.id,
                            name: integration.unique_name,
                        };
                        dataItems.push(scope);
                    }
                    setGmailScopes(dataItems);
                }
            })
            .catch((res) => {
                console.error("Error fetching scope data:", res);
            });
    }, []);

    const sidebarLabels = "font-medium text-xs text-gray9";
    return (
        <div className="w-full h-screen overflow-y-auto">
            <Flex
                align="start"
                direction="column"
                justify={"start"}
                className="pl-2 py-1 pr-4 w-full"
            >
                <Card className="bg-white shadow-sm border rounded-lg relative flex flex-col gap-4 p-5 w-full">
                    <div className="flex items-center gap-2.5 text-lg font-semibold mb-2">
                        <Badge
                            variant="outline"
                            color="gray"
                            size="2"
                            className="text-sm"
                        >
                            {`${analytics.ticket_identifier}-${analytics.number}`}
                        </Badge>
                        {processTitle(analytics)}
                        <Button
                            type="button"
                            variant="ghost"
                            className="hover:bg-muted px-1 -mx-1"
                            onClick={handleClick(
                                `${analytics.ticket_identifier}-${analytics.number}`,
                            )}
                        >
                            <TooltipProvider>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <OpenInNewWindowIcon />
                                    </TooltipTrigger>
                                    <TooltipContent className="bg-[#5B5BD6]">
                                        <p>Open Issue</p>
                                    </TooltipContent>
                                </Tooltip>
                            </TooltipProvider>
                        </Button>
                    </div>
                    <div>
                        {loadingThreadState === 0 && (
                            <div>
                                <Skeleton>
                                    <Text>
                                        {[...Array(6)].map((_, index) => (
                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                            <Text key={index}>
                                                {loremIpsum}
                                            </Text>
                                        ))}
                                    </Text>
                                </Skeleton>
                            </div>
                        )}

                        {loadingThreadState === 2 && (
                            <Callout.Root
                                size="1"
                                variant="outline"
                                color="red"
                                className="pt-4 w-[50%]"
                            >
                                <Callout.Text>
                                    Sorry, something's wrong with loading the
                                    thread! Please notify us at
                                    support@askassembly.app.
                                </Callout.Text>
                            </Callout.Root>
                        )}

                        {loadingThreadState === 1 && ticketThread && (
                            <div className="-mt-3">
                                <RoomProvider id={`${orgId}:ticket`}>
                                    <Room
                                        threadData={ticketThread}
                                        source={source}
                                        source_specific_id={analytics.source_specific_id}
                                        source_unique_name={analytics.source_unique_name}
                                        update={update}
                                        setUpdate={setUpdate}
                                        title={analytics.title}
                                        refetchThreadData={refetchThreadData}
                                        showComposer={false}
                                        userID={userID}
                                        subtractHeight={700}
                                        originalTicket={emptyTicket}
                                        teamsQuery={teamsQuery}
                                        customerUserInfo={customerUserInfo
                                            ? customerUserInfo
                                            : undefined}
                                        account={customerAccount}
                                        gmailScopes={gmailScopes}
                                        refetchTicketData={refetchTicketData}
                                    />
                                </RoomProvider>
                            </div>
                        )}
                    </div>
                </Card>
            </Flex>
            {loadingTicketState === 0 && (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                <Text
                                    key={
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        index
                                    }
                                >
                                    {loremIpsum}
                                </Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            )}
            {loadingTicketState === 1 && (
                <Flex
                    align="start"
                    direction="column"
                    justify={"start"}
                    className="pl-2 pt-2 pr-4 w-full"
                >
                    <div className="flex flex-1 flex-col gap-3 rounded-md overflow-y-auto overflow-visible w-full">
                        <SidebarCard
                            isOpen={attributesIsOpen}
                            setIsOpen={setAttributesIsOpen}
                            sidebarLabels={sidebarLabels}
                            type="Attributes"
                            analytics={analytics}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            userID={userID}
                            customerUserInfo={customerUserInfo}
                            teamsQuery={teamsQuery}
                        />
                        <SidebarCard
                            isOpen={labelsIsOpen}
                            setIsOpen={setLabelsIsOpen}
                            sidebarLabels={sidebarLabels}
                            type="Labels"
                            analytics={analytics}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            userID={userID}
                            customerUserInfo={customerUserInfo}
                            teamID={teamID}
                        />

                        {source !== "ChatWidget" && (
                            <SidebarCard
                                isOpen={customerIsOpen}
                                setIsOpen={setCustomerIsOpen}
                                sidebarLabels={sidebarLabels}
                                type="Customer Profile"
                                analytics={analytics}
                                refetchTicketData={refetchTicketData}
                                refetchThreadData={refetchThreadData}
                                userID={userID}
                                customerUserInfo={customerUserInfo}
                            />
                        )}
                        <SidebarCard
                            isOpen={extIssuesIsOpen}
                            setIsOpen={setExtIssuesIsOpen}
                            sidebarLabels={sidebarLabels}
                            type="External Issues"
                            analytics={analytics}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            userID={userID}
                            customerUserInfo={customerUserInfo}
                            alertNum={analytics.external_issues?.length ?? 0}
                        />
                        <SidebarCard
                            isOpen={insightsIsOpen}
                            setIsOpen={setInsightsIsOpen}
                            sidebarLabels={sidebarLabels}
                            type="Insights"
                            analytics={analytics}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            userID={userID}
                            customerUserInfo={customerUserInfo}
                            alertNum={analytics.insights?.length ?? 0}
                        />
                        <SidebarCard
                            isOpen={similarIssuesIsOpen}
                            setIsOpen={setSimilarIssuesIsOpen}
                            sidebarLabels={sidebarLabels}
                            type="Related Interactions"
                            analytics={analytics}
                            refetchTicketData={refetchTicketData}
                            refetchThreadData={refetchThreadData}
                            userID={userID}
                            customerUserInfo={customerUserInfo}
                            similarIssues={similarIssues}
                            loadingSimilarIssues={loadingSimilarIssues}
                            teamID={teamID}
                        />
                        {analytics.internal_note !== "" && (
                            <SidebarCard
                                isOpen={noteIsOpen}
                                setIsOpen={setNoteIsOpen}
                                sidebarLabels={sidebarLabels}
                                type="Internal Notes"
                                analytics={analytics}
                                refetchTicketData={refetchTicketData}
                                refetchThreadData={refetchThreadData}
                                userID={userID}
                                customerUserInfo={customerUserInfo}
                                alertNum={
                                    analytics.internal_note === "" ? 0 : 1
                                }
                            />
                        )}
                        <div className="h-[100px]" />
                    </div>
                </Flex>
            )}
            {loadingTicketState === 2 && (
                <Callout.Root
                    size="1"
                    variant="outline"
                    color="red"
                    className="pt-4 w-[50%]"
                >
                    <Callout.Text>
                        Sorry, something's wrong with loading the thread! Please
                        notify us at support@askassembly.app.
                    </Callout.Text>
                </Callout.Root>
            )}
        </div>
    );
}

export default memo(TicketDisplay, areEqual);
