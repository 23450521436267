import type { ResolutionTimeResponse } from "@/interfaces/serverData";
import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { ChartData } from "../AdminAnalyticsPage";

interface ResolutionAnalyticsProps {
    resolutionTimePerDay: ChartData[];
    resolutionChartData: ChartData[];
    resolutionTimeQueryStatus: string;
    resolutionTimeQueryIsError: boolean;
    resolutionTimeQueryData: ResolutionTimeResponse | undefined;
}

const ResolutionAnalyticsComponent = ({
    resolutionTimePerDay,
    resolutionChartData,
    resolutionTimeQueryStatus,
    resolutionTimeQueryIsError,
    resolutionTimeQueryData,
}: ResolutionAnalyticsProps) => {
    return (
        <div className="flex flex-col gap-2">
            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Resolution Time Per Day Average
                    </Text>
                    {resolutionTimeQueryStatus === "loading" && (
                        <SkeletonText />
                    )}
                    {resolutionTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {resolutionTimeQueryData &&
                        resolutionTimePerDay.length > 0 && (
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart
                                    data={resolutionTimePerDay.map((item) => {
                                        return {
                                            ...item,
                                            duration: item.duration / 60, // Convert duration from seconds to minutes
                                        };
                                    })}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="date"
                                        type="category"
                                        tickFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                    />
                                    <YAxis />
                                    <Tooltip
                                        labelFormatter={(value) =>
                                            new Date(value).toLocaleDateString()
                                        }
                                        formatter={(value: number) => [
                                            `${value.toFixed(2)} minutes`,
                                            "Average Resolution Time",
                                        ]}
                                    />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="duration"
                                        stroke="#8884d8"
                                        name="Average Resolution Time"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default ResolutionAnalyticsComponent;
