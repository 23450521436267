import type React from "react";

import { MultiSelect } from "./shadcn/ui/multi-select";

interface Option {
    disabled: boolean;
    label: string;
    value: string;
}
interface DropdownProps {
    dropdownStyle?: React.CSSProperties;
    dropdownContainerClassName?: string;
    items: Option[];
    onItemClick: (selectedItems: string[]) => void;
}

const MultiSelectDropdown: React.FC<DropdownProps> = ({
    items,
    onItemClick,
    dropdownContainerClassName,
}) => {
    return (
        <MultiSelect
            className="max-w-[520px]"
            placeholder="Select..."
            options={items}
            onValueChange={onItemClick}
            dropdownContainerClassName={dropdownContainerClassName}
        />
    );
};
export default MultiSelectDropdown;
