import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/component/shadcn/ui/card";
import { Badge, Callout } from "@radix-ui/themes";
import { Handle, Position } from "@xyflow/react";
import { PlayIcon } from "lucide-react";
import { ActionsIcon } from "../Icons";

import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/component/shadcn/ui/select";
import { Separator } from "@/component/shadcn/ui/separator";
import { Textarea } from "@/component/shadcn/ui/textarea";
import type {
  Category,
  CustomerGroup,
  GetTopicsResponse,
  GetUserResponse,
  Teams,
  Template,
  Workflow,
} from "@/interfaces/serverData";
import {
  WorkflowNodesDropdownTrigger,
  getTopicColors,
  getTypeFromActionType,
} from "@/utilities/methods";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { handleStyle } from "../Icons";
import { InfoCircledIcon } from "@radix-ui/react-icons";

const ActionNode: React.FC<{
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  data: any;
  isConnectable: boolean;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  onUpdate: (id: string, metadata: any) => void;
  categoriesQuery: UseQueryResult<Category[], Error>;
  topicsQuery: UseQueryResult<GetTopicsResponse[], Error>;
  teamsQuery: UseQueryResult<Teams[], Error>;
  customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
  usersQuery: UseQueryResult<GetUserResponse[], Error>;
  templatesQuery: UseQueryResult<Template[], Error>;
  workflow?: Workflow;
}> = ({
  data,
  isConnectable,
  onUpdate,
  categoriesQuery,
  topicsQuery,
  teamsQuery,
  customerGroupsQuery,
  usersQuery,
  templatesQuery,
  workflow,
}) => {
    const NON_FILTER_DROPDOWN_ACTIONS = ["send_message", "send_internal_message", "send_new_message"]
    const [actionType, setActionType] = useState<string>(
      data.metadata?.subtype ?? (workflow ? undefined : "send_template"),
    );
    const [nameVal, setNameVal] = useState<string>(data.metadata?.name ?? "");
    const [filters, setFilters] = useState<
      Map<
        string,
        Set<{ label: string; value: string; key: string; color: string }>
      >
    >(data.metadata?.filters ?? new Map());
    const [messageVal, setMessageVal] = useState<string>(
      data.metadata?.message ?? "",
    );
    const [newMessageRecipient, setNewMessageRecipient] = useState<string>(() => {
      const recipientData = data.metadata?.filters?.get("Recipient");
      if (recipientData) {
        const option = Array.from(recipientData)[0] as { label: string; value: string; key: string; color: string };
        return option.label ?? "";
      }
      return "";
    });

    // Update node's metadata
    const handleSelectChange = (value: string) => {
      if (data.metadata?.message) {
        data.metadata.message = ""
        setMessageVal("")
      }
      setNewMessageRecipient("")
      setActionType(value);
      const newFilters = new Map();
      setFilters(newFilters);
      const updatedMetadata = {
        ...data.metadata,
        filters: newFilters,
        subtype: value,
      };
      onUpdate(data.id, updatedMetadata);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const updatedMetadata = { ...data.metadata, name: e.target.value };
      onUpdate(data.id, updatedMetadata);
      setNameVal(e.target.value);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const updatedMetadata = { ...data.metadata, message: e.target.value };
      onUpdate(data.id, updatedMetadata);
      setMessageVal(e.target.value);
    };

    const handleItemSelect =
      (
        type: string,
        option: {
          label: string;
          value: string;
          key: string;
          color: string;
        },
      ) =>
        () => {
          const newFilters = new Map(filters);
          newFilters.set(type, new Set([option]));
          setFilters(newFilters);
          const updatedMetadata = { ...data.metadata, filters: newFilters };
          onUpdate(data.id, updatedMetadata);
        };

    const handleNewMessageRecipientChange = (value: string) => {
      setNewMessageRecipient(value);
      const newFilters = new Map(filters);
      const option = { label: value, value: value, key: value, color: value }
      newFilters.set("Recipient", new Set([option]))
      setFilters(newFilters);
      const updatedMetadata = {
        ...data.metadata,
        filters: newFilters,
      };
      onUpdate(data.id, updatedMetadata);
    };

    // Set the populated topic's colors once topics query is updated
    useEffect(() => {
      if (filters.has("Topic")) {
        const newTopics = new Set<{
          label: string;
          value: string;
          key: string;
          color: string;
        }>();
        const existingTopics = Array.from(filters.get("Topic") ?? [])
        for (const topic of existingTopics) {
          const fullTopic: GetTopicsResponse | undefined = (topicsQuery.data ?? []).find(t => t.id === topic.key);
          if (fullTopic) {
            newTopics.add({
              color: fullTopic.color ?? topic.color,
              label: fullTopic.topic_name ?? topic.label,
              value: fullTopic.topic_name ?? topic.value,
              key: topic.key
            });
          } else {
            newTopics.add(topic);
          }
        }
        setFilters(new Map([["Topic", newTopics]]))
      }
    }, [topicsQuery.data])

    return (
      <div className="flex flex-col items-start">
        <Badge className="bg-[#eceefb] text-[#5e6ad2] outline outline-[#d7d9f4] outline-1 hover-none -mb-1 ml-[1px] pb-[4px] relative">
          <div className="flex flex-row items-center justify-center gap-1">
            <PlayIcon className="text-[#5e6ad2] h-3 w-3" />
            <p className="text-xs">Action</p>
          </div>
        </Badge>
        <Card className={`w-[475px] shadow-none border rounded-lg z-10 ${data.errorStyle}`}>
          <CardHeader className="flex flex-col items-start gap-2">
            <div className="flex items-center space-x-2">
              <ActionsIcon />
              <CardTitle>
                <div className="flex items-center gap-2">
                  <p className="text-xs font-medium flex-shrink-0">
                    Then
                  </p>
                  <Select
                    defaultValue={actionType}
                    onValueChange={handleSelectChange}
                  >
                    <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1] w-[250px]">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="send_template">
                        Send a Template
                      </SelectItem>
                      <SelectItem value="send_message">
                        Send a Message
                      </SelectItem>
                      <SelectItem value="send_internal_message">
                        Send an Internal Message
                      </SelectItem>
                      <SelectItem value="add_assignee">
                        Add Assignee
                      </SelectItem>
                      <SelectItem value="add_interaction_type">
                        Add Interaction Type
                      </SelectItem>
                      <SelectItem value="add_tag">
                        Add Tag
                      </SelectItem>
                      <SelectItem value="change_status">
                        Change Status
                      </SelectItem>
                      <SelectItem value="send_new_message">
                        Send New Message
                      </SelectItem>
                      <SelectItem value="send_template_new_message">
                        Send Templated New Message
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <p className="text-xs font-medium flex-shrink-0">
                    :
                  </p>
                </div>
              </CardTitle>
            </div>
            {actionType !== "" && !NON_FILTER_DROPDOWN_ACTIONS.includes(actionType) && (
              <div className="flex flex-col gap-3">
                {actionType == "send_template_new_message" && (
                  <div className="flex items-center gap-6 ml-8">
                    <p className="text-xs font-medium flex-shrink-0">
                      to
                    </p>
                    <Select
                      defaultValue={newMessageRecipient}
                      onValueChange={handleNewMessageRecipientChange}
                    >
                      <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1] w-[150px]">
                        <SelectValue placeholder="Select Recipient" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="company">
                          Company
                        </SelectItem>
                        <SelectItem value="customer">
                          Customer
                        </SelectItem>
                      </SelectContent>
                    </Select>
                    {newMessageRecipient != "" &&
                      <Callout.Root size="1">
                        <Callout.Text className="flex flex-row gap-2 items-center">
                          <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0" />
                          <div className="text-[11px] leading-tight">
                            {newMessageRecipient == "company" ?
                              "Message will be sent to company's preferred communication method" :
                              "Message will be sent to customer's email"}
                          </div>
                        </Callout.Text>
                      </Callout.Root>
                    }
                  </div>
                )}
                <DropdownMenu>
                  <DropdownMenuTrigger
                    asChild
                    type="button"
                    className="ml-8"
                  >
                    {WorkflowNodesDropdownTrigger(
                      getTypeFromActionType(actionType),
                      filters,
                      usersQuery.data ?? [],
                      [],
                      [],
                      categoriesQuery.data ??
                      [],
                      getTopicColors(
                        topicsQuery.data ??
                        [],
                      ),
                    )}
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    align="start"
                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                  >
                    <FilterDropdownElement
                      type={getTypeFromActionType(actionType)}
                      categories={categoriesQuery.data ?? []}
                      filters={filters}
                      handleItemSelect={handleItemSelect}
                      topics={getTopicColors(
                        topicsQuery.data ?? [],
                      )}
                      users={usersQuery.data ?? []}
                      customerGroups={
                        customerGroupsQuery.data ?? []
                      }
                      teams={teamsQuery.data ?? []}
                      isSavedViewFilter={false}
                      channels={new Map()}
                      templates={templatesQuery.data ?? []}
                    />
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )}
            {actionType === "send_message" && (
              <Textarea
                className="text-xs ml-8 w-[360px]"
                placeholder="Message..."
                rows={2}
                value={messageVal}
                onChange={handleMessageChange}
              />
            )}
            {actionType === "send_internal_message" && (
              <Textarea
                className="text-xs ml-8 w-[360px]"
                placeholder="Internal Message..."
                rows={2}
                value={messageVal}
                onChange={handleMessageChange}
              />
            )}
            {actionType === "send_new_message" && (
              <div className="flex flex-col gap-3">
                <div className="flex items-center gap-6 ml-8">
                  <p className="text-xs font-medium flex-shrink-0">
                    to
                  </p>
                  <Select
                    defaultValue={newMessageRecipient}
                    onValueChange={handleNewMessageRecipientChange}
                  >
                    <SelectTrigger className="focus:outline-none focus:ring-0 text-xs font-medium hover:bg-gray-100 px-2 py-1 rounded outline outline-1 outline-[#eeeff1] w-[140px]">
                      <SelectValue placeholder="Select Recipient" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="company">
                        Company
                      </SelectItem>
                      <SelectItem value="customer">
                        Customer
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  {newMessageRecipient != "" &&
                    <Callout.Root size="1">
                      <Callout.Text className="flex flex-row gap-2 items-center">
                        <InfoCircledIcon className="w-3.5 h-3.5 flex-shrink-0" />
                        <div className="text-[11px] leading-tight">
                          {newMessageRecipient == "company" ?
                            "Message will be sent to company's preferred communication method" :
                            "Message will be sent to customer's email"}
                        </div>
                      </Callout.Text>
                    </Callout.Root>
                  }
                </div>
                <Textarea
                  className="text-xs ml-8 w-[360px]"
                  placeholder="New Message..."
                  rows={2}
                  value={messageVal}
                  onChange={handleMessageChange}
                />
              </div>
            )}
          </CardHeader>

          <CardContent className="flex flex-col gap-2">
            <Separator />
            <Input
              className="text-xs border-none px-0"
              placeholder="Name..."
              value={nameVal}
              onChange={handleNameChange}
            />
          </CardContent>

          <Handle
            type="source"
            position={Position.Top}
            id="a"
            isConnectable={isConnectable}
            className={`${handleStyle} top-[18px]`}
          />
          <Handle
            type="source"
            position={Position.Bottom}
            id="b"
            isConnectable={isConnectable}
            className={handleStyle}
          />
        </Card>
      </div>
    );
  };

export default ActionNode;
