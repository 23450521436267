import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/component/shadcn/ui/tooltip";
import type { Label } from "@/interfaces/serverData";
import { CaretDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";

interface WorkflowTypeDropdownProps {
    workflowType: Label | undefined;
    setWorkflowType: React.Dispatch<React.SetStateAction<Label | undefined>>;
    labels: Label[] | undefined;
}

export const WorkflowTypeDropdown: React.FC<WorkflowTypeDropdownProps> = ({ workflowType, setWorkflowType, labels }) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div>
                        <DropdownMenu>
                            <DropdownMenuTrigger asChild type="button">
                                {(workflowType && workflowType.id !== "00000000-0000-0000-0000-000000000000") ? (
                                    <Badge
                                        size="2"
                                        className="text-gray-500"
                                        style={{
                                            backgroundColor:
                                                workflowType?.color ?? "gray",
                                        }}
                                        variant="soft"
                                    >
                                        {workflowType.name}
                                        <CaretDownIcon />
                                    </Badge>
                                ) : (
                                    <Badge
                                        size="2"
                                        variant="outline"
                                        color="gray"
                                    >
                                        No Type
                                        <CaretDownIcon />
                                    </Badge>
                                )}

                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                                align="start"
                                className="fixed w-[150px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                            >
                                <DropdownMenuItem
                                    onClick={() =>
                                        setWorkflowType(undefined)
                                    }
                                >
                                    <Badge
                                        size="2"
                                        variant="outline"
                                        className="px-2 py-1 text-gray-500 rounded-sm"
                                        color="gray"
                                    >
                                        No Type
                                    </Badge>
                                </DropdownMenuItem>
                                {labels?.map((type) => (
                                    <DropdownMenuItem
                                        key={type.id}
                                        onClick={() =>
                                            setWorkflowType(type)
                                        }
                                    >
                                        <Badge
                                            size="2"
                                            variant="soft"
                                            className="px-2 py-1 text-gray-500 rounded-sm"
                                            style={{
                                                backgroundColor:
                                                    type?.color,
                                            }}
                                        >
                                            {type.name}
                                        </Badge>
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>
                    </div>
                </TooltipTrigger>
                <TooltipContent className="bg-[#5B5BD6] px-2 py-1.5 text-[11px]">
                    Workflow Type
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}