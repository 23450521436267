import type { Teams } from "@/interfaces/serverData";

export const queryToLabel: Record<string, string> = {
    id: "Id",
    tag: "Tag",
    description: "Description",
    created_at: "Timestamp",
    count: "Count",
    member: "Member",
    name: "Name",
    role: "Role",
    team: "Teams",
    labels: "Labels",
    contractValue: "Contract Value",
    assignee: "Assignee",
    lastInteraction: "Last Interaction",
    sources: "Sources",
    contacts: "Contacts",
    accountType: "Account Type",
};

export const tags = [
    {
        value: "Bug",
        color: "red",
    },
    {
        value: "Feature",
        color: "blue",
    },
    {
        value: "Query",
        color: "green",
    },
    {
        value: "Unknown",
        color: "gray",
    },
];

export type TagsRowState = {
    id: string;
    colorSelected: string;
    tagSelected: string;
    tag_name: string;
    description: string;
    teams: Teams[];
};

export type MembersRowState = {
    id: string;
    name: string;
    email: string;
    username: string;
    picture_url: string;
    role: string;
};

export const workspace: Teams = {
    id: "workspace",
    team_name: "Workspace",
    description: "Workspace",
};

export type ViewsRowState = {
    id: string;
    name: string;
    description: string;
};
