import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pages } from "../../../constant";
import NavBarComponent from "../../../sharedPages/NavBar";

import { Progress } from "@/component/shadcn/ui/progress";
import { Em, Text } from "@radix-ui/themes";

const ReIndexingPage = () => {
    const navigate = useNavigate();
    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    const [startIndexing, setStartIndexing] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0);

    const fetchProgress = useCallback(async () => {
        const interval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    navigateToIntegrations();
                    return prev;
                }
                return prev + 10;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.search} />

            <div className="flex flex-col items-center justify-center min-h-[80vh]">
                <div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem]">
                    <div className="flex flex-col gap-4">
                        <Text
                            size={{
                                sm: "4",
                                initial: "5",
                                md: "5",
                                xl: "6",
                            }}
                            weight="bold"
                        >
                            <b>
                                Please don't close this tab or refresh the page!
                            </b>
                        </Text>
                        <Text
                            size={{
                                sm: "3",
                                initial: "4",
                                md: "4",
                                xl: "4",
                            }}
                        >
                            <Em>Assembling...</Em>
                        </Text>
                        <Text
                            size={{
                                initial: "3",
                                sm: "2",
                                md: "3",
                                xl: "4",
                            }}
                            color="gray"
                            highContrast
                        >
                            This will take a few minutes, about as long as it
                            would take you to grab a cup of coffee!
                        </Text>
                        <Progress value={progress} className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReIndexingPage;
