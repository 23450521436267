import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { Checkbox } from "@/component/shadcn/ui/checkbox";
import { Input } from "@/component/shadcn/ui/input";
import CalendarPopup from "@/design/CalendarPopup";
import type {
    GetOnboardingResponse,
    GetTopicsResponse,
    GetUserResponse,
    OnboardingChecklist,
    OnboardingChecklistPayload,
} from "@/interfaces/serverData";
import { AvatarIcon } from "@radix-ui/react-icons";
import type { RefetchOptions } from "@tanstack/react-query";
import { ArrowRightIcon, CircleIcon, FilePlus2Icon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useMemo } from "react";
import { OnboardingContextMenu } from "./OnboardingContextMenu";

import CalendarBadge from "@/design/CalendarBadge";
import { OnboardingAssigneeDropdown } from "./AssigneeDropdown";

interface OnboardingCardProps {
    item: OnboardingChecklist;
    users: GetUserResponse[];
    saveIssue: (
        type: string,
        payload: Partial<OnboardingChecklistPayload>,
        userID: string,
        updateIssueState: (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => void,
        refetch: (options?: RefetchOptions) => Promise<OnboardingChecklist[]>,
        issueId: string,
        item: OnboardingChecklist,
        topicsMap?: Map<string, GetTopicsResponse>,
    ) => void;
    userID: string;
    isSelected: boolean;
    onSelect: (
        checked: boolean,
        issueId: string,
        event?: React.MouseEvent,
    ) => void;
    refetch: (options?: RefetchOptions) => Promise<GetOnboardingResponse>;
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void;
    deleteIssue: (
        userID: string,
        issueId: string,
        item: OnboardingChecklist,
    ) => void;
    setSheetOpen: (open: boolean) => void;
    onAddChild: (parentId: string) => void;
    setTopLevelEditing: (editing: { edit: boolean; parentId: string }) => void;
}

export const OnboardingCard = ({
    item,
    users,
    saveIssue,
    userID,
    isSelected,
    onSelect,
    refetch,
    updateIssueState,
    deleteIssue,
    setSheetOpen,
    onAddChild,
    setTopLevelEditing,
}: OnboardingCardProps) => {
    const handleCheckboxClick = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onSelect?.(!isSelected, `${item.id}`, e);
        },
        [isSelected, item.id, onSelect],
    );

    const [editing, setEditing] = useState(false);
    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === item.assignee_user_id),
        [item.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    function getIcon() {
        if (item.completed) {
            return (
                <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-green3 border border-green4">
                    <CircleIcon
                        className="text-green9 h-4 w-4"
                        strokeWidth={3}
                    />
                </div>
            );
        } else {
            return (
                <div className="flex h-6 w-6 p-1 items-center justify-center rounded-lg bg-blue3 border border-blue4">
                    <FilePlus2Icon
                        strokeWidth={1.5}
                        className="h-4 w-4 text-blue9"
                    />
                </div>
            );
        }
    }

    const dropdownOptions = ["Assignee"]; // todo: introduce tag, topic, status

    const handleSave = useCallback(
        (type: string, payload: Partial<OnboardingChecklistPayload>) => {
            saveIssue(
                type,
                payload,
                userID,
                updateIssueState,
                refetch,
                item.id,
                item,
                new Map(),
            );
        },
        [userID, refetch, saveIssue, item, updateIssueState],
    );

    const handleDelete = useCallback(() => {
        deleteIssue(userID, item.id, item);
    }, [userID, item, deleteIssue]);

    const [startDate, setStartDate] = useState<Date | undefined>(
        item.start_date,
    );
    const [deadline, setDeadline] = useState<Date | undefined>(item.deadline);
    const [name, setName] = useState<string>(item.name);
    const [description, setDescription] = useState<string>(item.description);

    const [assignee, setAssignee] = useState<GetUserResponse>({
        id: item.assignee_user_id,
        email: "",
        username: userName,
        first_name: "",
        last_name: "",
        picture_url: pictureURL,
        user_role: "",
    } as GetUserResponse);

    const saveAssignee = (assignee: GetUserResponse) => {
        setAssignee(assignee);
    };

    const saveEdit = () => {
        handleSave("ALL", {
            name: name,
            description: description,
            deadline: deadline,
            start_date: startDate,
            assignee_user_id: assignee.id,
        });
        setEditing(false);
    };

    useEffect(() => {
        setTopLevelEditing({ edit: editing, parentId: item.parent_id });
    }, [editing, setTopLevelEditing, item.parent_id]);

    return (
        <Card
            onClick={() => {
                if (!editing) {
                    setSheetOpen(true);
                } else {
                    setSheetOpen(false);
                }
            }}
            className={`w-full rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col px-1 shadow-none border relative bg-white ${!item.enabled ? "opacity-50" : ""} ${item.completed ? "bg-[#fbfbfb]" : ""}`}
        >
            <CardContent className="p-3">
                <OnboardingContextMenu
                    issueId={item.id}
                    dropdownOptions={dropdownOptions}
                    onSave={handleSave}
                    topics={[]}
                    users={users}
                    teams={[]}
                    filters={new Map()}
                    categories={[]}
                    enabled={item.enabled}
                    completed={item.completed}
                    editing={editing}
                    setEdit={setEditing}
                    onDelete={handleDelete}
                    onAddChild={onAddChild}
                >
                    <div className="flex items-center gap-4">
                        <div
                            className="h-[100%]"
                            onClick={handleCheckboxClick}
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    e.stopPropagation();
                                }
                            }}
                        >
                            <Checkbox
                                checked={isSelected}
                                className={`${
                                    isSelected ? "opacity-100" : "opacity-0"
                                } hover:opacity-100 hover:shadow-[0_0_10px_4px] hover:shadow-iris5 lb-comment-details data-[state=checked]:bg-[#5e6ad2] data-[state=checked]:text-[#eceefb] outline-1 outline hover:outline-iris10 outline-[#eceefb] cursor-pointer`}
                                data-checked={isSelected}
                            />
                        </div>

                        <div className="flex items-center gap-2">
                            {getIcon()}
                            {editing ? (
                                <Input
                                    defaultValue={item.name}
                                    className="text-xs font-medium"
                                    placeholder="Task name"
                                    onChange={(e) => setName(e.target.value)}
                                />
                            ) : (
                                <span className="text-sm font-medium">
                                    {item.name}
                                </span>
                            )}
                        </div>
                        <div className="flex items-center gap-2 ml-auto">
                            {editing ? (
                                <CalendarPopup
                                    date={startDate}
                                    buttonText="Start date"
                                    onSelect={setStartDate}
                                />
                            ) : (
                                item.start_date && (
                                    <CalendarBadge date={item.start_date} />
                                )
                            )}
                            {editing || (item.start_date && item.deadline) ? (
                                <ArrowRightIcon className="h-3 w-3" />
                            ) : (
                                <></>
                            )}
                            {editing ? (
                                <CalendarPopup
                                    date={deadline}
                                    onSelect={setDeadline}
                                    buttonText="End date"
                                    iconColor="text-red-500"
                                />
                            ) : (
                                item.deadline && (
                                    <CalendarBadge
                                        date={item.deadline}
                                        iconColor="text-red-500"
                                    />
                                )
                            )}

                            {editing ? (
                                <OnboardingAssigneeDropdown
                                    assigneeUserID={assignee.id}
                                    users={users}
                                    userID={userID}
                                    saveAssignee={saveAssignee}
                                />
                            ) : item.assignee_user_id &&
                              item.assignee_user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )}
                        </div>
                        {editing && (
                            <div className="flex flex-row gap-2">
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setEditing(false);
                                    }}
                                    variant="outline"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        saveEdit();
                                    }}
                                    variant="default"
                                    className="bg-iris10"
                                >
                                    Save
                                </Button>
                            </div>
                        )}
                    </div>
                </OnboardingContextMenu>
            </CardContent>
        </Card>
    );
};

interface NewOnboardingCardProps {
    removeCard: () => void;
    handleSave: (onboardingItem: OnboardingChecklistPayload) => void;
    parentId?: string;
    users: GetUserResponse[];
    userID: string;
}
export const NewOnboardingCard = ({
    removeCard,
    handleSave,
    parentId,
    users,
    userID,
}: NewOnboardingCardProps) => {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [deadline, setDeadline] = useState<Date | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [assignee, setAssignee] = useState<GetUserResponse>({
        id: "",
        email: "",
        username: "",
        first_name: "",
        last_name: "",
        picture_url: "",
        user_role: "",
    } as GetUserResponse);

    const saveAssignee = (assignee: GetUserResponse) => {
        setAssignee(assignee);
    };

    return (
        <Card
            className={
                "w-full shadow-md shadow-iris5 rounded-lg hover:outline-0.5 hover:outline-offset-0"
            }
        >
            <CardContent className="p-4">
                <div className="flex items-center gap-3">
                    <div className="flex flex-row gap-2 w-full">
                        <Input
                            className="text-xs"
                            placeholder="Task name"
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Input
                            className="text-xs text-gray-500"
                            placeholder="Task description"
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <CalendarPopup
                            date={startDate}
                            onSelect={setStartDate}
                            buttonText="Start date"
                        />
                        <CalendarPopup
                            date={deadline}
                            onSelect={setDeadline}
                            buttonText="End date"
                        />
                        <OnboardingAssigneeDropdown
                            users={users}
                            userID={userID}
                            saveAssignee={saveAssignee}
                        />
                        <div className="flex justify-end">
                            <div className="flex flex-row gap-2">
                                <Button
                                    onClick={() => removeCard()}
                                    variant="outline"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() =>
                                        handleSave({
                                            name,
                                            description,
                                            deadline: deadline,
                                            start_date: startDate,
                                            completed: false,
                                            assignee_user_id: assignee.id,
                                            enabled: true,
                                            parent_id: parentId ?? "",
                                            user_id: userID,
                                        })
                                    }
                                    variant="default"
                                    className="bg-iris10"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};
