import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { CheckIcon } from "@radix-ui/react-icons";
import React from "react";

interface TimezoneDropdownProps {
    button: React.ReactNode;
    onTimezoneSelect: (timezone: string) => void;
    searchPlaceholder?: string;
    selectedTimezone?: string;
}

const TimezoneDropdown: React.FC<TimezoneDropdownProps> = ({
    button,
    onTimezoneSelect,
    searchPlaceholder = "Choose timezone",
    selectedTimezone = "",
}) => {
    const timezones = Intl.supportedValuesOf("timeZone").map((tz) => ({
        value: tz,
        label: tz.replace("_", " "), // Format for display, replace underscores with spaces
    }));
    const [tzSearch, setTzSearch] = React.useState("");

    const filteredTimezones = timezones.filter(
        (timezone) =>
            timezone.label.toLowerCase().includes(tzSearch.toLowerCase()) ||
            timezone.value.toLowerCase().includes(tzSearch.toLowerCase()),
    );

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>{button}</DropdownMenuTrigger>
            <DropdownMenuContent className="max-h-70 w-45">
                <Command className="rounded-md shadow-md text-xs pb-1">
                    <CommandInput
                        placeholder={searchPlaceholder}
                        onValueChange={setTzSearch}
                        className="text-xs"
                    />
                    <CommandList className="space-y-1">
                        <CommandEmpty className="px-4 py-2 text-xs">
                            No timezones found
                        </CommandEmpty>
                        {filteredTimezones.map((timezone) => (
                            <CommandItem
                                key={timezone.value}
                                onSelect={() => {
                                    onTimezoneSelect(timezone.value);
                                }}
                                className="px-3 py-1.5"
                            >
                                <div className="flex items-center gap-2 text-xs font-normal">
                                    {timezone.label}
                                    {timezone.value === selectedTimezone && (
                                        <CheckIcon className="h-4 w-4" />
                                    )}
                                </div>
                            </CommandItem>
                        ))}
                    </CommandList>
                </Command>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default TimezoneDropdown;
