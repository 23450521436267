import {
    Background,
    type Connection,
    ControlButton,
    Controls,
    type Edge,
    type EdgeProps,
    type Node,
    type OnEdgesChange,
    type OnNodesChange,
    ReactFlow,
    ReactFlowProvider,
    addEdge,
    useReactFlow,
} from "@xyflow/react";
import type React from "react";
import "@xyflow/react/dist/style.css";
import { Card } from "@/component/shadcn/ui/card";
import type { Workflow, WorkflowStep } from "@/interfaces/serverData";
import {
    blockHeight,
    capitalizeType,
    stepToNodeMetadata,
} from "@/utilities/methods";
import { TrashIcon } from "@radix-ui/react-icons";
import { Box, Skeleton, Text } from "@radix-ui/themes";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { ConnectionMode } from "reactflow";
import { ActionsIcon, ConditionIcon, TriggerIcon, WaitIcon } from "./Icons";
import ActionNode from "./Nodes/ActionNode";
import ConditionNode from "./Nodes/ConditionNode";
import TriggerNode from "./Nodes/TriggerNode";
import WaitNode from "./Nodes/WaitNode";
import { useWorkflowOptionsData } from "./useWorkflowOptionsData";

const constNodeNames = {
    trigger: "trigger",
    action: "action",
    condition: "condition",
    wait: "wait",
};

const NODE_TYPE_KEY = "node-type";

interface WorkflowEditorProps {
    nodes: never[];
    edges: never[];
    setNodes: React.Dispatch<React.SetStateAction<never[]>>;
    setEdges: React.Dispatch<React.SetStateAction<never[]>>;
    onNodesChange: OnNodesChange<never>;
    onEdgesChange: OnEdgesChange<never>;
    setInitialNodes: React.Dispatch<React.SetStateAction<Node[]>>;
    setInitialEdges: React.Dispatch<React.SetStateAction<Edge[]>>;
    workflow?: Workflow;
    editable?: boolean;
    workflowKey?: number;
}

const WorkflowEditor: React.FC<WorkflowEditorProps> = ({
    nodes,
    edges,
    setNodes,
    setEdges,
    onNodesChange,
    onEdgesChange,
    setInitialNodes,
    setInitialEdges,
    workflow,
    editable = true,
    workflowKey = 0,
}) => {
    const { screenToFlowPosition, fitView } = useReactFlow();
    const containerRef = useRef<HTMLDivElement>(null);
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    const {
        categoriesQuery,
        topicsQuery,
        teamsQuery,
        customerGroupsQuery,
        channelsQuery,
        usersQuery,
        templatesQuery,
        customers,
        companies,
        isLoading,
    } = useWorkflowOptionsData(); // TODO: input team ID

    // Add an initial trigger node
    useEffect(() => {
        // Wait until all queries are finished loading
        if (isLoading) {
            return;
        }

        if (workflow) {
            const initialNodes: {
                id: string;
                type: string;
                position: {
                    x: number;
                    y: number;
                };
                data: {
                    label: string;
                    id: string;
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    metadata: Record<string, any>;
                };
            }[] = [];
            const initialEdges: {
                id: string;
                source: string;
                target: string;
                type: string;
                sourceHandle: string;
                targetHandle: string;
            }[] = [];
            let currY = 15;
            const parentToChild = new Map<string, WorkflowStep[]>();
            // TODO: in the future, the first the step can be more than one trigger node (or relationship)
            let firstStep: WorkflowStep | undefined = undefined;
            for (const step of workflow.steps) {
                const parentID = step.parent_id;
                if (parentID) {
                    if (parentToChild.has(parentID)) {
                        const val: WorkflowStep[] =
                            parentToChild.get(parentID) ?? [];
                        val.push(step);
                        parentToChild.set(parentID, val);
                    } else {
                        parentToChild.set(parentID, [step]);
                    }
                } else {
                    firstStep = step;
                }
            }

            if (firstStep) {
                let step: WorkflowStep | undefined = firstStep;
                while (step) {
                    const metadata = stepToNodeMetadata(
                        step.type,
                        step.name,
                        step.subtype,
                        new Map(Object.entries(step.metadata || {})),
                    )
                    const node = {
                        id: step.id,
                        type: step.type,
                        position: { x: 150, y: currY }, // Adjusting the position based on the centerX
                        data: {
                            label: capitalizeType(step.type),
                            id: step.id,
                            metadata: metadata,
                        },
                    };
                    initialNodes.push(node);

                    // Add bigger vertical spacing if was a condition node
                    currY += blockHeight(step.type, metadata);

                    if (step.parent_id) {
                        // Add an edge
                        const edge = {
                            id: `edge-${step.parent_id}-${step.id}`,
                            source: step.parent_id,
                            target: step.id,
                            type: "default", // Type of edge, e.g., 'default', 'smoothstep'
                            sourceHandle: "b",
                            targetHandle: "a",
                        };
                        initialEdges.push(edge);
                    }
                    step =
                        parentToChild.get(step.id) &&
                            parentToChild.get(step.id)?.length === 1
                            ? parentToChild?.get(step.id)?.[0]
                            : undefined;
                }
            }
            setNodes(initialNodes);
            setEdges(initialEdges);
            setInitialNodes(initialNodes);
            setInitialEdges(initialEdges);
        } else {
            if (containerRef.current && !workflow) {
                // Only add default if no workflow exists
                const containerWidth = containerRef.current.offsetWidth;
                const centerX = containerWidth / 2;

                const newNodeID = crypto.randomUUID();
                const initialNode = {
                    id: newNodeID,
                    type: "defaultTrigger",
                    position: { x: centerX - 175, y: 15 },
                    data: {
                        label: "Trigger",
                        id: newNodeID,
                        metadata: { type: "issue_created" },
                    },
                    deletable: false, // Make it non-deletable
                };
                setNodes([initialNode]);
            }
        }
    }, [setNodes, setEdges, workflow, isLoading]);

    // Handle updating the metadata of a specific node
    const onUpdate = useCallback(
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        (id: string, updatedMetadata: any) => {
            setNodes((nds) => {
                // If the id is undefined and there's only one node, update the first node.
                if (id === undefined && nds.length === 1) {
                    return [
                        {
                            ...nds[0],
                            data: { ...nds[0].data, metadata: updatedMetadata },
                        },
                    ];
                }

                // Otherwise, update the node with the matching id.
                return nds.map((node) => {
                    return node.id === id
                        ? {
                            ...node,
                            data: { ...node.data, metadata: updatedMetadata },
                        }
                        : node;
                });
            });
        },
        [setNodes],
    );

    const nodeTypes = useMemo(
        () => ({
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            trigger: (props: any) => (
                <TriggerNode
                    {...props}
                    onUpdate={onUpdate}
                    categoriesQuery={categoriesQuery}
                    topicsQuery={topicsQuery}
                    workflow={workflow}
                />
            ),
            defaultTrigger: (props: any) => (
                <TriggerNode
                    {...props}
                    onUpdate={onUpdate}
                    categoriesQuery={categoriesQuery}
                    topicsQuery={topicsQuery}
                    workflow={workflow}
                    defaultTrigger
                />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            condition: (props: any) => (
                <ConditionNode
                    {...props}
                    onUpdate={onUpdate}
                    categoriesQuery={categoriesQuery}
                    topicsQuery={topicsQuery}
                    teamsQuery={teamsQuery}
                    customerGroupsQuery={customerGroupsQuery}
                    channelsQuery={channelsQuery}
                    workflow={workflow}
                    customers={customers}
                    companies={companies}
                />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            wait: (props: any) => (
                <WaitNode {...props}
                    onUpdate={onUpdate}
                    categoriesQuery={categoriesQuery}
                    topicsQuery={topicsQuery}
                    workflow={workflow} />
            ),
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
            action: (props: any) => (
                <ActionNode
                    {...props}
                    onUpdate={onUpdate}
                    categoriesQuery={categoriesQuery}
                    topicsQuery={topicsQuery}
                    teamsQuery={teamsQuery}
                    customerGroupsQuery={customerGroupsQuery}
                    usersQuery={usersQuery}
                    templatesQuery={templatesQuery}
                    workflow={workflow}
                />
            ),
        }),
        [onUpdate, isLoading, workflow],
    );

    const ArrowEdge = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
    }: EdgeProps) => {
        const path = `M${sourceX},${sourceY}L${targetX},${targetY}`;
        return (
            <path
                id={id}
                d={path}
                fill="transparent"
                stroke="#000"
                strokeWidth={2}
                markerEnd="url(#arrowhead)" // Applying the arrowhead marker to the edge
            />
        );
    };

    const edgeTypes = {
        custom: ArrowEdge,
    };

    const listNodeTypes = [
        { name: constNodeNames.trigger, label: "Trigger", icon: TriggerIcon },
        {
            name: constNodeNames.condition,
            label: "Condition",
            icon: ConditionIcon,
        },
        { name: constNodeNames.wait, label: "Wait", icon: WaitIcon },
        { name: constNodeNames.action, label: "Action", icon: ActionsIcon },
    ];

    // Disable connections if not editable
    const onConnect = useCallback(
        (params: Connection) => {
            if (editable) {
                setEdges((eds) => addEdge(params, eds));
            }
        },
        [setEdges, editable],
    );

    const onDragStart = useCallback(
        (event: React.DragEvent, nodeType: string) => {
            event.dataTransfer?.setData(NODE_TYPE_KEY, nodeType);
            if (event.dataTransfer) {
                event.dataTransfer.effectAllowed = "move";
            }
        },
        [],
    );

    const onDragOver = useCallback((event: React.DragEvent) => {
        event.preventDefault();
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = "move";
        }
    }, []);

    const onDrop = useCallback(
        (event: React.DragEvent) => {
            event.preventDefault();

            if (editable) {
                const type = event.dataTransfer.getData(NODE_TYPE_KEY);

                if (!type) return;

                const position = screenToFlowPosition({
                    x: event.clientX,
                    y: event.clientY,
                });

                // Event location is always offset to the right so manually adjusting the position to move it to the left
                const adjustedPosition = {
                    x: position.x - 500,
                    y: position.y - 50,
                };

                const startingMetadata = (() => {
                    switch (type) {
                        case "trigger":
                        case "defaultTrigger":
                            return { type: "issue_created" };
                        case "condition":
                            return { subtype: "all" };
                        case "wait":
                            return { unit: "days", time: 30, subtype: "none" };
                        case "action":
                            return { subtype: "send_template" };
                        default:
                            return {}; // default case, returns an empty object if type doesn't match
                    }
                })();

                const newNodeID = crypto.randomUUID();
                const newNode = {
                    id: newNodeID,
                    type,
                    position: adjustedPosition,
                    data: {
                        label: `${type} node`,
                        id: newNodeID,
                        metadata: startingMetadata,
                    },
                };

                setNodes((nds) => nds.concat(newNode));
            }
        },
        [editable, screenToFlowPosition, setNodes],
    );

    const deleteSelectedElements = useCallback(() => {
        // Delete selected nodes (except default trigger)
        setNodes((nds) =>
            nds.filter(
                (node) => !node.selected || node.type === "defaultTrigger",
            ),
        );

        // Delete selected edges
        setEdges((eds) => eds.filter((edge) => !edge.selected));
    }, [setNodes, setEdges]);

    const isValidConnection = useCallback((connection: Connection) => {
        // Prevent self-connections and connections to the default trigger
        return (
            connection.source !== connection.target &&
            connection.target !== "default-trigger"
        ); // Prevent incoming connections to default trigger
    }, []);

    return (
        <Box className="h-screen">
            {isLoading ? (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            ) : (
                <ReactFlowProvider>
                    <Box
                        style={{ width: "calc(97vw)", height: "calc(80vh)" }}
                        className="relative"
                        ref={containerRef}
                    >
                        <ReactFlow
                            // TODO: fix cancel - re-render back to intialnodes
                            key={
                                workflow
                                    ? workflowKey + workflow.id
                                    : workflowKey
                            }
                            nodes={nodes}
                            edges={edges}
                            onNodesChange={onNodesChange}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            isValidConnection={isValidConnection}
                            onDragOver={onDragOver}
                            onDrop={onDrop}
                            fitView={false}
                            nodeTypes={nodeTypes}
                            edgeTypes={edgeTypes}
                            zoom={0.5}
                            connectionMode={ConnectionMode.Loose}
                            // Disable actions if not editable
                            nodesDraggable={editable}
                            nodesConnectable={editable}
                            elementsSelectable={editable}
                        >
                            <defs>
                                <marker
                                    id="arrowhead"
                                    viewBox="0 0 10 10"
                                    refX="5"
                                    refY="5"
                                    markerWidth="20"
                                    markerHeight="6"
                                    orient="auto"
                                >
                                    <path
                                        d="M 0 0 L 10 5 L 0 10 z"
                                        fill="#000"
                                    />
                                </marker>
                            </defs>
                            <Background />
                            <Controls>
                                <ControlButton
                                    onClick={deleteSelectedElements}
                                    title="Delete selected elements"
                                    aria-label="Delete selected elements"
                                >
                                    <TrashIcon />
                                </ControlButton>
                            </Controls>
                        </ReactFlow>

                        <div className="absolute top-0 left-0 p-2 z-10 bg-white bg-opacity-80 rounded-lg shadow-md flex flex-col gap-1">
                            {listNodeTypes.map((node) => (
                                <Card
                                    key={node.name}
                                    className="flex flex-row items-center gap-2 bg-white shadow-none border rounded-lg relative p-2 w-[120px]"
                                    onDragStart={(event) =>
                                        onDragStart(event, node.name)
                                    }
                                    draggable
                                >
                                    {node.icon && <node.icon />}
                                    <p className="text-xs">{node.label}</p>
                                </Card>
                            ))}
                        </div>
                    </Box>
                </ReactFlowProvider>
            )}
        </Box>
    );
};

export default WorkflowEditor;
