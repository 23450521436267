"use client";

import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import type { ScopeResponse } from "@/interfaces/serverData";
import { Command as CommandPrimitive } from "cmdk";
import { X } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";

import { Badge } from "@radix-ui/themes";

interface MultiSelectProps {
    options: ScopeResponse[];
    placeholder: string;
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    selectedChannels: ScopeResponse[];
    getName?: (option: ScopeResponse) => string;
    className?: string;
}

export function FancyMultiSelect({
    className,
    options,
    placeholder,
    setSelectedChannels,
    selectedChannels,
    getName = (option: ScopeResponse) => option?.name ?? "",
}: MultiSelectProps) {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState("");

    const [selectables, setSelectables] = React.useState<ScopeResponse[]>(
        options?.filter(
            (option) =>
                !selectedChannels.some(
                    (selected) => selected.key === option.key,
                ),
        ),
    );

    useEffect(() => {
        setSelectables(
            options?.filter(
                (option) =>
                    !selectedChannels.some(
                        (selectedItem) => selectedItem.key === option.key,
                    ),
            ),
        );
    }, [selectedChannels, options]);

    const handleUnselect = React.useCallback(
        (framework: ScopeResponse) => {
            setSelectedChannels((prev) =>
                prev.filter((s) => s.key !== framework.key),
            );
        },
        [setSelectedChannels],
    );

    const handleKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            const input = inputRef.current;
            if (input) {
                if (e.key === "Delete" || e.key === "Backspace") {
                    if (input.value === "") {
                        setSelectedChannels((prev) => {
                            const newSelected = [...prev];
                            newSelected.pop();
                            return newSelected;
                        });
                    }
                }
                // This is not a default behaviour of the <input /> field
                if (e.key === "Escape") {
                    input.blur();
                }
            }
        },
        [setSelectedChannels],
    );

    return (
        <Command
            onKeyDown={handleKeyDown}
            className={`overflow-visible bg-transparent ${className}`}
        >
            <div className="group rounded-xl border border-gray-300 px-2 py-1 text-xs ">
                <div className="flex flex-wrap gap-1">
                    {selectedChannels.map((framework) => {
                        return (
                            <Badge key={framework.name} color="iris">
                                <div className="flex flex-row items-center justify-center p-1">
                                    <p className="text-xs">{framework.name}</p>
                                    <button
                                        key={framework.name}
                                        className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                handleUnselect(framework);
                                            }
                                        }}
                                        onMouseDown={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                        onClick={() =>
                                            handleUnselect(framework)
                                        }
                                        type="button"
                                    >
                                        <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                                    </button>
                                </div>
                            </Badge>
                        );
                    })}
                    {/* Avoid having the "Search" Icon */}
                    <CommandPrimitive.Input
                        ref={inputRef}
                        value={inputValue}
                        onValueChange={setInputValue}
                        onBlur={() => setOpen(false)}
                        onFocus={() => setOpen(true)}
                        placeholder={placeholder}
                        className="ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
                    />
                </div>
            </div>
            <div className={`relative mt-2 ${className}`}>
                <CommandList className={className}>
                    {open && selectables?.length > 0 ? (
                        <div className="text-xs absolute top-0 z-[100] w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in">
                            <CommandGroup className="max-h-48 overflow-y-auto">
                                {selectables?.map((framework) => {
                                    return (
                                        <CommandItem
                                            key={framework.key}
                                            onMouseDown={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                            }}
                                            onSelect={(value) => {
                                                setInputValue("");
                                                setSelectedChannels((prev) => [
                                                    ...prev,
                                                    framework,
                                                ]);
                                            }}
                                            className={
                                                "bg-white cursor-pointer text-xs"
                                            }
                                        >
                                            {getName(framework)}
                                        </CommandItem>
                                    );
                                })}
                            </CommandGroup>
                        </div>
                    ) : null}
                </CommandList>
            </div>
        </Command>
    );
}
