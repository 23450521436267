import { ContactsAPI, URLS } from "@/constant";
import type {
    OnboardingChecklist,
    OnboardingChecklistPayload,
} from "@/interfaces/serverData";
import type { AxiosInstance } from "axios";

export function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
    currentItem: OnboardingChecklist,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            updateIssueState({ assignee_user_id: assigneeID }, currentItem);
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export function saveCompleted(
    completed: boolean,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
    currentItem: OnboardingChecklist,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        completed: completed,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            updateIssueState({ completed: completed }, currentItem);
            console.log(`Updated completed to ${completed} successfully`);
        } else {
            console.log("Call to update completed failed");
        }
    });
}

export function saveEnabled(
    enabled: boolean,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
    currentItem: OnboardingChecklist,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        enabled: enabled,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            updateIssueState({ enabled: enabled }, currentItem);
            console.log(`Updated enabled to ${enabled} successfully`);
        } else {
            console.log("Call to update enabled failed");
        }
    });
}

export function deleteItem(
    api: AxiosInstance,
    ids: string[],
    deleteIssue: (ids: string[]) => void,
    currentItem: OnboardingChecklist,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
    };
    api.post(
        `${URLS.serverUrl}${ContactsAPI.deleteOnboardingChecklistItem.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            deleteIssue(ids);
            console.log("Deleted item successfully");
        } else {
            console.log("Call to delete item failed");
        }
    });
}

export function saveAll(
    payload: Partial<OnboardingChecklistPayload>,
    api: AxiosInstance,
    ids: string[],
    updateIssueState: (
        newState: Partial<OnboardingChecklist>,
        oldItem: OnboardingChecklist,
    ) => void,
    currentItem: OnboardingChecklist,
    userID: string,
) {
    const requestData = {
        ids: ids,
        source: "Web",
        user_id: userID,
        assignee_user_id: payload.assignee_user_id,
        name: payload.name,
        description: payload.description,
        deadline: payload.deadline,
        start_date: payload.start_date,
    };
    api.patch(
        `${URLS.serverUrl}${ContactsAPI.saveOnboardingChecklist.url}`,
        requestData,
        {
            headers: {
                "Content-Type": "application/json",
            },
        },
    ).then((res) => {
        if (res.status === 200) {
            updateIssueState(payload, currentItem);
            console.log("Updated all fields successfully");
        } else {
            console.log("Call to update completed failed");
        }
    });
}
