import type React from "react";
import { useEffect, useState } from "react";
import { Button } from "./shadcn/ui/button";
import { Input } from "./shadcn/ui/input";
import { Dialog, DialogContent, DialogDescription } from "./shadcn/ui/dialog";

interface PopupProps {
    open: boolean;
    text: string;
    suggestText: string;
    onClickConfirm: () => void;
    onCancel: () => void;
    jiraUrl: string;
    setJiraUrl: (event: React.ChangeEvent<HTMLInputElement>) => void;
    invalidUrl: boolean;
}

const URLCustomPopup: React.FC<PopupProps> = ({
    open,
    text,
    suggestText,
    onClickConfirm,
    onCancel,
    jiraUrl,
    setJiraUrl,
    invalidUrl,
}) => {
    useEffect(() => {
        if (!invalidUrl && jiraUrl.length > 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [invalidUrl, jiraUrl]);

    const [disabledButton, setDisabledButton] = useState<boolean>(true);

    return (
        <Dialog open={open} onOpenChange={(open) => onCancel()}>
            <DialogContent className="flex flex-col gap-5">
                <DialogDescription>{text}</DialogDescription>

                <Input
                    value={jiraUrl}
                    onChange={setJiraUrl}
                    id="outlined-basic"
                    placeholder={suggestText}
                />

                <div className="flex flex-row gap-2 justify-end">
                    <Button
                        onClick={onClickConfirm}
                        disabled={disabledButton}
                        type="submit"
                        variant="secondary"
                    >
                        Activate
                    </Button>
                    <Button onClick={onCancel} type="button" variant="outline">
                        Cancel
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default URLCustomPopup;
