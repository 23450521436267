import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import type {
    Account,
    Category,
    CustomerGroup,
    GetUserResponse,
    ScopeResponse,
    Teams,
    Template,
    Topic,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getLowercase,
    getOptions,
    getPlural,
    getStatusIcon,
} from "@/utilities/methods";
import {
    AllSidesIcon,
    AvatarIcon,
    CheckIcon,
    ComponentBooleanIcon,
    CrossCircledIcon,
    DotFilledIcon,
    GroupIcon,
    ImageIcon,
    QuestionMarkIcon,
} from "@radix-ui/react-icons";
import { Badge, Button } from "@radix-ui/themes";
import { BoxIcon, HouseIcon, NotepadText, UsersIcon } from "lucide-react";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import {
    insightStatusPriorityOrder,
    sources,
    ticketStatusPriorityOrder,
} from "./constants";

export const ElementDisplay = (
    type: string,
    label: string,
    value: string,
    color: string,
    filters: Map<string, Set<string | { label: string; value: string; key: string; color: string }>>,
    categories: Category[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    channels: Map<string, ScopeResponse[]>,
    customers: Account[],
    companies: Account[],
) => {
    const typeFilters = filters.get(type);
    let isSelected = typeFilters?.has(value) || [...typeFilters || []].some((elem) => {
        if (typeof elem === 'string') {
            // If it's a string, check if it matches the value
            return elem === value;
        } else if (typeof elem === 'object' && elem !== null) {
            const typedElem = elem as unknown as { label: string; value: string; key: string; color: string };
            return typedElem.key === value || typedElem.value === value;
        }
        return false;
    });
    if (sources.map(source => source.value).includes(type)) {
        isSelected = filters.get("Source")?.has(value) ?? false
    }
    switch (type) {
        case "Source": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ??
                AllSidesIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Broadcast": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Tag":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TAG" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : (value === "Any interaction type" ? (
                                <BoxIcon className="w-3 h-3 mr-0.5" />
                            ) : (
                                <ComponentBooleanIcon
                                    color={
                                        categories.find(
                                            (category) =>
                                                category.name === value,
                                        )?.color ?? "gray"
                                    }
                                />
                            )
                            )}
                            <p className="pl-0.5">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Topic":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TOPIC" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : value === "NONE" ? (
                                <CrossCircledIcon className="mr-1" />
                            ) : value === "Any tag" ? (
                                <BoxIcon className="w-3 h-3 mr-1" />
                            ) : (
                                <DotFilledIcon
                                    color={color !== "" ? color : "#9B9EF0"}
                                    style={{
                                        transform: "scale(1.8)",
                                    }}
                                />
                            )}
                            <p className="pl-0.3">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Status":
        case "Insight Status": {
            const Icon = getStatusIcon(value ?? "Unknown");
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Assignee": {
            const pictureURL = users.find(
                (user) => user.id === value,
            )?.picture_url;
            return (
                <div className="flex items-center gap-2">
                    <div className="lb-avatar rounded-lg w-5 h-5">
                        {pictureURL ? (
                            <img
                                className="lb-avatar-image"
                                src={pictureURL}
                                alt={label}
                            />
                        ) : (
                            <AvatarIcon className="w-6 h-6" />
                        )}
                    </div>
                    <span className="lb-comment-author text-xs font-normal font-destructive">
                        {label}
                    </span>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Company": {
            const fullCompany = companies.find(
                (c) => c.id === value,
            );
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        {fullCompany?.image_url !== "" &&
                            fullCompany?.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={fullCompany?.image_url}
                                    alt={fullCompany?.name}
                                />
                            </div>
                        ) : (
                            <ImageIcon className="w-4 h-4" />
                        )}
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Customer": {
            const fullCustomer = customers.find(
                (c) => c.id === value,
            );
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        {fullCustomer?.image_url !== "" &&
                            fullCustomer?.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-4 h-4">
                                <img
                                    className="lb-avatar-image"
                                    src={fullCustomer?.image_url}
                                    alt={fullCustomer?.name}
                                />
                            </div>
                        ) : (
                            <ImageIcon className="w-4 h-4" />
                        )}
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Customer Group": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <GroupIcon />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Team": {
            return (
                <div className="flex items-center gap-2">
                    {label === "General" ? (
                        <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                            <HouseIcon
                                className="text-iris9"
                                strokeWidth={1.5}
                                size={12}
                            />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                            <UsersIcon
                                className="text-red9"
                                strokeWidth={1.5}
                                size={12}
                            />
                        </div>
                    )}
                    {label}
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Slack":
        case "Discord":
        case "CommunitySlack":
        case "Gmail":
        case "API": {
            const [source, channelName] = label.split(" -- ");
            const formattedLabel = channelName ? channelName.trim() : label;
            const formattedSource = source ? source.trim() : "";
            const Icon =
                integrationBackEndDataMappingToSvg.get(formattedSource) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {formattedLabel}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Template": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <NotepadText className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        default:
            return (
                <div className="flex items-center gap-2">
                    {label}
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
    }
};

interface FilterDropdownElementProps {
    type: string;
    filters: Map<string, Set<string | { label: string; value: string; key: string; color: string }>>;
    handleItemSelect: (type: string, option: { label: string; value: string; key: string; color: string }, index?: number) => () => void;
    categories: Category[];
    topics: Topic[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    teams: Teams[];
    isSavedViewFilter: boolean;
    extraOptions?: { color: string; label: string; value: string; key: string }[];
    channels: Map<string, ScopeResponse[]>;
    key?: string;
    index?: number;
    templates?: Template[];
    customers?: Account[];
    companies?: Account[];
    organizationSources?: string[],
}

function FilterDropdownElement({
    type,
    filters,
    handleItemSelect,
    categories,
    topics,
    users,
    customerGroups,
    teams,
    isSavedViewFilter,
    extraOptions,
    channels,
    key,
    index,
    templates,
    customers,
    companies,
    organizationSources
}: FilterDropdownElementProps) {
    const options: { label: string; value: string; key: string; color: string }[] =
        // TODO: the last key is new, check it didn't break anything
        getOptions(
            type,
            topics,
            users,
            customerGroups,
            categories,
            teams,
            channels,
            templates ?? [],
            customers ?? [],
            companies ?? [],
            organizationSources
        ) ?? [];

    return (
        <Command className="rounded-md shadow-md text-xs pb-1" aria-disabled>
            <CommandInput
                placeholder={`Filter ${getLowercase(type)}...`}
                className="px-1 text-[13px]"
            />
            <CommandList className="space-y-1">
                <CommandEmpty className="text-xs px-4 py-2">
                    {`No ${getPlural(type)} found`}
                </CommandEmpty>
                {extraOptions?.map((extraOption) => (
                    <CommandItem
                        key={extraOption.value}
                        className="px-1 py-0.5"
                    >
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={index === undefined ? handleItemSelect(type, extraOption) : handleItemSelect(type, extraOption, index)}
                            className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                        >
                            {ElementDisplay(
                                type,
                                extraOption.label,
                                extraOption.value,
                                extraOption.color,
                                filters,
                                categories,
                                users,
                                customerGroups,
                                channels,
                                customers ?? [],
                                companies ?? []
                            )}
                        </Button>
                    </CommandItem>
                ))}
                {options
                    .sort((a, b) => {
                        // Statuses has a specific priority order
                        if (type === "Status") {
                            return (
                                ticketStatusPriorityOrder.indexOf(a.value) -
                                ticketStatusPriorityOrder.indexOf(b.value)
                            );
                        }
                        if (type === "Insight Status") {
                            return (
                                insightStatusPriorityOrder.indexOf(a.value) -
                                insightStatusPriorityOrder.indexOf(b.value)
                            );
                        }
                        const prioritizeLabel = "No Assignee";
                        if (
                            a.label === prioritizeLabel &&
                            b.label !== prioritizeLabel
                        ) {
                            return -1;
                        }
                        if (
                            b.label === prioritizeLabel &&
                            a.label !== prioritizeLabel
                        ) {
                            return 1;
                        }
                        // Default sorting by label if neither is prioritized
                        return a.label.localeCompare(b.label);
                    })
                    .map((option) => (
                        <CommandItem key={option.value} className="px-1 py-0.5">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={
                                    isSavedViewFilter
                                        ? undefined
                                        // TODO: pass in full option including id somehow
                                        : (index === undefined ? handleItemSelect(type, option) : handleItemSelect(type, option, index))
                                }
                                className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                            >
                                {ElementDisplay(
                                    type,
                                    option.label,
                                    option.value,
                                    option.color,
                                    filters,
                                    categories,
                                    users,
                                    customerGroups,
                                    channels,
                                    customers ?? [],
                                    companies ?? []
                                )}
                            </Button>
                        </CommandItem>
                    ))}
            </CommandList>
        </Command>
    );
}

export default FilterDropdownElement;
