import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import type { Category, GetUserResponse, Teams, Topic } from "@/interfaces/serverData";
import { getIconForType, getLabelForType, getOptions } from "@/utilities/methods";
import {
    DoubleArrowLeftIcon,
    OpenInNewWindowIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import { ElementDisplay } from "./FilterDropdownElement";
import { ticketStatusPriorityOrder } from "./constants";

interface IssueContextMenuProps {
    children: React.ReactNode;
    issueId: string;
    dropdownOptions: string[];
    onSave: (type: string, value: string) => void;
    categoryOptionsQuery: {
        isSuccess: boolean;
        data?: Category[];
    };
    topics: Topic[];
    users: GetUserResponse[];
    teams: Teams[];
    filters: Map<string, Set<string>>;
    categories: Category[];
    onOpenNewTab: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onReload: () => void;
}

const sortOptions = (
    type: string,
    a: { label: string; value: string },
    b: { label: string; value: string },
) => {
    if (type === "Status") {
        return (
            ticketStatusPriorityOrder.indexOf(a.value) -
            ticketStatusPriorityOrder.indexOf(b.value)
        );
    }

    const prioritizeLabel = "No Assignee";
    if (a.label === prioritizeLabel && b.label !== prioritizeLabel) {
        return -1;
    }
    if (b.label === prioritizeLabel && a.label !== prioritizeLabel) {
        return 1;
    }

    return a.label?.localeCompare(b.label);
};

export function IssueContextMenu({
    children,
    dropdownOptions,
    onSave,
    categoryOptionsQuery,
    topics,
    users,
    teams,
    filters,
    categories,
    onOpenNewTab,
    onReload,
}: IssueContextMenuProps) {
    return (
        <ContextMenu>
            <ContextMenuTrigger>{children}</ContextMenuTrigger>
            <ContextMenuContent className="w-60">
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                    onClick={onOpenNewTab}
                >
                    <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                    Open in New Tab
                </ContextMenuItem>
                <ContextMenuItem
                    inset
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                >
                    <DoubleArrowLeftIcon className="w-3.5 h-3.5" />
                    Back
                </ContextMenuItem>
                <ContextMenuItem
                    inset
                    onClick={onReload}
                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                >
                    <ReloadIcon className="w-3 h-3" />
                    Reload
                </ContextMenuItem>
                <ContextMenuSeparator />
                {categoryOptionsQuery.isSuccess &&
                    dropdownOptions.map((type) => {
                        const options =
                            getOptions(
                                type,
                                topics,
                                users,
                                [],
                                categoryOptionsQuery.data ?? [],
                                teams,
                                new Map(),
                                [],
                                [],
                                []
                            ) ?? [];
                        return (
                            <ContextMenuSub key={type}>
                                <ContextMenuSubTrigger
                                    inset
                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                >
                                    {getIconForType(type)}
                                    {getLabelForType(type)}
                                </ContextMenuSubTrigger>
                                <ContextMenuSubContent className="w-55 max-h-60 overflow-y-auto text-xs">
                                    {options
                                        .sort((a, b) => sortOptions(type, a, b))
                                        .map((option) => (
                                            <ContextMenuItem
                                                key={option.value}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    onSave(type, option.value);
                                                }}
                                                className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:bg-gray-100 hover:text-gray-950 w-full px-3 py-1.5"
                                            >
                                                {ElementDisplay(
                                                    type,
                                                    option.label,
                                                    option.value,
                                                    option.color,
                                                    filters,
                                                    categories,
                                                    users,
                                                    [],
                                                    new Map(),
                                                    [],
                                                    []
                                                )}
                                            </ContextMenuItem>
                                        ))}
                                </ContextMenuSubContent>
                            </ContextMenuSub>
                        );
                    })}
            </ContextMenuContent>
        </ContextMenu>
    );
}
