import EnterCommand from "@/component/textEditor/EnterCommand";
import {
    $convertFromMarkdownString,
    $convertToMarkdownString,
} from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { MATCHERS, TRANSFORMERS, validateUrl } from "@/utilities/methods";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { useEffect } from "react";
import Toolbar from "../../component/textEditor/Toolbar";
import type { EditorActionType } from "@/interfaces/serverData";
import ImagesPlugin from "@/component/textEditor/ImagesPlugin";

interface EditorProps {
    handleEditorChanges?: (markdwn: string) => void;
    className?: string;
    initialText?: string;
    enableToolbar?: boolean;
    source?: string;
}

export function Editor({
    handleEditorChanges = (markdwn: string) => { },
    className,
    initialText,
    enableToolbar = true,
    source = "Template",
}: EditorProps) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        if (initialText) {
            editor.update(() =>
                $convertFromMarkdownString(initialText, TRANSFORMERS),
            );
        }
    }, [initialText]); // Only run when initialText changes

    useEffect(() => {
        const updateEditorContent = () => {
            // Convert the current editor content to markdown string
            editor.update(() => {
                const markdownContent = $convertToMarkdownString(TRANSFORMERS);
                handleEditorChanges(markdownContent);
            });
        };

        // Subscribe to editor state updates
        const unregister = editor.registerUpdateListener(() => {
            updateEditorContent();
        });

        // Cleanup listener on unmount
        return () => {
            unregister();
        };
    }, [editor, handleEditorChanges]);

    // Dummy functions for the toolbar
    const handleImageUpload = (src: string, altText: string): void => { };
    const handleFileUpload = (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ): void => { };

    return (
        <div
            className={`relative flex flex-row justify-between w-full pb-2 gap-[50px] bg-white ${className || ""} min-h-[45px]`}
        >
            <div className="text-sm relative flex flex-col flex-1 gap-1 rounded-lg transition-all">
                {enableToolbar && (
                    <Toolbar
                        handleImageUpload={handleImageUpload}
                        handleFileUpload={handleFileUpload}
                        source={source}
                        inlineImages={!!(source === "Signature")}
                    />
                )}
                <div
                    className={`max-h-[350px] overflow-scroll scrollbar-white pb-2 ${!enableToolbar
                        ? "pointer-events-none cursor-default select-none caret-transparent"
                        : ""
                        }`}
                    tabIndex={enableToolbar ? 0 : -1} // Prevent focus when toolbar is not enabled
                >
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="w-full" />}
                        placeholder={<div />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                </div>
                <HistoryPlugin />
                <AutoFocusPlugin />
                <ClearEditorPlugin />
                <EnterCommand
                    onSubmit={(type: EditorActionType) => {
                        const mrkdwn = $convertToMarkdownString(TRANSFORMERS);
                        handleEditorChanges(mrkdwn);
                    }}
                />
                <ListPlugin />
                <ClickableLinkPlugin newTab={true} />
                <LinkPlugin validateUrl={validateUrl} />
                <AutoLinkPlugin matchers={MATCHERS} />
                <ImagesPlugin handleFileUpload={handleImageUpload} />
            </div>
        </div>
    );
}
