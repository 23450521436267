import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, URLS, pages } from "../../constant";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useApi } from "../../interfaces/api";
import NavBarComponent from "../../sharedPages/NavBar";
import { integrationsList } from "./constant";

import { Em, Text } from "@radix-ui/themes";
import { Progress } from "@/component/shadcn/ui/progress";

const BotPage = () => {
    const navigate = useNavigate();
    const api = useApi();
    const [integration, setIntegration] = useState<string>("");
    const [progress, setProgress] = useState<number>(0);

    const navigateToIntegrations = useCallback(async () => {
        navigate(`/admin/manage_integrations/${integration}`, {
            replace: true,
        });
    }, [navigate, integration]);

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    useEffect(() => {
        const integration = integrationsList.get(window.location.pathname);
        if (integration === undefined) {
            navigate("/*");
        }

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const guildParam = urlParams.get("guild_id");
        if (guildParam === undefined || guildParam === "") {
            navigate("/*");
        }

        const codeParam = urlParams.get("code");
        if (codeParam === undefined || codeParam === "") {
            navigate("/*");
        }

        setIntegration(integration?.integrationType ?? "");

        const requestData = {
            type: integration?.integrationType,
            info: {
                code: codeParam,
                scope: guildParam,
            },
        };

        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                setProgress(10);
                setStartIndexing(true);
                console.log("successfully authenticated and added bot");
            })
            .catch((res) => {
                setProgress(10);
                setStartIndexing(true);
                console.log("unable to authenticate and add bot");
            });
    }, [navigate]);

    const fetchProgress = useCallback(async () => {
        if (!startIndexing) return;

        try {
            setProgress((prev) => {
                const newProgress = prev + 20;
                return Math.min(newProgress, 95);
            });

            await new Promise((resolve) => setTimeout(resolve, 2000));

            if (progress >= 95) {
                setProgress(100);
                navigateToIntegrations();
            } else {
                fetchProgress();
            }
        } catch (error) {
            console.error("Error updating progress:", error);
            setProgress((prev) => Math.min(prev + 5, 95));
            setTimeout(fetchProgress, 2000);
        }
    }, [startIndexing, progress, navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.search} />

            <div
                className="flex flex-col justify-center items-center min-h-[80vh]"
            >
                <div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem]">
                    <div className="flex flex-col gap-4">
                        <Text
                            size={{
                                sm: "4",
                                initial: "5",
                                md: "5",
                                xl: "6",
                            }}
                            weight="bold"
                        >
                            <b>
                                <SlackSvg
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        paddingRight: "20px",
                                    }}
                                />
                                Installing the discord bot!
                            </b>
                        </Text>
                        <Text
                            size={{
                                sm: "3",
                                initial: "4",
                                md: "4",
                                xl: "4",
                            }}
                        >
                            <Em>Assembling...</Em>
                        </Text>

                        <Progress value={progress} className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BotPage;
