import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/component/shadcn/ui/alert-dialog";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent, CardTitle } from "@/component/shadcn/ui/card";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";

import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { integrationBackEndDataMappingToSvg } from "../Admin/Integrations/constant";
import SLAPopup from "./SLAPopup";

import { useToast } from "@/component/shadcn/ui/use-toast";
import type {
    Integration,
    SLACreatePayload,
    SLADeletePayload,
    SLAResponse,
    ScopeResponse,
    Teams,
} from "@/interfaces/serverData";
import {
    ArrowUpIcon,
    Cross2Icon,
    DotsHorizontalIcon,
    Pencil2Icon,
    QuestionMarkCircledIcon,
    ResetIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Badge, Separator } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { getBadgeForTeam } from "@/utilities/methods";

interface SLAProps {
    sla: SLAResponse;
    setSlas: React.Dispatch<React.SetStateAction<SLAResponse[]>>;
    clearSLADetails: () => Promise<void>;
    orgID: string;
}

const SLACard: React.FC<SLAProps> = ({
    sla,
    setSlas,
    clearSLADetails,
    orgID
}) => {
    const api = useApi();
    const { toast } = useToast();
    const Icon =
        integrationBackEndDataMappingToSvg.get(sla.source) ??
        QuestionMarkCircledIcon;

    const [selectedSource, setSelectedSource] = useState<string>(sla.source);
    const [options, setOptions] = useState<ScopeResponse[]>([]);
    const [icon, setIcon] = useState<React.ElementType | undefined>(Icon);
    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        sla.channels ?? [],
    );
    const [selectedTeams, setSelectedTeams] = useState<Teams[]>(
        sla.teams ?? [],
    );
    const [breachTargetInput, setBreachTargetInput] = useState<string>(
        sla.breach_target.toString(),
    );
    const [breachTargetTimeUnit, setBreachTargetTimeUnit] = useState(
        sla.breach_target_unit,
    );

    const [notificationTimeInput, setNotificationTimeInput] = useState<string>(
        sla.notification ? sla.notification.toString() : "0",
    );
    const [notificationTimeUnit, setNotificationTimeUnit] = useState(
        sla.notification_time_unit ? sla.notification_time_unit : "never",
    );

    const [showSelectedChannels, setShowSelectedChannels] =
        useState<boolean>(false);
    const [showSelectedTeams, setShowSelectedTeams] = useState<boolean>(false)

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const handleSLASourceChange = (newSLASource: string) => {
        setSelectedSource(newSLASource);
        setIcon(integrationBackEndDataMappingToSvg.get(newSLASource));
        switch (newSLASource) {
            case "Slack":
            case "Discord":
            case "CommunitySlack":
            case "GitHubTicket": {
                api.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${newSLASource}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        const dataItems: ScopeResponse[] =
                            res.data.data?.asm_ticket_channels;
                        setOptions(dataItems);
                    })
                    .catch((res) => { });
                break
            }
            case "Gmail": {
                api.get(`${URLS.serverUrl}${API.getUniqueIntegrations}/Google`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((res) => {
                        if (res.status === 200) {
                            const integrationsResponse: Integration[] =
                                res.data.data;
                            const dataItems: ScopeResponse[] = [];
                            for (const integration of integrationsResponse) {
                                const scope: ScopeResponse = {
                                    key: integration.id,
                                    name: integration.unique_name
                                };
                                dataItems.push(scope);
                            }
                            setOptions(dataItems)
                        }
                    })
                    .catch((res) => {
                        console.error("Error fetching scope data:", res);
                    });
            }
        }
    };

    useEffect(() => {
        setIcon(integrationBackEndDataMappingToSvg.get(sla.source));
    }, [sla]);

    const saveSLA = async () => {
        const requestData: SLACreatePayload = {
            id: sla.id,
            operation: "EDIT",
            enabled: true,
            source: selectedSource,
            channels: selectedChannels,
            teams: selectedTeams,
            breach_target: Number(breachTargetInput),
            breach_target_unit: breachTargetTimeUnit,
            notification: Number(notificationTimeInput),
            notification_time_unit: notificationTimeUnit,
        };
        api.post(`${URLS.serverUrl}${API.saveSLA}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    })
                } else {
                    toast({
                        title: "Updated Settings!",
                        description: "Your settings have been updated successfully.",
                    })
                    setSlas(res.data.data);
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                })
            })
    };

    const deleteSLA = async () => {
        const requestData: SLADeletePayload = {
            operation: "DELETE",
            id: sla.id,
        };
        api.post(`${URLS.serverUrl}${API.saveSLA}`, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    })
                } else {
                    toast({
                        title: "Updated Settings!",
                        description: "Your settings have been updated successfully.",
                    })
                    setSlas(res.data.data);
                }
            })
            .catch((res) => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                })
            })
    };

    return (
        <Card className="shadow-none p-3">
            <CardTitle className="pb-5 pl-1">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-row gap-2 items-center">
                        <Icon className="w-4 h-4" />
                        <p className="text-sm">{sla.source}</p>
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                variant="ghost"
                                className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                            >
                                <DotsHorizontalIcon className="h-4 w-4" />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                            align="end"
                            className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                            onClick={(event) => event.stopPropagation()}
                        >
                            <DropdownMenuItem asChild>
                                <AlertDialog
                                    open={deleteDialogOpen}
                                    onOpenChange={setDeleteDialogOpen}
                                >
                                    <AlertDialogTrigger asChild>
                                        <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                            <p className="flex-grow">Delete</p>
                                            <TrashIcon className="justify-self-end" />
                                        </div>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent>
                                        <div>
                                            <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                <AlertDialogTitle>
                                                    Are you absolutely sure?
                                                </AlertDialogTitle>
                                                <AlertDialogDescription>
                                                    This action cannot be
                                                    undone. This will
                                                    permanently delete this
                                                    SLA.
                                                </AlertDialogDescription>
                                            </AlertDialogHeader>
                                        </div>
                                        <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                            <AlertDialogCancel>
                                                Cancel
                                            </AlertDialogCancel>
                                            <AlertDialogAction
                                                onClick={deleteSLA}
                                            >
                                                Delete
                                            </AlertDialogAction>
                                            <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                <Cross2Icon />
                                            </AlertDialogCancel>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild>
                                <SLAPopup
                                    triggerElement={
                                        <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                            <p className="flex-grow">Edit</p>
                                            <Pencil2Icon className="justify-self-end" />
                                        </div>
                                    }
                                    selectedSource={selectedSource}
                                    setSelectedSource={setSelectedSource}
                                    selectedTeams={selectedTeams}
                                    setSelectedTeams={setSelectedTeams}
                                    options={options}
                                    setOptions={setOptions}
                                    selectedChannels={selectedChannels}
                                    setSelectedChannels={setSelectedChannels}
                                    breachTargetInput={breachTargetInput}
                                    setBreachTargetInput={setBreachTargetInput}
                                    breachTargetTimeUnit={breachTargetTimeUnit}
                                    setBreachTargetTimeUnit={
                                        setBreachTargetTimeUnit
                                    }
                                    notificationTimeInput={
                                        notificationTimeInput
                                    }
                                    setNotificationTimeInput={
                                        setNotificationTimeInput
                                    }
                                    notificationTimeUnit={notificationTimeUnit}
                                    setNotificationTimeUnit={
                                        setNotificationTimeUnit
                                    }
                                    showSelectedChannels={showSelectedChannels}
                                    setShowSelectedChannels={
                                        setShowSelectedChannels
                                    }
                                    showSelectedTeams={showSelectedTeams}
                                    setShowSelectedTeams={setShowSelectedTeams}
                                    saveSLA={saveSLA}
                                    icon={icon}
                                    handleSLASourceChange={
                                        handleSLASourceChange
                                    }
                                    subChannelsView={
                                        selectedChannels.length !== 0
                                    }
                                    editing={true}
                                    clearSLADetails={clearSLADetails}
                                    orgID={orgID}
                                />
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
            </CardTitle>
            <CardContent className="pl-3 pr-3">
                {sla.channels && sla.channels?.length !== 0 && (
                    <Card className="shadow-none p-3 mb-3">
                        <div className="flex flex-col gap-1">
                            <p className="text-xs font-medium">
                                Customer Channels Selected:{" "}
                            </p>
                            <p className="text-xs font-small">
                                Subchannels that this SLA applies to.
                            </p>
                            <Separator className="my-3" size="4" />
                            <div className="flex flex-row flex-wrap gap-3 px-4">
                                {sla.channels.map((item) => (
                                    <Badge key={item.name}>{item.name}</Badge>
                                ))}{" "}
                            </div>
                        </div>
                    </Card>
                )}
                {sla.teams && sla.teams?.length !== 0 && (
                    <Card className="shadow-none p-3 mb-3">
                        <div className="flex flex-col gap-1">
                            <p className="text-xs font-medium">
                                Teams Selected:{" "}
                            </p>
                            <p className="text-xs font-small">
                                Specific teams that this SLA applies to.
                            </p>
                            <Separator className="my-3" size="4" />
                            <div className="flex flex-row flex-wrap gap-3 px-4">
                                {sla.teams.map((team) => (
                                    getBadgeForTeam(team.team_name)))}
                            </div>
                        </div>
                    </Card>
                )}
                <div className="flex flex-row gap-3">
                    <div className="flex flex-col items-center">
                        <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                            <ResetIcon className="text-iris9" />
                        </div>
                        <Separator
                            orientation="vertical"
                            className="bg-[#D8D8D8] w-[1px] h-12"
                        />
                    </div>
                    <div className="flex flex-col items-center pt-1 gap-2">
                        <p className="text-xs font-medium">Breach Limit</p>
                        <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 py-1 shadow-sm bg-white h-7 pl-2">
                            <p className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer">
                                {sla.breach_target}
                            </p>

                            <Separator
                                orientation="vertical"
                                className="bg-[#D8D8D8] w-[1px] h-6 mx-3"
                            />

                            <p className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer">
                                {sla.breach_target_unit}
                            </p>
                        </div>
                    </div>
                </div>
                {sla.notification_enabled && (
                    <div className="flex flex-row gap-3">
                        <div className="flex flex-col items-center">
                            <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                <ArrowUpIcon className="text-red9" />
                            </div>
                            <Separator
                                orientation="vertical"
                                className="bg-[#D8D8D8] w-[1px] h-12"
                            />
                        </div>
                        <div className="flex flex-col items-center pt-1 gap-2">
                            <p className="text-xs font-medium">Notification</p>
                            <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 py-1 shadow-sm bg-white h-7 pl-2">
                                <p className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer">
                                    {sla.notification}
                                </p>

                                <Separator
                                    orientation="vertical"
                                    className="bg-[#D8D8D8] w-[1px] h-6 mx-3"
                                />

                                <p className="text-xs text-gray-700 bg-white border-none focus:outline-none cursor-pointer">
                                    {sla.notification_time_unit}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default SLACard;
