import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Account, Template, UserResponse } from "@/interfaces/serverData";
import {
    TRANSFORMERS,
    convertMarkdownToPlainText,
    substituteVariablesInMarkdown,
} from "@/utilities/methods";
import type { ListNode } from "@lexical/list";
import {
    $convertFromMarkdownString,
    $convertToMarkdownString,
} from "@lexical/markdown";
import { CheckIcon } from "@radix-ui/react-icons";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import {
    $createParagraphNode,
    $getRoot,
    type ParagraphNode,
    type RootNode,
} from "lexical";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ShinyButton from "../shadcn/magicui/shiny-button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "../shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../shadcn/ui/tooltip";

// TODO: store once template is used in a specific scenario so pass in the type as an input?
interface TemplatesPluginProps {
    customerUserInfo?: UserResponse;
    customerAccount?: Account;
    companyAccount?: Account;
    aiResponse?: string;
    className?: string;
    teamID?: string;
}

export function TemplatesPlugin({
    customerUserInfo,
    customerAccount,
    companyAccount,
    aiResponse,
    className,
}: TemplatesPluginProps) {
    const { team_id } = useParams(); // team ID
    const [isToggled, setIsToggled] = useState<Map<string, boolean>>(new Map());
    const [editor] = useLexicalComposerContext();
    const api = useApi();

    const fetchTemplates = async (): Promise<Template[]> => {
        const response: AxiosResponse<{ data: Template[] }> = await api.get(
            `${URLS.serverUrl}${API.getTemplates}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch data");
    };
    const fetchTeamTemplates = async (): Promise<Template[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTemplates}/team/${team_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };
    const {
        data = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Template[]>({
        queryKey: team_id ? [`teamTemplates_${team_id}`] : ["data"],
        queryFn: team_id ? () => fetchTeamTemplates() : () => fetchTemplates(),
    });

    const updateEditor = (template: Template) => {
        const newToggled = new Map(isToggled);
        editor.update(() => {
            const content_used = substituteVariablesInMarkdown(
                template.template ?? "",
                customerUserInfo,
                customerAccount,
                companyAccount,
                aiResponse,
            );
            const root = $getRoot();
            const convertedContent = convertMarkdownToPlainText(content_used); // Convert the markdown content to plain text
            const paragraphTextMap = [];
            let fullText = "";

            // Gather all paragraphs and their text content
            for (const paragraph of root.getChildren()) {
                const textNode = paragraph.getTextContent();
                paragraphTextMap.push({ paragraph, textNode });
                fullText += `${textNode}\n`; // Append text and preserve the newlines
            }

            const normalizeText = (text: string) => {
                return text.replace(/^\s*[-*]\s+/gm, ""); // Remove bullet point markers (start of lines with `-` or `*`)
            };
            const normalizedConvertedContent = normalizeText(convertedContent);

            if (isToggled.has(template.id) && isToggled.get(template.id)) {
                // biome-ignore lint/complexity/noForEach: <explanation>
                root.getChildren().forEach((paragraph) => {
                    // Get all text nodes within this paragraph
                    const textNodes = (
                        paragraph as unknown as ListNode | ParagraphNode
                    ).getChildren();
                    // biome-ignore lint/complexity/noForEach: <explanation>
                    textNodes.forEach(
                        (textNode: {
                            getTextContent: () => string;
                            remove: () => void;
                        }) => {
                            const textNodeContent = textNode.getTextContent();
                            const normalizedTextNodeContent =
                                normalizeText(textNodeContent);
                            // If the text node content matches the template, remove it
                            if (
                                normalizedTextNodeContent !== "\n" &&
                                normalizedConvertedContent.includes(
                                    normalizedTextNodeContent,
                                )
                            ) {
                                textNode.remove();
                            }
                        },
                    );

                    // After removing the part of the text, if the paragraph is empty, remove the entire paragraph
                    if (
                        (
                            paragraph as unknown as ListNode | ParagraphNode
                        ).getChildren().length === 0
                    ) {
                        paragraph.remove(false); // Remove the empty paragraph
                    }
                    newToggled.set(template.id, false);
                });
            } else {
                newToggled.set(template.id, true);

                // Convert the full Markdown content + the new template content into Lexical nodes
                const fullMarkdownContent =
                    $convertToMarkdownString(TRANSFORMERS); // Convert existing content to Markdown
                const combinedMarkdownContent = `${fullMarkdownContent}\n${content_used}`;

                // Remove the old content (if applicable) and insert the new Markdown content as Lexical nodes
                const paragraph = $createParagraphNode();
                $convertFromMarkdownString(
                    combinedMarkdownContent,
                    TRANSFORMERS,
                );
                root.append(paragraph);
                root.selectEnd();
            }
            setIsToggled(newToggled);
        });
    };

    // If the editor is empty, untoggle all the template options
    const checkIfEditorIsEmpty = (root: RootNode) => {
        const rootChildren = root.getChildren();
        return rootChildren.every((child) => !child.getTextContent().trim());
    };
    useEffect(() => {
        const unregisterListener = editor.registerUpdateListener(() => {
            editor.update(() => {
                const root = $getRoot();
                const isEmpty = checkIfEditorIsEmpty(root);
                if (isEmpty) {
                    const newToggledMap = new Map(isToggled);
                    newToggledMap.forEach((_, key) => {
                        newToggledMap.set(key, false);
                    });
                    setIsToggled(newToggledMap);
                }
            });
        });

        return () => unregisterListener();
    }, [editor, isToggled]);

    return (
        <div>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <div
                            className={`${className} absolute top-0 mt-2 mr-1`}
                        >
                            <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <div>
                                        <ShinyButton
                                            text={"{ }"}
                                            className="text-[#5e6ad2] pr-2 pl-2 text-xs outline outline-1 outline-iris8 flex py-1 flex-wrap justify-start"
                                        />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="end"
                                    className="overflow-hidden p-0"
                                >
                                    <Command className="rounded-md shadow-md text-xs pb-1">
                                        <CommandInput
                                            placeholder="Filter templates..."
                                            className="px-1 text-[13px]"
                                        />
                                        <CommandList className="flex-1 max-h-[140px] overflow-y-auto">
                                            <CommandEmpty className="text-xs px-4 py-2">
                                                No results found.
                                            </CommandEmpty>
                                            {data.map((template) => (
                                                <CommandItem
                                                    key={template.id}
                                                    onSelect={() =>
                                                        updateEditor(template)
                                                    }
                                                    className="text-[13px] flex items-center gap-2 px-4"
                                                >
                                                    {template.name}
                                                    {isToggled.has(
                                                        template.id,
                                                    ) &&
                                                        isToggled.get(
                                                            template.id,
                                                        ) && (
                                                            <CheckIcon className="h-4 w-4" />
                                                        )}
                                                </CommandItem>
                                            ))}
                                        </CommandList>
                                    </Command>
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    </TooltipTrigger>
                    <TooltipContent className="bg-[#5B5BD6]">
                        <p>Templates</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    );
}
