import { Input } from "@/component/shadcn/ui/input";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import {
	Button,
	Callout,
	Em,
	Flex,
	Heading,
	Switch,
	Text,
} from "@radix-ui/themes";
import { LoaderCircleIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import {
	type ChangeEvent,
	useCallback,
	useEffect,
	useLayoutEffect,
	useMemo,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectSearchableDropdown from "../../component/MultiSelectDropdown";
import { API, URLS, pages } from "../../constant";
import { loadingTypes } from "../../constant";
import { ErrorChip } from "../../design/Chip";
import { useApi } from "../../interfaces/api";
import type { ScopeResponse } from "../../interfaces/serverData";
import type { BotMetadata } from "../../interfaces/serverData";
import NavBarComponent from "../../sharedPages/NavBar";
import type { integrationInfo } from "./constant";
import { integrationsUpdateScopes } from "./constant";

import { Progress } from "@/component/shadcn/ui/progress";
const UpdateScopesPage = () => {
	const api = useApi();

	const [channels, setChannels] = useState<ScopeResponse[]>([]);
	const [alreadySelectedChannels, setAlreadySelectedChannels] = useState<
		string[]
	>([]);

	const [loading, setLoading] = useState(loadingTypes.loading);

	const navigate = useNavigate();

	const { toast } = useToast();

	const dropdownStyle: React.CSSProperties = {
		position: "relative", // Establishes a new positioning context
		paddingRight: 15,
		maxHeight: "200px", // Adjust this value based on your item height to fit 5 items
		overflowY: "auto",
	};

	const posthog = usePostHog();

	const [startIndexing, setStartIndexing] = useState<boolean>(false);

	const [jobList, setJobList] = useState<string[]>([]);

	const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);

	const [isSubmit, setIsSubmit] = useState<boolean>(false);

	const [channelsSelected, setChannelsSelected] = useState<string[]>([
		"Select",
	]);

	const [integrationType, setIntegrationType] = useState("");

	const [createHistoricalTickets, setCreateHistoricalTickets] =
		useState<boolean>(false);
	const [daysAgoInput, setDaysAgoInput] = useState(90);
	const handleDaysAgoInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		setDaysAgoInput(value === "" ? 0 : Number(value));
	};

	const initialIntegrationInfo: integrationInfo = useMemo(
		() => ({
			title: "",
			description: "",
			buttonInfo: "",
		}),
		[],
	);

	const [info, setInfo] = useState<integrationInfo>(initialIntegrationInfo);

	const [source, setSource] = useState("");

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		const tempInfo: integrationInfo = {
			title: "",
			description: "",
			buttonInfo: "",
		};

		switch (integrationType) {
			case "Slack":
				tempInfo.title = "Add Slack Channels";
				tempInfo.description =
					"Choose channels from below that you'd like us to listen to (example - #general, #random, #support). We'll automatically create an Assembly ticket from any provided messages in these channels.";
				tempInfo.buttonInfo = "Select Channels";
				break;
			case "CommunitySlack":
				tempInfo.title = "Add Community Slack Channels";
				tempInfo.description =
					"Choose channels from below that you'd like us to listen to (example - #general, #random, #support). We'll automatically create an Assembly ticket from any provided messages in these channels.";
				tempInfo.buttonInfo = "Select Channels";
				break;
			case "Discord":
				tempInfo.title = "Add Discord Channels";
				tempInfo.description =
					"Choose channels from below that you'd like us to listen to (example - #questions, #help, #bug). We'll automatically create an Assembly ticket from any provided messages in these channels.";
				tempInfo.buttonInfo = "Select Channels";
				break;
			case "GitHubTicket":
				tempInfo.title = "Add GitHub Issue Repositories";
				tempInfo.description =
					"Choose repositories from below that you'd like us to listen to the Github Issues (example - #repo). We'll automatically create an Assembly ticket from any issues and comments in these repositories.";
				tempInfo.buttonInfo = "Select Repositories";
				break;
			case "GithubDiscussion":
				tempInfo.title = "Add GitHub Discussions Repositories";
				tempInfo.description =
					"Choose repositories from below that you'd like us to listen to the Github Discussion (example - #repo). We'll automatically create an Assembly ticket from any issues and comments in these repositories.";
				tempInfo.buttonInfo = "Select Repositories";
				break;
		}
		setInfo(tempInfo);
	}, [integrationType, initialIntegrationInfo]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useLayoutEffect(() => {
		const integration = integrationsUpdateScopes.get(window.location.pathname);
		if (integration === undefined) {
			navigate("/*");
		}
		const i = integration ? integration : "";
		if (i === "") {
			navigate("/*");
		}

		setIntegrationType((prev) => i);
		if (i === "Slack") {
			setSource("Slack");
		} else {
			setSource("");
			const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
			const requestData = {
				type: i,
			};
			api
				.post(serverUrl + API.getScopes, requestData, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					const channelData = res.data.data;
					setChannels(channelData);
				})
				.catch((res) => {
					setLoading(loadingTypes.error);
					console.log(res);
				});
		}

		api
			.get(`${URLS.serverUrl}${API.getBotSettingsV2}/${integration}`, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status === 200) {
					const settingsData: BotMetadata = res.data.data;
					const dataTemp: string[] = [];
					if (settingsData.asm_ticket_channels) {
						for (const repo of settingsData.asm_ticket_channels) {
							dataTemp.push(repo.name);
						}
					}
					setAlreadySelectedChannels(dataTemp);
					setLoading(loadingTypes.loaded);
				} else {
					setLoading(loadingTypes.error);
				}
			})
			.catch(() => {
				console.log("failed to get bot settings");
				setLoading(loadingTypes.error);
			});
	}, [navigate]); //only run once

	const [cursor, setCursor] = useState<string | null>(null);
	const [hasMore, setHasMore] = useState<boolean>(true);

	const [showLoadingBar, setShowLoadingBar] = useState<boolean>(false);

	const fetchSlackChannels = useCallback(() => {
		const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
		const requestData = {
			type: "Slack",
			cursor: cursor,
		};
		api
			.post(serverUrl + API.getScopesPaginated, requestData, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				const channelData = res.data.data.scopes;
				const newCursor = res.data.data.cursor;
				setChannels((prevChannels) => [...prevChannels, ...channelData]);
				setCursor(newCursor);
				setHasMore(!!newCursor);
				setLoading(loadingTypes.loaded);
			})
			.catch((res) => {
				setLoading(loadingTypes.error);
				console.log(res);
			});
	}, [api, cursor]);

	useEffect(() => {
		if (hasMore) {
			if (source === "Slack") {
				setShowLoadingBar(true);
				fetchSlackChannels();
			} else if (source !== "") {
				setShowLoadingBar(false);
			}
		} else {
			setShowLoadingBar(false);
		}
	}, [fetchSlackChannels, hasMore, source]);

	function handleChannelSelect(channels: string[]) {
		setChannelsSelected(channels);
		if (channels?.length === 0) {
			setIsDisabledButton(true);
		} else {
			setIsDisabledButton(false);
		}
	}

	const [open, setOpen] = useState<boolean>(false);

	const navigateToIntegrations = useCallback(
		async (intType?: string) => {
			switch (intType) {
				case "Slack":
					navigate("/admin/manage_integrations/slack", {
						replace: true,
					});
					break;
				case "CommunitySlack":
					navigate("/admin/manage_integrations/communityslack", {
						replace: true,
					});
					break;
				case "Discord":
					navigate("/admin/manage_integrations/discord", {
						replace: true,
					});
					break;
				case "GitHubTicket":
					navigate("/admin/manage_integrations/githubticket", {
						replace: true,
					});
					break;
				case "GithubDiscussion":
					navigate("/admin/manage_integrations/githubticket", {
						// shares same page as GitHubTicket
						replace: true,
					});
					break;
				default:
					navigate("/admin/manage_integrations", { replace: true });
			}
		},
		[navigate],
	);

	function handleSubmit(intType?: string) {
		const myIntegrationType = intType ? intType : integrationType;
		const channelRequest: ScopeResponse[] = []; // contains channel ids for back-end
		// biome-ignore lint/complexity/noForEach: <explanation>
		channels.forEach((channel) => {
			if (channelsSelected.includes(channel.name)) {
				channelRequest.push(channel);
			}
		});

		if (createHistoricalTickets && intType === "Slack") {
			const now = new Date();
			const latest: string = Math.floor(now.getTime() / 1000).toString();
			const pastDate = new Date(now);
			pastDate.setDate(now.getDate() - daysAgoInput);
			const oldest: string = Math.floor(pastDate.getTime() / 1000).toString();
			const requestData = {
				type: "Slack",
				scopes: channelRequest,
				oldest: oldest,
				latest: latest,
			};
			console.log("request data is ", requestData);
			api
				.post(URLS.serverUrl + API.createHistoricalTickets, requestData, {
					headers: {
						"Content-Type": "application/json",
					},
				})
				.then((res) => {
					if (res.status === 200) {
						console.log("submitted create historical tickets worker");
					}
				})
				.catch((res) => {
					console.log("failed to submit create historical tickets worker");
				});
		}

		const requestData: BotMetadata = {
			bot_type: myIntegrationType,
			asm_ticket_channels: channelRequest,
		};

		api
			.post(URLS.serverUrl + API.saveBotSettings, requestData, {
				headers: {
					"Content-Type": "application/json",
				},
			})
			.then((res) => {
				if (res.status !== 200) {
					toast({
						title: "Oops! Something's wrong.",
						description: "Please try again at a later time.",
						variant: "destructive",
					});
				} else {
					toast({
						title: "Updated Settings!",
						description: "The settings have been updated successfully.",
					});
				}
				if (intType !== "") {
					navigate(`/admin/manage_integrations/${intType}`, {
						replace: true,
					});
				} else {
					navigate("/admin/manage_integrations", { replace: true });
				}
			})
			.catch((res) => {
				toast({
					title: "Oops! Something's wrong.",
					description: "Please try again at a later time.",
					variant: "destructive",
				});
				if (intType !== "") {
					navigate(`/admin/manage_integrations/${intType}`, {
						replace: true,
					});
				} else {
					navigate("/admin/manage_integrations", { replace: true });
				}
			});
	}

	const fetchProgress = useCallback(async () => {
		navigateToIntegrations(integrationType);
	}, [navigateToIntegrations, integrationType]);

	useEffect(() => {
		if (startIndexing) {
			fetchProgress();
		}
	}, [startIndexing, fetchProgress]);

	return (
		<div>
			<NavBarComponent state={pages.index} />

			{isSubmit ? (
				<div className="flex justify-center items-center h-screen flex-col gap-2 flex-wrap w-full h-[80vh]">
					<div className="bg-white border border-[#cccccc] rounded-lg p-5 max-w-[60rem] flex flex-col items-center justify-center">
						<div className="bg-white border border-[#cccccc] rounded-lg p-5 max-w-[60rem] flex flex-col items-center justify-center">
							<Flex direction="column" gap="2">
								<Text
									size={{
										sm: "4",
										initial: "5",
										md: "5",
										xl: "6",
									}}
									weight="bold"
								>
									<b>Please don't close this tab or refresh the page!</b>
								</Text>
								<Text
									size={{
										sm: "3",
										initial: "4",
										md: "4",
										xl: "4",
									}}
								>
									<Em>Assembling...</Em>
								</Text>
								<Text
									size={{
										initial: "3",
										sm: "2",
										md: "3",
										xl: "4",
									}}
									color="gray"
									highContrast
								>
									This will take a few minutes, about as long as it would take
									you to grab a cup of coffee!
								</Text>
								<Progress />
							</Flex>
						</div>
					</div>
				</div>
			) : (
				<div className="flex justify-center items-center h-screen flex-col gap-2 flex-wrap w-full h-[80vh]">
					<div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem] flex flex-col gap-2 items-center text-center">
						<Heading weight="regular" style={{ paddingBottom: 1 }}>
							<b>{info.title}</b>
						</Heading>
						<Text color="gray" style={{ paddingBottom: "40px" }}>
							{info.description}
						</Text>

						{loading === loadingTypes.loading && (
							<Button
								size="3"
								style={{ paddingLeft: "150px" }}
								loading
								variant="soft"
							/>
						)}
						{loading === loadingTypes.loaded && (
							<div className="flex flex-col items-center gap-6 w-full">
								{showLoadingBar && (
									<Callout.Root size="1" className="mb-0">
										<Callout.Icon>
											<InfoCircledIcon />
										</Callout.Icon>
										<Callout.Text>
											<div className="flex flex-row gap-2 items-center">
												Note: All Slack channels are still loading, but you can
												still select some in the meantime.{" "}
												<LoaderCircleIcon className="h-4 w-4 animate-spin" />{" "}
											</div>
										</Callout.Text>
									</Callout.Root>
								)}
								<MultiSelectSearchableDropdown
									dropdownContainerClassName="w-[520px]"
									items={channels.map((channel) => ({
										label: channel.name,
										value: channel.name,
										disabled: alreadySelectedChannels.includes(channel.name),
									}))}
									onItemClick={handleChannelSelect}
								/>
								{integrationType === "Slack" && (
									<div className="flex flex-col items-center gap-1">
										<div className="flex items-center gap-2">
											<div className="flex flex-col items-center gap-2 font-semibold">
												Index Past Data for Selected Channels
											</div>

											<Switch
												checked={createHistoricalTickets}
												onCheckedChange={(checked) => {
													setCreateHistoricalTickets(checked);
												}}
											/>
										</div>
										{createHistoricalTickets && (
											<div className="flex items-center gap-2 text-sm text-gray">
												Create closed issues and intake data into Product
												Insights for messages from the past
												<Input
													type="number"
													placeholder="90"
													style={{
														width: "65px",
													}}
													value={daysAgoInput}
													onChange={handleDaysAgoInputChange}
												/>
												days.
											</div>
										)}
									</div>
								)}
							</div>
						)}
						{loading === loadingTypes.error && (
							<ErrorChip
								label={
									"Oops! Looks like something's wrong. Try again, or notify us!"
								}
							/>
						)}
					</div>

					<div className="pt-8">
						<div className="relative w-fit">
							<Button
								size="3"
								onClick={() => handleSubmit(integrationType)}
								disabled={isDisabledButton}
							>
								{info.buttonInfo}
							</Button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default UpdateScopesPage;
