import { ExampleTheme } from "@/component/textEditor/Theme";
import { EmojiNode } from "@/component/textEditor/nodes/EmojiNode";
import { FileNode } from "@/component/textEditor/nodes/FileNode";
import { ImageNode } from "@/component/textEditor/nodes/ImageNode";
import { VariableNode } from "@/component/textEditor/nodes/TextVariableNode";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import {
    BookmarkIcon,
    ComponentBooleanIcon,
    PersonIcon,
} from "@radix-ui/react-icons";
import { LineBreakNode, ParagraphNode } from "lexical";

export const supportedThenConstants = {
    AssignIssueTo: "Assign Issue To",
    AssignTag: "Assign Tag",
    AssignTopic: "Assign Topic",
};

export const getSupportedThenIcon = (action: string): JSX.Element => {
    switch (action) {
        case supportedThenConstants.AssignIssueTo:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-mint3 border border-mint4 shadow-sm">
                    <PersonIcon className="text-mint11" />
                </div>
            );
        case supportedThenConstants.AssignTag:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                    <BookmarkIcon className="text-red11" />
                </div>
            );
        case supportedThenConstants.AssignTopic:
            return (
                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                    <ComponentBooleanIcon className="text-blue11" />
                </div>
            );
        default:
            return <></>;
    }
};

export const templatesInitialConfig = {
    namespace: "MyEditor",
    theme: ExampleTheme,
    onError: (error: unknown) => {
        console.error(error);
        throw error;
    },
    nodes: [
        HeadingNode,
        QuoteNode,
        ListNode,
        ListItemNode,
        EmojiNode,
        LineBreakNode,
        ParagraphNode,
        ImageNode,
        FileNode,
        VariableNode,
        LinkNode,
        AutoLinkNode,
    ],
};

export const notesInitialConfig = {
    namespace: "MyEditor",
    theme: ExampleTheme,
    onError: (error: unknown) => {
        console.error(error);
        throw error;
    },
    nodes: [
        HeadingNode,
        QuoteNode,
        ListNode,
        ListItemNode,
        EmojiNode,
        LineBreakNode,
        ParagraphNode,
        ImageNode,
        FileNode,
        LinkNode,
        AutoLinkNode,
    ],
};

// Used in workflows. Add additional types once supported
export const LABEL_TYPES = ["Topic", "Tag"]