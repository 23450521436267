import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/component/shadcn/ui/card";
import type { InsightListType } from "@/Insights/useInsightsData";
import type { Teams } from "@/interfaces/serverData";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from "recharts";

export interface SLABreachCount {
    ticket_number: number;
    date: string;
    url: string;
    assignee: string;
    topics: string;
    source: string;
    bot_category: string;
}
export interface SLABreachCountProps {
    listType: InsightListType;
    teamsQueryData?: Teams[];
    teamID: string;
    setTeamID: (teamID: string) => void;
    breachCountData: SLABreachCountData[];
}

export interface SLABreachCountData {
    date: string;
    count: number;
}

export default function SLABreachCountComponent({
    breachCountData,
}: SLABreachCountProps) {
    return (
        <Card>
            <CardHeader>
                <CardTitle>SLA Breach Count</CardTitle>
                <CardDescription>
                    Number of times SLA was breached per ticket
                </CardDescription>
            </CardHeader>
            <CardContent>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={breachCountData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            </CardContent>
        </Card>
    );
}
