import { findStatus } from "@/Insights/constants";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Insight,
    InsightData,
    InsightsWithPaginationResponse,
    Ticket,
} from "@/interfaces/serverData";
import {
    CaretDownIcon,
    CaretUpIcon,
    CheckIcon,
    OpenInNewWindowIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import {
    type QueryObserverResult,
    type RefetchOptions,
    useInfiniteQuery,
} from "@tanstack/react-query";
import { memo, useEffect, useState } from "react";

interface InsightsSectionProps {
    issue: Ticket;
    refetchTicketData?: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    userID: string;
    showLinkInsight?: boolean;
}

function InsightsSection({
    issue,
    refetchTicketData,
    userID,
    showLinkInsight = true,
}: InsightsSectionProps) {
    const api = useApi();
    const statusOrder: { [key: string]: number } = {
        New: 1,
        Open: 2,
        Closed: 3,
    };
    const [isOpenMap, setIsOpenMap] = useState<Map<string, boolean>>();
    const [showAllInsights, setShowAllInsights] = useState<boolean>(false);
    const [insightsToShow, setInsightsToShow] = useState<Insight[]>(
        issue.insights,
    );
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { toast } = useToast();

    const fetchInsights = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<InsightsWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.getInsightsWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };
    const { data } = useInfiniteQuery({
        queryKey: ["insights"],
        queryFn: fetchInsights,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 300000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    useEffect(() => {
        const sortedInsights = issue.insights?.sort((a, b) => {
            return statusOrder[a.status] - statusOrder[b.status];
        });
        if (sortedInsights && sortedInsights.length !== 0) {
            const newMap = new Map<string, boolean>();
            for (const insight of sortedInsights) {
                newMap.set(insight.id, isOpenMap?.get(insight.id) ?? true);
            }
            setIsOpenMap(newMap);
            if (showAllInsights) {
                setInsightsToShow(sortedInsights);
            } else {
                setInsightsToShow([sortedInsights[0]]);
            }
        } else {
            setInsightsToShow([]);
            setIsOpenMap(new Map());
        }
    }, [issue.insights]);

    const toggleIsOpen = (id: string) => {
        setIsOpenMap((prevMap) => {
            const updatedMap = new Map(prevMap);
            const currentValue = updatedMap.get(id);
            updatedMap.set(id, !currentValue);
            return updatedMap;
        });
    };

    const handleClick =
        (id: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
            const url = `/insight/${id}`;
            window.open(url, "_blank");
        };

    function showInsights(showAll: boolean) {
        setShowAllInsights(showAll);
        const sortedInsights = issue.insights?.sort((a, b) => {
            return statusOrder[a.status] - statusOrder[b.status];
        });
        if (showAll) {
            // Pull in all insights sorted
            setInsightsToShow(sortedInsights);
        } else {
            // Pull in the first insight
            setInsightsToShow([sortedInsights[0]]);
        }
    }

    const handleItemSelect = (insight: Insight) => () => {
        let newRelatedIssues: string[];
        let requestData: InsightData = {}
        if (
            issue?.insights?.map((insight) => insight.id).includes(insight.id)
        ) {
            // Already an include insight, so de-selecting
            newRelatedIssues = insight.related_issues
                ?.map((ri) => ri.id)
                .filter((id) => id !== issue.id);
            requestData = {
                existing_insight_id: insight.id,
                related_issues_removed: [issue.id],
                user_id: userID,
            }
        } else {
            // Is a new insight, so selecting it
            newRelatedIssues = insight.related_issues?.map((ri) => ri.id);
            if (newRelatedIssues) {
                newRelatedIssues.push(issue.id);
            } else {
                newRelatedIssues = [issue.id];
            }
            requestData = {
                existing_insight_id: insight.id,
                related_issues_added: [issue.id],
                user_id: userID,
            }
        }
        api.post(URLS.serverUrl + API.saveInsight, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log("Updated linked insight successfully");
                    setIsDropdownOpen(false);
                    toast({
                        title: "Updated Insights",
                        description: "Updated insights successfully",
                        variant: "default",
                    });
                    refetchTicketData?.();
                } else {
                    console.log("Call to update linked insight failed");
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                }
            })
            .catch(() => {
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
            })
    };

    return (
        <div>
            {insightsToShow?.map((insight) => {
                const selectedStatus = findStatus(insight.status);
                const isOpen = isOpenMap?.get(insight.id) ?? false;
                return (
                    <Card className={`p-1 my-2 relative rounded ${isOpen && "pt-2"}`} key={insight.id}>
                        <div className="prose w-full text-xs flex flex-col px-2">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-1">
                                    <span className="font-semibold">
                                        {insight.title}
                                    </span>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="hover:bg-muted px-1 py-2"
                                        onClick={handleClick(insight.id)}
                                    >
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <OpenInNewWindowIcon />
                                                </TooltipTrigger>
                                                <TooltipContent className="bg-[#5B5BD6]">
                                                    <p>Open Insight</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </Button>
                                </div>
                                {isOpen ? (
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="text-xs p-0.5"
                                        onClick={() => toggleIsOpen(insight.id)}
                                    >
                                        <CaretUpIcon className="w-4 h-4" />
                                    </Button>
                                ) : (
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        className="text-xs p-0.5"
                                        onClick={() => toggleIsOpen(insight.id)}
                                    >
                                        <CaretDownIcon className="w-4 h-4" />
                                    </Button>
                                )}
                            </div>
                            {isOpen && (
                                <div className="pb-3">
                                    <span className="text-xs text-muted-foreground">
                                        {insight.description}
                                    </span>
                                    <div className="flex flex-row justify-between items-center gap-2 w-full pt-0.5">
                                        <div className="flex items-center gap-2 overflow-hidden">
                                            <Badge
                                                color="gray"
                                                size="1"
                                                radius="full"
                                                className="flex items-center gap-1"
                                            >
                                                {selectedStatus ? (
                                                    <selectedStatus.icon className="ml-0.5 w-3 h-3" />
                                                ) : (
                                                    <QuestionMarkCircledIcon
                                                        style={{
                                                            minWidth: "5px",
                                                            minHeight: "5px",
                                                        }}
                                                        className="ml-0.5"
                                                    />
                                                )}
                                                <span className="text-xs">
                                                    {selectedStatus
                                                        ? selectedStatus.label
                                                        : "Unknown"}
                                                </span>
                                            </Badge>
                                        </div>
                                        <Badge
                                            color="gray"
                                            size="1"
                                            radius="full"
                                            className="my-1"
                                        >
                                            <CaretUpIcon /> {insight.count}
                                        </Badge>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Card>
                );
            })}
            {showLinkInsight && (
                <div className="flex items-center justify-between gap-2 pb-2">
                    <DropdownMenu
                        open={isDropdownOpen}
                        onOpenChange={setIsDropdownOpen}
                    >
                        <DropdownMenuTrigger asChild type="button">
                            <Button
                                variant="secondary"
                                size="sm"
                            >
                                Link Insight
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent
                            align="start"
                            className="fixed w-[300px] max-h-30 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                        >
                            <Command className="rounded-md shadow-md text-xs pb-1">
                                <CommandInput
                                    placeholder="Filter Insights..."
                                    className="px-1"
                                />
                                <CommandList className="space-y-1">
                                    <CommandEmpty className="text-xs px-4 py-2">
                                        No Insights Found
                                    </CommandEmpty>
                                    {combinedData
                                        .sort((a, b) => {
                                            return a.title.localeCompare(
                                                b.title,
                                            );
                                        })
                                        .map((insightOption) => (
                                            <CommandItem
                                                key={insightOption.id}
                                                className="flex items-center justify-between text-xs rounded-md hover:bg-muted text-gray-700 hover:text-gray-950 w-full px-4 py-1.5"
                                                onSelect={handleItemSelect(
                                                    insightOption,
                                                )}
                                            >
                                                <div className="flex items-center gap-2" >
                                                    {insightOption.title}
                                                    {issue.insights
                                                        ?.map(
                                                            (insight) =>
                                                                insight.id,
                                                        )
                                                        .includes(
                                                            insightOption.id,
                                                        ) && (
                                                            <CheckIcon className="h-4 w-4" />
                                                        )}
                                                </div>
                                            </CommandItem>
                                        ))}
                                </CommandList>
                            </Command>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    {issue.insights?.length > 1 && (
                        <Button
                            variant="ghost"
                            className="text-xs px-2 py-1"
                            onClick={() => showInsights(!showAllInsights)}
                        >
                            {showAllInsights
                                ? "Show Less..."
                                : `Show ${issue.insights?.length - 1} More...`}
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

export default memo(InsightsSection);
