import type {
    TimerAnalyticsResponse,
} from "@/interfaces/serverData";
import { Card, Flex, Text } from "@radix-ui/themes";
import type { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Bar, BarChart } from "recharts";
import {
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

interface BarListRow {
    name: string;
    value: number;
    color: string;
}

export interface TickerTimerPageProps {
    dataQuery: UseQueryResult<TimerAnalyticsResponse, unknown>;
}

export function TickerTimerPage({
    dataQuery,
}: TickerTimerPageProps) {
    const [data, setData] = useState<BarListRow[]>([]);

    useEffect(() => {
        if (dataQuery.isSuccess) {
            const data = dataQuery.data?.data?.map((item) => ({
                name: `ASM-${item.ticket_number}`,
                value: item.total_duration,
                color: "blue",
            }));
            setData(data);
        }
    }, [dataQuery.data?.data, dataQuery.isSuccess]);


    return (
            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Timer
                    </Text>
                    <Text size="3" color="gray" mb="2">
                        View how much time is spent on each ticket.
                    </Text>
                    <ResponsiveContainer height={400}>
                        <BarChart data={data} margin={{ left: 50 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis
                                dataKey="value"
                                label={{
                                    value: "Duration (hours)",
                                    angle: -90,
                                    position: "insideLeft",
                                    offset: -35,
                                }}
                            />
                            <Legend />
                            <Tooltip />
                            <Bar dataKey="value" fill="#8884d8" />
                        </BarChart>
                    </ResponsiveContainer>
                </Flex>
            </Card>
    );
}
