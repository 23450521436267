import { useEffect, useState } from "react";
import type { CodeBlockProps } from "../interfaces/SearchBlock";
import CodeBlameToggle from "./CodeBlameToggle";

function CodeSnippetComponent(props: CodeBlockProps) {
    const [language] = useState<string>(getLanguage());
    const [codeLines] = useState<string[]>(
        props.codeBlock.split("\n"),
    );

    const [displayedFilePath,] = useState<string>(
        getDisplayedFilePath(),
    );

    const [displayedLines, setDisplayedLines] = useState<string[]>([]);
    const [showFullCode, setShowFullCode] = useState<boolean>(false);

    const [blame, setBlame] = useState(false);
    const handleToggle = (isBlameOn: boolean) => {
        setBlame(isBlameOn);
    };

    function getLanguage(): string {
        const myMap = new Map<string, string>([
            ["py", "python"],
            ["java", "java"],
            ["go", "go"],
            ["c", "c"],
            ["js", "javascript"],
            ["ts", "typescript"],
            ["jsx", "javascript"],
            ["tsx", "typescript"],
            ["cpp", "cpp"],
        ]);

        const filePathArr = props.filePath.split(".");
        const extension = filePathArr[filePathArr.length - 1];

        if (myMap.get(extension)) {
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            return myMap.get(extension)!;
        } else {
            return "python"; // default it to python at least
        }
    }

    function getDisplayedFilePath(): string {
        const firstSlashIndex = props.filePath.indexOf("/");
        const dividedResponse = props.filePath.substring(firstSlashIndex);
        if (dividedResponse.length > 55) {
            return `${dividedResponse.substring(0, 55)}...`;
        }
        return dividedResponse;
    }

    const toggleCodeDisplay = () => {
        setShowFullCode(!showFullCode);
    };

    useEffect(() => {
        if (showFullCode) {
            setDisplayedLines(codeLines);
        } else {
            setDisplayedLines(codeLines.slice(0, 5));
        }
    }, [showFullCode, props.codeBlock, codeLines]);

    const handleExternalLink = (url: string) => {
        window.open(url, "_blank");
    };

    const getMessage = (lineNumber: number) => {
        const message: string | undefined =
            props.githubblame?.[lineNumber]?.Message;

        const time: string | undefined =
            props.githubblame?.[lineNumber]?.CommittedDate;

        const datePadding = 12;
        let readableDate = " ".repeat(9);
        if (time !== undefined) {
            const date = new Date(time);
            readableDate = date.toLocaleDateString();
            readableDate = readableDate.padEnd(datePadding, " ");
        }

        const padEnding = 30;

        // ensure that message is always 10 characters.
        if (message === undefined) {
            return `${readableDate} ${" ".repeat(padEnding)}`;
        } else if (message.length > padEnding - 3) {
            return `${readableDate} ${message.substring(0, padEnding - 3)}...`;
        } else {
            return `${readableDate} ${message.padEnd(padEnding, " ")}`;
        }
    };

    const getCommitLink = (lineNumber: number) => {
        const commitId: string | undefined =
            props.githubblame?.[lineNumber]?.Id;
        if (commitId) {
            const org = `${props.owner}/${props.repo}`;
            const link = `https://github.com/${org}/commit/${commitId}`;
            return link;
        }
        return "";
    };

    return (
        <div className="m-[0.3rem] mx-20 p-[0.3rem] mb-8 bg-[#2d333b] text-[#c9d1d9] border border-[#444c56] rounded-md">
            <div className="flex items-center bg-[#434d59] rounded-md">
                <CodeBlameToggle onToggle={handleToggle} />
                <div className="flex justify-between items-center flex-1 px-4">
                    <div className="text-[0.85rem] whitespace-nowrap overflow-hidden text-ellipsis">
                        <a
                            className="text-inherit hover:underline cursor-pointer"
                            href={props.link}
                            onClick={() => handleExternalLink(props.link)}
                        >
                            {displayedFilePath}
                        </a>
                    </div>
                    <div className="text-[0.85rem] text-right whitespace-nowrap">
                        {language} · main
                        <button className="text-[#8b949e] p-2" type="button">
                            {!showFullCode ? (
                                <span onClick={toggleCodeDisplay}>▼</span>
                            ) : (
                                <span onClick={toggleCodeDisplay}>▲</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>

            <div className="overflow-x-auto pb-[3px] scrollbar-thin scrollbar-thumb-[#434d59] scrollbar-thumb-rounded-full">
                {displayedLines.map((code, index) => (
                    <div
                        key={code}
                        className="flex p-[0.2rem] max-w-[4rem] hover:bg-[#41484f]"
                    >
                        {blame && (
                            <code className="pr-3 font-mono text-xs tracking-[-1.5px] whitespace-pre">
                                <a
                                    className="text-inherit hover:underline cursor-pointer"
                                    href={getCommitLink(index + props.startLine)}
                                    onClick={() =>
                                        handleExternalLink(
                                            getCommitLink(index + props.startLine)
                                        )
                                    }
                                >
                                    {getMessage(index + props.startLine)}
                                </a>
                            </code>
                        )}
                        <span className="inline-block w-8 text-[#8b949e] text-right px-4 select-none text-xs">
                            {index + props.startLine}
                        </span>
                        <code
                            className={`font-mono text-xs tracking-[-0.75px] whitespace-pre language-${language}`}
                        >
                            {code}
                        </code>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CodeSnippetComponent;
