import { Button } from "@/component/shadcn/ui/button";
import { API, URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Account,
    GetOnboardingResponse,
    GetTopicsResponse,
    GetUserResponse,
    Onboarding,
    OnboardingChecklist,
    OnboardingChecklistPayload,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { PlusIcon } from "@radix-ui/react-icons";
import { Flex } from "@radix-ui/themes";
import { type RefetchOptions, useQuery } from "@tanstack/react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import { NewOnboardingCard } from "./OnboardingCard";
import { OnboardingWrapper } from "./OnboardingWrapper";
import {
    deleteItem,
    saveAll,
    saveAssignee,
    saveCompleted,
    saveEnabled,
} from "./methods";
import { useToast } from "@/component/shadcn/ui/use-toast";

export interface OnboardingProps {
    orgID: string;
    userID: string;
    accountProp: Account;
    accountType: "Company" | "Customer";
}

export const OnboardingPage = ({
    orgID,
    userID,
    accountProp,
    accountType,
}: OnboardingProps) => {
    const api = useApi();
    const { toast } = useToast();

    const fetchOnboardingChecklist =
        async (): Promise<GetOnboardingResponse> => {
            try {
                const { url } = ContactsAPI.getOnboardingChecklist;
                const response = await api.get(`${URLS.serverUrl}${url}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        [accountType === "Company"
                            ? "company_id"
                            : "customer_id"]: accountProp.id,
                    },
                });
                if (response.data === null) {
                    return {
                        onboarding: undefined,
                        onboarding_checklist: [],
                    };
                }
                if (response.status === 200) {
                    return response.data.data;
                }
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            } catch (error) {
                console.error("Error fetching queries:", error);
                return {
                    onboarding: undefined,
                    onboarding_checklist: [],
                };
            }
        };

    const {
        data: onboardingData,
        isLoading: loadingOnboardingData,
        isError: errorOnboardingData,
        refetch: refetchOnboardingData,
    } = useQuery({
        queryKey: ["onboardingItems", accountProp.id],
        queryFn: fetchOnboardingChecklist,
    });

    const [showAddItem, setShowAddItem] = useState<boolean>(false);
    const [showAddChildItem, setShowAddChildItem] = useState<{
        show: boolean;
        parentId: string | null;
    }>({
        show: false,
        parentId: null,
    });

    const [onboardingItems, setOnboardingItems] = useState<
        Map<string, OnboardingChecklist>
    >(
        new Map(
            onboardingData?.onboarding_checklist?.map((item) => [
                item.id,
                item,
            ]),
        ),
    );

    useEffect(() => {
        setOnboardingItems(
            new Map(
                onboardingData?.onboarding_checklist?.map((item) => [
                    item.id,
                    item,
                ]),
            ),
        );
    }, [onboardingData]);

    const [onboardingMainItem, setOnboardingMainItem] = useState<
        Onboarding | undefined
    >(onboardingData?.onboarding);

    useEffect(() => {
        debugger;
        if (!loadingOnboardingData && onboardingData) {
            setOnboardingMainItem(onboardingData?.onboarding);
        }
    }, [loadingOnboardingData, onboardingData]);

    const addOnboardingItem = useCallback(
        (onboardingItem: OnboardingChecklistPayload) => {
            if (accountType === "Company") {
                onboardingItem.company_id = accountProp.id;
            } else {
                onboardingItem.customer_id = accountProp.id;
            }
            api.post(
                `${URLS.serverUrl}${ContactsAPI.addOnboardingChecklistItem.url}`,
                onboardingItem,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Added onboarding task!",
                            description: "Your onboarding task has been added successfully.",
                        });

                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                        setShowAddItem(false);
                        setShowAddChildItem({ show: false, parentId: null });
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [accountType, accountProp.id, api, refetchOnboardingData],
    );

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const [selectedItems, setSelectedItems] = useState<OnboardingChecklist[]>(
        [],
    );

    const setOnboardingStateHelper = useCallback(
        (
            newState: Partial<OnboardingChecklist>,
            updatedMap: Map<string, OnboardingChecklist>,
            val: OnboardingChecklist,
        ) => {
            if (!val.id) {
                return updatedMap;
            }
            const currentIssue = updatedMap?.get(val.id);

            if (!currentIssue) {
                return updatedMap;
            }

            const updatedIssue = {
                ...currentIssue,
                ...newState,
                assignee_user_id:
                    newState.assignee_user_id || currentIssue.assignee_user_id,
            };

            updatedMap?.set(val.id, updatedIssue);
        },
        [],
    );

    const setOnboardingState = useCallback(
        (
            newState: Partial<OnboardingChecklist>,
            oldItem: OnboardingChecklist,
        ) => {
            setOnboardingItems((prev) => {
                const updatedMap = new Map(prev);
                if (selectedItems.length === 0) {
                    // single issue
                    setOnboardingStateHelper(newState, updatedMap, oldItem);
                } else {
                    // selected issues with selector
                    for (const val of selectedItems) {
                        setOnboardingStateHelper(newState, updatedMap, val);
                    }
                }
                return updatedMap;
            });
            setSelectedItems([]);
        },
        [selectedItems, setOnboardingStateHelper],
    );

    const deleteOnboardingItem = useCallback(
        (ids: string[]) => {
            setOnboardingItems((prev) => {
                const updatedMap = new Map(prev);
                if (selectedItems.length === 0) {
                    // single issue
                    updatedMap.delete(ids[0]);
                } else {
                    // selected issues with selector
                    for (const val of selectedItems) {
                        updatedMap.delete(val.id);
                    }
                }
                return updatedMap;
            });
            setSelectedItems([]);
        },
        [selectedItems],
    );

    const handleDeleteIssue = useCallback(
        (userID: string, issueId: string, item: OnboardingChecklist) => {
            let selectedIssueIds: string[];
            if (selectedItems.length === 0) {
                selectedIssueIds = [issueId];
            } else {
                selectedIssueIds = selectedItems.map((issue) => issue.id);
            }

            deleteItem(
                api,
                selectedIssueIds,
                deleteOnboardingItem,
                item,
                userID,
            );
        },
        [selectedItems, api, deleteOnboardingItem],
    );

    const saveIssue = useCallback(
        (
            type: string,
            payload: Partial<OnboardingChecklistPayload>,
            userID: string,
            updateIssueState: (
                newState: Partial<OnboardingChecklist>,
                oldItem: OnboardingChecklist,
            ) => void,
            refetch: (
                options?: RefetchOptions,
            ) => Promise<OnboardingChecklist[]>,
            issueId: string,
            item: OnboardingChecklist,
            topicsMap?: Map<string, GetTopicsResponse>,
        ) => {
            let selectedIssueIds: string[];
            if (selectedItems.length === 0) {
                selectedIssueIds = [issueId];
            } else {
                selectedIssueIds = selectedItems.map((issue) => issue.id);
            }
            switch (type) {
                case "completed":
                    return saveCompleted(
                        payload.completed || false,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        item,
                        userID,
                    );
                case "Assignee":
                    return saveAssignee(
                        payload.assignee_user_id || "",
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        item,
                        userID,
                    );
                case "enabled":
                    return saveEnabled(
                        payload.enabled ?? true,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        item,
                        userID,
                    );
                case "ALL":
                    return saveAll(
                        payload,
                        api,
                        selectedIssueIds,
                        updateIssueState,
                        item,
                        userID,
                    );
                default:
                    return null;
            }
        },
        [selectedItems, api],
    );

    const [lastSelectedIssue, setLastSelectedIssue] = useState<{
        issueId: string;
    } | null>(null);

    const handleItemSelect = useCallback(
        (checked: boolean, issueId: string, event?: React.MouseEvent) => {
            const currentItem = onboardingItems.get(issueId);
            if (!currentItem) return;

            setSelectedItems((prev) => {
                if (event?.shiftKey && lastSelectedIssue) {
                    // Get all items as a flat array
                    const allItems = Array.from(onboardingItems.values());

                    // Find indices of last selected and current items
                    const startIdx = allItems.findIndex(
                        (item) => item.id === lastSelectedIssue.issueId,
                    );
                    const endIdx = allItems.findIndex(
                        (item) => item.id === issueId,
                    );

                    if (startIdx === -1 || endIdx === -1) return prev;

                    // Get range of items between last selected and current
                    const [rangeStart, rangeEnd] = [
                        Math.min(startIdx, endIdx),
                        Math.max(startIdx, endIdx),
                    ];

                    const itemsInRange = allItems.slice(
                        rangeStart,
                        rangeEnd + 1,
                    );

                    if (checked) {
                        // Add range to selection, avoiding duplicates
                        const newSelection = new Set([
                            ...prev,
                            ...itemsInRange,
                        ]);
                        return Array.from(newSelection);
                    } else {
                        // Remove range from selection
                        const rangeIds = new Set(
                            itemsInRange.map((item) => item.id),
                        );
                        return prev.filter((item) => !rangeIds.has(item.id));
                    }
                }

                // Single item selection
                if (checked) {
                    return [...prev, currentItem];
                } else {
                    return prev.filter((item) => item.id !== issueId);
                }
            });

            setLastSelectedIssue({ issueId });
        },
        [lastSelectedIssue, onboardingItems],
    );

    const handleAddChild = useCallback((parentId: string) => {
        setShowAddChildItem({ show: true, parentId });
    }, []);

    const [topLevelEditing, setTopLevelEditing] = useState<{
        edit: boolean;
        parentId: string;
    }>({
        edit: false,
        parentId: "",
    });

    const updateGoogleLink = useCallback(
        (onboarding_id: string, google_link: string) => {
            api.post(
                `${URLS.serverUrl}${ContactsAPI.updateOnboardingGoogleLink.url}`,
                { onboarding_id, google_link },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Google Sheet!",
                            description: "Your Google Sheet has been updated successfully.",
                        });

                        if (refetchOnboardingData) {
                            refetchOnboardingData();
                        }
                        setShowAddItem(false);
                        setShowAddChildItem({ show: false, parentId: null });
                    }
                })
                .catch((err) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                });
        },
        [showAddChildItem, topLevelEditing],
    );

    // very complicated logic, but it's for calculating the line height of the tree on the onboarding page.
    const calculateTotalHeight = useCallback(
        (items: OnboardingChecklist[], parentId: string): [number, number] => {
            const directChildren = items.filter(
                (child) => child.parent_id === parentId,
            );

            const newChild =
                showAddChildItem.show && showAddChildItem.parentId === parentId;
            const newEditing =
                topLevelEditing.edit && topLevelEditing.parentId === parentId;

            if (directChildren.length === 0 && !newChild && !newEditing) {
                return [0, 0];
            }

            const cardHalfHeight = 25;
            const gapAndCardHalf = cardHalfHeight + 10;
            let totalHeight = 35;
            let realHeight = 0;
            if (newEditing) {
                // Initial offset (if parent card is being modified)
                totalHeight = 45;
                realHeight = 5;
            }

            directChildren.forEach((child, index) => {
                // Add height for the card itself (connecting to middle of card)
                totalHeight += cardHalfHeight; // Half of card height (50/2) to connect to center
                realHeight += cardHalfHeight;
                // Calculate children height
                const childHeight = calculateTotalHeight(items, child.id);

                // Add child height if there are children
                if (childHeight[0] > 0) {
                    totalHeight += childHeight[0];
                }

                // Add gap for next sibling if not last child
                if (index < directChildren.length - 1) {
                    totalHeight += gapAndCardHalf;
                    realHeight += gapAndCardHalf;
                    realHeight += childHeight[0];
                }
            });
            if (newChild) {
                if (directChildren.length !== 0) {
                    realHeight = totalHeight;
                    totalHeight += gapAndCardHalf;
                }
                totalHeight += 39; // accounts for additional height of 'NewOnboardingCard'
                realHeight += 39;
            }
            return [totalHeight, realHeight];
        },
        [showAddChildItem, topLevelEditing],
    );

    const renderOnboardingItems = useCallback(() => {
        const items = Array.from(onboardingItems.values());
        const topLevelItems = items.filter((item) => !item.parent_id);

        const renderItem = (item: OnboardingChecklist) => {
            const [_, realHeight] = calculateTotalHeight(items, item.id);
            const children = items.filter(
                (child) => child.parent_id === item.id,
            );

            return (
                <div key={item.id} className="w-full flex flex-col ">
                    <OnboardingWrapper
                        item={item}
                        users={usersQuery.data ?? []}
                        saveIssue={saveIssue}
                        userID={userID}
                        refetch={async (options?: RefetchOptions) => {
                            const result = await refetchOnboardingData(options);
                            return (
                                result.data ?? {
                                    onboarding: undefined,
                                    onboarding_checklist: [],
                                }
                            );
                        }}
                        isSelected={selectedItems.includes(item)}
                        onSelect={handleItemSelect}
                        updateIssueState={setOnboardingState}
                        deleteIssue={handleDeleteIssue}
                        onAddChild={handleAddChild}
                        setTopLevelEditing={setTopLevelEditing}
                    />
                    {(realHeight > 0 ||
                        (showAddChildItem.show &&
                            showAddChildItem.parentId === item.id)) && (
                        <div className="ml-[61px] relative">
                            <div
                                className={`absolute top-0 w-[1.5px] ${item.completed ? "bg-green9" : "bg-blue9"}`}
                                style={{
                                    height: `${realHeight}px`,
                                }}
                            />

                            <div className="flex flex-col gap-[10px] mt-[10px]">
                                {children.map((child) => (
                                    <div key={child.id} className="relative">
                                        <div>
                                            <svg
                                                className="absolute top-[9px]"
                                                width="40"
                                                height="40"
                                                style={{ overflow: "visible" }}
                                            >
                                                <path
                                                    d="M0.75 0 L0.75 8 A8 8 0 0 0 8.75 16 L16.75 16"
                                                    stroke={
                                                        item.completed
                                                            ? "#30A46C"
                                                            : "#3B82F6"
                                                    }
                                                    strokeWidth="1.5"
                                                    fill="none"
                                                />
                                            </svg>
                                            <div className="ml-4">
                                                {renderItem(child)}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {showAddChildItem.show &&
                                    showAddChildItem.parentId === item.id && (
                                        <div className="relative">
                                            <div>
                                                <svg
                                                    className="absolute top-[18px]"
                                                    width="40"
                                                    height="40"
                                                    style={{
                                                        overflow: "visible",
                                                    }}
                                                >
                                                    <path
                                                        d="M0.75 0 L0.75 8 A8 8 0 0 0 8.75 16 L16.75 16"
                                                        stroke={
                                                            item.completed
                                                                ? "#30A46C"
                                                                : "#3B82F6"
                                                        }
                                                        strokeWidth="1.5"
                                                        fill="none"
                                                    />
                                                </svg>
                                                <div className="ml-4">
                                                    <NewOnboardingCard
                                                        removeCard={() =>
                                                            setShowAddChildItem(
                                                                {
                                                                    show: false,
                                                                    parentId:
                                                                        null,
                                                                },
                                                            )
                                                        }
                                                        handleSave={
                                                            addOnboardingItem
                                                        }
                                                        users={
                                                            usersQuery.data ??
                                                            []
                                                        }
                                                        userID={userID}
                                                        parentId={item.id}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                        </div>
                    )}
                </div>
            );
        };

        return topLevelItems.map((item) => renderItem(item));
    }, [
        onboardingItems,
        usersQuery.data,
        saveIssue,
        userID,
        addOnboardingItem,
        refetchOnboardingData,
        selectedItems,
        handleItemSelect,
        setOnboardingState,
        handleDeleteIssue,
        handleAddChild,
        showAddChildItem,
        calculateTotalHeight,
    ]);

    const [googleSheetUrl, setGoogleSheetUrl] = useState<string>("");

    return (
        <Flex direction="column" align="center" justify="center" gap="2">
            <div className="flex flex-row gap-2 justify-end items-end w-full">
                <div className="ml-auto flex gap-2">
                    <Button
                        className="shadow-md outline outline-1 mb-3 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                        size="sm"
                        variant="outline"
                        onClick={() => setShowAddItem(true)}
                    >
                        Add Onboarding Task
                        <PlusIcon />
                    </Button>

                    {/* <Dialog>
                        <DialogTrigger asChild>
                            <Button
                                className="shadow-md outline outline-1 mb-3 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                {!onboardingMainItem?.google_link ? (
                                    <>
                                        Connect Google Spreadsheet
                                        <PlusIcon />
                                    </>
                                ) : (
                                    <>
                                        Update Google Spreadsheet
                                        <Pencil1Icon />
                                    </>
                                )}
                            </Button>
                        </DialogTrigger>

                        <DialogContent className="sm:max-w-[425px]">
                            <DialogHeader>
                                <DialogTitle>Connect Google Sheet</DialogTitle>
                                <DialogDescription>
                                    Enter the URL of your Google Sheet to
                                    connect it with this onboarding checklist.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <Label
                                        htmlFor="sheet-url"
                                        className="text-right"
                                    >
                                        Sheet URL
                                    </Label>
                                    <Input
                                        id="sheet-url"
                                        placeholder="https://docs.google.com/spreadsheets/d/..."
                                        className="col-span-3"
                                        value={googleSheetUrl}
                                        onChange={(e) =>
                                            setGoogleSheetUrl(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <DialogFooter>
                                <DialogClose>
                                    <Button
                                        type="submit"
                                        onClick={() =>
                                            updateGoogleLink(
                                                userID,
                                                googleSheetUrl,
                                            )
                                        }
                                    >
                                        Connect Sheet
                                    </Button>
                                </DialogClose>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog> */}
                </div>
            </div>
            {loadingOnboardingData ? (
                <div>Loading...</div>
            ) : (
                <>
                    {renderOnboardingItems()}
                    {showAddItem && (
                        <NewOnboardingCard
                            removeCard={() => setShowAddItem(false)}
                            handleSave={addOnboardingItem}
                            users={usersQuery.data ?? []}
                            userID={userID}
                        />
                    )}
                </>
            )}
        </Flex>
    );
};
