import { Card } from "@/component/shadcn/ui/card";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "@radix-ui/react-icons";
import { useMemo } from "react";

type DateInput = string | Date | undefined;

export default function CalendarBadge({
    date,
    iconColor,
}: { date: DateInput; iconColor?: string }) {
    const formattedDate = useMemo(() => {
        if (!date) return null;

        try {
            if (date instanceof Date) {
                return date.toLocaleDateString();
            }
            const d = new Date(date);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert date input "${date}" to a valid date. Error: ${err}`,
            );
            return typeof date === "string" ? date : null;
        }
    }, [date]);

    if (!formattedDate) return null;

    return (
        <Card
            className={cn(
                "rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 py-1 items-center",
            )}
        >
            <CalendarIcon className={iconColor} />
            <p className="text-xs ">{formattedDate}</p>
        </Card>
    );
}
