import type {
    Account,
    EditorActionType,
    ScopeResponse,
    Ticket,
    UploadedFile,
    UploadedFileWithMetadata,
    UserResponse,
} from "@/interfaces/serverData";
import { MATCHERS, TRANSFORMERS, validateUrl } from "@/utilities/methods";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { $convertToMarkdownString } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { ClickableLinkPlugin } from "@lexical/react/LexicalClickableLinkPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { Cross1Icon } from "@radix-ui/react-icons";
import { ReaderIcon } from "@radix-ui/react-icons";
import { LineBreakNode, ParagraphNode } from "lexical";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import { Button } from "../shadcn/ui/button";
import { Card } from "../shadcn/ui/card";
import { AIResponsePlugin } from "./AIResponsePlugin";
import ActionsPlugin from "./ActionsPlugin";
import EnterCommand from "./EnterCommand";
import FilesPlugin from "./FilePlugin";
import ImagesPlugin from "./ImagesPlugin";
import InternalMessageCommand from "./InternalMessages";
import { TemplatesPlugin } from "./TemplatesPlugin";
import { EmojiNode } from "./nodes/EmojiNode";
import { FileNode } from "./nodes/FileNode";
import { ImageNode } from "./nodes/ImageNode";
import { VariableNode } from "./nodes/TextVariableNode";

interface EditorProps {
    className?: string;
    handleSubmit: (
        markdwn: string,
        files: (UploadedFile | UploadedFileWithMetadata)[],
        type: EditorActionType,
        source?: string, // for sending a new message
        channelID?: string, // for sending a new message
        recipient?: string,
        integration_id?: string,
        subject?: string,
        new_source_specific_id?: string,
    ) => Promise<{ id: string; url: string }>;
    enableAIResponse: boolean;
    isToggled?: boolean;
    setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading?: boolean;
    setKey?: React.Dispatch<React.SetStateAction<number>>;
    handleImageUpload: (src: string, altText: string) => void;
    handleFileUpload: (
        file_name: string,
        file_type: string,
        file_size: number,
        file: File,
    ) => void;
    handleDeleteFile: (
        fileToDelete: UploadedFile | UploadedFileWithMetadata,
    ) => void;
    uploadedFiles: (UploadedFile | UploadedFileWithMetadata)[];
    source: string;
    customerUserInfo?: UserResponse;
    customerAccount?: Account;
    companyAccount?: Account;
    gmailScopes?: ScopeResponse[];
    originalTicket?: Ticket;
    signature?: string;
    channelID?: string;
    newMessage?: boolean;
    fullyDisableSend?: boolean;
}

export function Editor({
    className,
    handleSubmit,
    enableAIResponse,
    isToggled,
    setIsToggled = () => { },
    isSendDisabled,
    setIsSendDisabled,
    loading = false,
    handleImageUpload,
    handleFileUpload,
    handleDeleteFile,
    uploadedFiles,
    source,
    customerUserInfo,
    customerAccount,
    companyAccount,
    gmailScopes,
    originalTicket,
    signature,
    channelID,
    newMessage,
    fullyDisableSend
}: EditorProps) {
    const initialConfig = {
        namespace: "MyEditor",
        theme: ExampleTheme,
        onError: (error: unknown) => {
            console.error(error);
            throw error;
        },
        nodes: [
            HeadingNode,
            QuoteNode,
            ListNode,
            ListItemNode,
            EmojiNode,
            LineBreakNode,
            ParagraphNode,
            ImageNode,
            FileNode,
            VariableNode,
            LinkNode,
            AutoLinkNode,
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div
                className={`relative flex flex-row justify-between w-full pb-2 gap-[50px] bg-white ${className || ""}`}
            >
                <div className="text-sm relative flex flex-col flex-1 gap-1 rounded-lg transition-all">
                    {enableAIResponse && (
                        <AIResponsePlugin
                            isToggled={isToggled ?? false}
                            setIsToggled={setIsToggled}
                        />
                    )}
                    <ClickableLinkPlugin newTab={true} />
                    <LinkPlugin validateUrl={validateUrl} />

                    <Toolbar
                        handleImageUpload={handleImageUpload}
                        handleFileUpload={handleFileUpload}
                        source={source}
                        setIsSendDisabled={setIsSendDisabled}
                        uploadedFiles={uploadedFiles}
                        signature={signature}
                    />
                    {enableAIResponse && (
                        <AIResponsePlugin
                            isToggled={isToggled ?? false}
                            setIsToggled={setIsToggled}
                        />
                    )}
                    <ClickableLinkPlugin newTab={true} />
                    <LinkPlugin validateUrl={validateUrl} />
                    <TemplatesPlugin
                        customerUserInfo={customerUserInfo}
                        customerAccount={customerAccount}
                        companyAccount={companyAccount}
                        className={enableAIResponse ? "right-[100px]" : "right-[10px]"}
                    />
                    <div className="max-h-full overflow-scroll scrollbar-white">
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable className="w-full" />
                            }
                            placeholder={<div />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    </div>
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    <AutoLinkPlugin matchers={MATCHERS} />
                    <ImagesPlugin handleFileUpload={handleImageUpload} />
                    <EnterCommand
                        onSubmit={(type: EditorActionType) => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn, uploadedFiles, type);
                        }}
                    />
                    <InternalMessageCommand
                        onSubmit={(type: EditorActionType) => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn, uploadedFiles, type);
                        }}
                    />
                    <ListPlugin />
                    <FilesPlugin handleFileUpload={handleFileUpload} />
                    <div className="flex items-center flex-wrap gap-1">
                        {uploadedFiles.map(
                            (file) =>
                                file.type === "image" && (
                                    <div
                                        key={(file as UploadedFile).alt}
                                        className="relative"
                                    >
                                        <div className="w-[100px] h-[100px] overflow-hidden relative m-1">
                                            <img
                                                src={(file as UploadedFile).src}
                                                alt={(file as UploadedFile).alt}
                                                style={{
                                                    maxWidth: "auto",
                                                    height: "100%",
                                                    objectFit: "cover",
                                                }}
                                            />
                                        </div>
                                        <Button
                                            onClick={() =>
                                                handleDeleteFile(
                                                    file as UploadedFile,
                                                )
                                            }
                                            className="absolute px-1 h-4 top-0 right-0 bg-[#5B5BD6]"
                                        >
                                            <Cross1Icon className="w-2.5 h-2.5" />
                                        </Button>
                                    </div>
                                ),
                        )}
                        {uploadedFiles.map(
                            (file) =>
                                file.type === "file" && (
                                    <div
                                        key={
                                            (file as UploadedFileWithMetadata)
                                                .file_name
                                        }
                                        className="relative "
                                    >
                                        <Card className="shadow-none border-none rounded-lg flex flex-row items-center gap-1 text-sm text-gray-600 text-center px-2 overflow-hidden relative m-1 flex items-center justify-center bg-gray-100">
                                            <ReaderIcon className="w-3.5 h-3.5" />
                                            <p>
                                                {
                                                    (
                                                        file as UploadedFileWithMetadata
                                                    ).file_name
                                                }
                                            </p>
                                            <Button
                                                onClick={() =>
                                                    handleDeleteFile(
                                                        file as UploadedFileWithMetadata,
                                                    )
                                                }
                                                variant="ghost"
                                                className="p-0.5"
                                            >
                                                <Cross1Icon className="w-2.5 h-2.5" />
                                            </Button>
                                        </Card>
                                    </div>
                                ),
                        )}
                    </div>
                    <ActionsPlugin
                        isSendDisabled={isSendDisabled}
                        loading={loading}
                        handleSubmit={(
                            type: EditorActionType,
                            recipient?: string,
                            integration_id?: string,
                            subject?: string,
                            header?: string,
                            new_source_specific_id?: string,
                        ) => {
                            let mrkdwn = $convertToMarkdownString(TRANSFORMERS);
                            if (header) {
                                mrkdwn = `${header}\n\n=========\n\n${mrkdwn}`;
                            }
                            return handleSubmit(
                                mrkdwn,
                                uploadedFiles,
                                type,
                                source,
                                channelID,
                                recipient,
                                integration_id,
                                subject,
                                new_source_specific_id,
                            );
                        }}
                        gmailScopes={gmailScopes}
                        originalTicket={originalTicket}
                        newMessage={newMessage}
                        fullyDisableSend={fullyDisableSend}
                    />
                    <FilesPlugin handleFileUpload={handleFileUpload} />
                </div>
            </div>
        </LexicalComposer>
    );
}
