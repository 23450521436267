import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import * as Toggle from "@radix-ui/react-toggle";

import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";

import SparklesIcon from "../../images/icons8-sparkles-48.png";

import ShinyButton from "@/component/shadcn/magicui/shiny-button";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    DescribeResponse,
    SearchResponse,
    Ticket,
} from "@/interfaces/serverData";
import { useQuery } from "@tanstack/react-query";
import { Loader2Icon } from "lucide-react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "../shadcn/ui/use-toast";

interface AIResponsePluginProps {
    className?: string;
    getAIResponse?: () => void;
    isToggled: boolean;
    setIsToggled: React.Dispatch<React.SetStateAction<boolean>>;
    loaded?: boolean;
    setIsSendDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
    setKey?: React.Dispatch<React.SetStateAction<number>>;
    aiResponseCompleted?: number;
}

export function AIResponsePlugin({
    className,
    isToggled,
    setIsToggled,
}: AIResponsePluginProps) {
    const [editor] = useLexicalComposerContext();

    const { id } = useParams<{ id: string }>();
    const { team_id } = useParams<{ team_id: string }>();
    const api = useApi();
    const { toast } = useToast();

    const getAIResponse = async (): Promise<Ticket | null> => {
        setAskAIUpdateLoading(true);
        let endpoint = `${URLS.serverUrl}${API.getTicketInfo}/${id}`;
        if (team_id) {
            endpoint = `${URLS.serverUrl}${API.getTicketInfo}/team/${team_id}/issue/${id}`;
        }
        try {
            const response = await api.get(endpoint, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            });
            if (response.status === 200) {
                const ticketData: Ticket = response.data.data;
                if (ticketData === undefined) {
                    return null;
                }
                if (
                    ticketData.ai_response !== undefined &&
                    ticketData.ai_response !== ""
                ) {
                    updateEditor(ticketData.ai_response);
                    return ticketData;
                } else {
                    if (ticketData?.query) {
                        const content = ticketData.query;
                        const reqData = {
                            query: content,
                        };

                        api.post(URLS.serverUrl + API.search, reqData, {
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                            },
                        }).then(async (res) => {
                            if (res.status === 200) {
                                const searchResp: SearchResponse[] =
                                    res.data.data;

                                const objects = [];

                                if (searchResp.length === 0) {
                                    return {
                                        content: [
                                            {
                                                type: "text",
                                                text: "Sorry, don't have an answer to your question",
                                            },
                                        ],
                                    };
                                }

                                for (const searchElem of searchResp) {
                                    const elem = {
                                        id: searchElem.id,
                                        source: searchElem.kind,
                                    };
                                    objects.push(elem);
                                }
                                const describeReq = {
                                    query: content,
                                    objects: objects,
                                };

                                api.post(
                                    URLS.serverUrl + API.describe,
                                    describeReq,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                            "Analytics-Id": ticketData.id,
                                        },
                                    },
                                )
                                    .then(async (describeData) => {
                                        if (describeData.status === 200) {
                                            const describeResp: DescribeResponse =
                                                describeData.data.data;
                                            updateEditor(describeResp.summary);
                                        }
                                    })
                                    .catch((error) => {
                                        console.error(
                                            "Error fetching AI response:",
                                            error,
                                        );
                                        setAskAIUpdateLoading(false);
                                        toast({
                                            title: "Failed to fetch AI response",
                                            description:
                                                "Please try again later",
                                            variant: "destructive",
                                        });
                                    });
                            }
                        });
                    }
                }
                return ticketData;
            } else {
                setAskAIUpdateLoading(false);
                toast({
                    title: "Failed to fetch ticket",
                    description: "Please try again later",
                    variant: "destructive",
                });
                throw new Error("Failed to fetch ticket");
            }
            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        } catch (error: any) {
            if (error.response?.status === 404) {
                throw new Error("Ticket not found");
            }
            toast({
                title: "Failed to fetch ticket",
                description: "Please try again later",
                variant: "destructive",
            });
            setAskAIUpdateLoading(false);
            throw new Error("Failed to fetch ticket");
        }
    };

    const { isLoading, refetch, isFetching, data } = useQuery<Ticket | null>({
        queryKey: ["tickets", id, team_id],
        queryFn: getAIResponse,
        enabled: false,
    });

    const [askAIUpdateLoading, setAskAIUpdateLoading] = useState(false);

    const updateEditor = useCallback(
        (aiResponse: string) => {
            editor.update(() => {
                const content_used = aiResponse ?? "";
                const root = $getRoot();

                const paragraph = $createParagraphNode();
                const text = $createTextNode(content_used?.trim());
                paragraph.append(text);
                root.append(paragraph);
                root.selectEnd();
                // setKey((prevKey) => prevKey + 1);
                setIsToggled(!isToggled);
                console.log("reached updating after");
                setAskAIUpdateLoading(false);
            });
        },
        [editor, isToggled, setIsToggled],
    );

    return (
        <div>
            <div className={`${className} absolute top-0 right-0 mt-2 mr-1`}>
                <Toggle.Root
                    aria-label="Toggle italic"
                    className="flex items-center justify-center rounded text-base leading-4"
                    onClick={() => refetch()}
                    asChild
                >
                    <div>
                        <ShinyButton
                            text={
                                <div className="flex flex-row gap-2 items-center">
                                    <span>Ask AI</span>
                                    {askAIUpdateLoading ? (
                                        <Loader2Icon
                                            color="#5e6ad2"
                                            className="w-5 h-5 animate-spin"
                                        />
                                    ) : (
                                        <img
                                            src={SparklesIcon}
                                            alt=""
                                            className="h-5 w-5"
                                        />
                                    )}
                                </div>
                            }
                            className={`text-[#5e6ad2] pr-2 pl-2 text-xs outline outline-1 outline-iris8 flex py-1 flex-wrap justify-start data-[state=on]:bg-[#eceefb] ${isToggled ? "bg-[#eceefb]" : "bg-white"}`}
                            disabled={isLoading || isFetching || !!data}
                        />
                    </div>
                </Toggle.Root>
            </div>
        </div>
    );
}
