import { useAuthInfo } from "@propelauth/react";
import axios from "axios";
import type { AxiosError } from "axios";
import { useDialog } from "../DialogContext";
import { URLS } from "../constant";

const api = axios.create({
    baseURL: URLS.serverUrl,
    withCredentials: true,
});

const api_form_data = axios.create({
    baseURL: URLS.serverUrl,
    withCredentials: true,
});

const handleUnauthorizedError = (error: AxiosError, openDialog: () => void) => {
    // reserved for logout error!
    if (error.response && error.response.status === 401) {
        openDialog();
    }
    return Promise.reject(error);
};

// old way of api call (here just in case - 10/20/2024)
// export const useApi = () => {
//     const { openDialog } = useDialog();
//     const authInfo = useAuthInfo();
//     const authInfoRef = useRef(authInfo);

//     useEffect(() => {
//         authInfoRef.current = authInfo;
//     }, [authInfo]);

//     api.interceptors.request.use(
//         (config) => {
//             if (authInfoRef.current.accessToken) {
//                 config.headers.Authorization = `Bearer ${authInfoRef.current.accessToken}`;
//             }
//             config.headers["Content-Type"] = "application/json";
//             return config;
//         },
//         (error) => Promise.reject(error),
//     );

//     api.interceptors.response.use(
//         (response) => response,
//         (error) => handleUnauthorizedError(error, openDialog),
//     );

//     return api;
// };

export const useApiFormData = () => {
    const { openDialog } = useDialog();
    const { tokens } = useAuthInfo();

    api_form_data.interceptors.request.use(
        async (config) => {
            const accessToken = await tokens.getAccessToken();
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            return config;
        },
        (error) => Promise.reject(error),
    );

    api_form_data.interceptors.response.use(
        (response) => response,
        (error) => handleUnauthorizedError(error, openDialog),
    );

    return api_form_data;
};

export const useApi = () => {
    const { openDialog } = useDialog();
    const { tokens } = useAuthInfo();

    api.interceptors.request.use(
        async (config) => {
            const accessToken = await tokens.getAccessToken();
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (error) => Promise.reject(error),
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => handleUnauthorizedError(error, openDialog),
    );

    return api;
};

export const UseApi = () => {
    const { openDialog } = useDialog();
    const { accessToken } = useAuthInfo();

    api.interceptors.request.use(
        (config) => {
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (error) => Promise.reject(error),
    );

    api.interceptors.response.use(
        (response) => response,
        (error) => handleUnauthorizedError(error, openDialog),
    );

    return api;
};
