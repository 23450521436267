import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Category,
    EditTopicPayload,
    GetTopicsResponse,
    Teams,
} from "@/interfaces/serverData";
import { CaretDownIcon, CaretUpIcon, ComponentBooleanIcon, PlusIcon } from "@radix-ui/react-icons";
import { Box } from "@radix-ui/themes";
import { useQuery, } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { BookmarkIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Categories } from "./Categories";
import { CategoriesDialog } from "./CategoriesDialog";
import type { TagsRowState } from "./DataTable/constants";
import { Topics } from "./Topics";
import { TopicsDialog } from "./TopicsDialog";
import { useToast } from "@/component/shadcn/ui/use-toast";

interface InteractionLabelsProps {
    teams: Teams[];
}

export const InteractionLabels: React.FC<InteractionLabelsProps> = ({ teams }) => {
    const { id } = useParams(); // team ID
    const api = useApi()
    const { toast } = useToast();
    const defaultBadgeColor = "#9B9EF0";

    const [currentTeam, setCurrentTeam] = useState<Teams | undefined>();

    const [tagIsOpen, setTagIsOpen] = useState(false);
    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [tagRowState, setTagRowState] = useState<Map<string, TagsRowState>>(
        new Map(),
    );
    const [labelGroupIsOpen, setLabelGroupIsOpen] = useState<
        Map<string, boolean>
    >(
        new Map([
            ["Tags", false],
            ["Interaction Types", false],
        ]),
    );

    const updateLabelGroupIsOpen = (
        updates: Partial<Record<string, boolean>>,
    ) => {
        setLabelGroupIsOpen((prevState) => {
            const newMap = new Map(prevState);
            // biome-ignore lint/complexity/noForEach: <explanation>
            Object.entries(updates).forEach(([key, value]) => newMap.set(key as string, value ?? false));
            return newMap;
        });
    };

    // Fetch categories / interaction type
    const fetchCategories = async (): Promise<Category[]> => {
        const response: AxiosResponse<{ data: Category[] }> = await api.get(
            `${URLS.serverUrl}${API.getCategories}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch categories");
    };
    const fetchTeamCategories = async (): Promise<Category[]> => {
        const response = await api.get(`${URLS.serverUrl}${API.getCategories}/team/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };
    const {
        data: categories = [],
        isLoading,
        isError,
        refetch: refetchCategories,
    } = useQuery<Category[]>({
        queryKey:
            id
                ? [`teamCategories_${id}`]
                : ["categories"],
        queryFn:
            id
                ? () => fetchTeamCategories()
                : () => fetchCategories(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    // Tags Support
    const fetchTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        if (response.status === 200) {
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };
    const fetchTeamTopics = async (): Promise<GetTopicsResponse[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTopics}/team/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        setLoadingState(2);
        return [];
    };
    const { data: topicsData = [], refetch: refetchTopics } = useQuery({
        queryKey: id ? [`teamTopics_${id}`] : ["topics"],
        queryFn: id ? () => fetchTeamTopics() : () => fetchTopics(),
        refetchInterval: 10000, // refetch every 10 secs
        refetchOnWindowFocus: true,
    });

    const tagUpdateRowState = (id: string, newState: Partial<TagsRowState>) => {
        setTagRowState((prevState) => {
            const currentState = prevState.get(id) || {
                id: "",
                colorSelected: "",
                tagSelected: "",
                tag_name: "",
                description: "",
                teams: [],
            };
            return new Map(prevState).set(id, {
                ...currentState,
                ...newState,
            });
        });
    };

    useEffect(() => {
        if (topicsData) {
            const newRowState = new Map<string, TagsRowState>();
            for (let i = 0; i < topicsData.length; i++) {
                const query: GetTopicsResponse = topicsData[i];
                newRowState.set(String(i), {
                    id: query.id,
                    tagSelected: query.topic_name,
                    colorSelected: query.color ?? defaultBadgeColor,
                    tag_name: query.topic_name,
                    description: query.description ?? "",
                    teams: query.teams,
                });
            }
            newRowState.set("dialog", {
                id: "",
                tagSelected: "",
                colorSelected: defaultBadgeColor,
                tag_name: "",
                description: "",
                teams: [],
            });
            setTagRowState(newRowState);
            setLoadingState(1);
        }
    }, [topicsData]);

    const saveTag = (id: string, rowStateInfo: Partial<TagsRowState>) => {
        const requestData: EditTopicPayload = {
            id: id,
        };
        if (rowStateInfo.tag_name) {
            requestData.name = rowStateInfo.tag_name;
        }
        if (rowStateInfo.description) {
            requestData.description = rowStateInfo.description;
        }
        if (rowStateInfo.colorSelected) {
            requestData.color = rowStateInfo.colorSelected;
        }
        if (rowStateInfo.teams) {
            requestData.teams = rowStateInfo.teams
                .filter((team) => team.id !== "workspace")
                .map((team) => team.id);
        }
        api.post(URLS.serverUrl + API.editTopic, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const response: GetTopicsResponse = res.data.data;
                    tagUpdateRowState(response.id, {
                        colorSelected: response.color,
                        tagSelected: response.topic_name,
                        tag_name: response.topic_name,
                        description: response.description,
                        teams: response.teams,
                    });
                    refetchTopics();
                    toast({
                        title: "Updated Tag!",
                        description: "The tag has been updated successfully.",
                    });
                }
            })
            .catch((res) => {
                console.log("failed to edit tag");
                setLoadingState(2);
                toast({
                    title: "Oops! Something's wrong.",
                    description: "Please try again at a later time.",
                })
            });
    };

    useEffect(() => {
        if (id) {
            const team = teams.filter((team) => team.id === id);
            if (team.length === 1) {
                setCurrentTeam(team[0]);
            } else {
                setCurrentTeam(undefined);
            }
        } else {
            setCurrentTeam(undefined);
        }
    }, [id, teams]);

    return (
        <div>
            <Box mt={"3%"}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-md font-semibold ml-3">
                            Interactions Labels
                        </h2>
                    </div>
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button
                                className="shadow-md outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                size="sm"
                                variant="outline"
                            >
                                Add Label
                                <PlusIcon />
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-52 mx-4">
                            <TopicsDialog
                                type={"Create"}
                                triggerElement={<DropdownMenuItem
                                    className="text-xs px-2 py-1.5 hover:bg-muted"
                                    onSelect={(e) => e.preventDefault()}
                                >
                                    Add Tag
                                </DropdownMenuItem>}
                                teams={teams}
                                refetchTopics={refetchTopics}
                                setLoadingState={setLoadingState}
                                saveTag={saveTag}
                                currentTeam={currentTeam} />
                            <CategoriesDialog
                                type={"Create"}
                                triggerElement={
                                    <DropdownMenuItem
                                        className="text-xs px-2 py-1.5 hover:bg-muted"
                                        onSelect={(e) => e.preventDefault()}
                                    >
                                        Add Interaction Type
                                    </DropdownMenuItem>
                                }
                                teams={teams}
                                refetchCategories={refetchCategories}
                                currentTeam={currentTeam}
                            />
                        </DropdownMenuContent>
                    </DropdownMenu>
                </div>
                <div className="space-y-4 my-5 w-full">
                    <Card
                        className={`py-3 px-8 shadow-none w-full flex flex-col gap-3 ${labelGroupIsOpen.get("Tags") && "pb-3"}`}
                    >
                        <div className="font-semibold text-[15px] flex items-center justify-between">
                            <div className="flex items-center gap-1.5">
                                Tags
                                <BookmarkIcon
                                    className="w-4 h-4"
                                />
                            </div>
                            {labelGroupIsOpen.get("Tags") ? (
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="text-xs p-0.5"
                                    onClick={() =>
                                        updateLabelGroupIsOpen({
                                            "Tags": false,
                                        })
                                    }
                                >
                                    <CaretUpIcon className="w-4 h-4" />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="text-xs p-0.5"
                                    onClick={() =>
                                        updateLabelGroupIsOpen({
                                            "Tags": true,
                                        })
                                    }
                                >
                                    <CaretDownIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </div>
                        {labelGroupIsOpen.get("Tags") &&
                            <Topics
                                data={topicsData}
                                teams={teams}
                                rowState={tagRowState}
                                updateRowState={tagUpdateRowState}
                                refetch={refetchTopics}
                                saveTag={saveTag}
                                loadingState={loadingState}
                                setLoadingState={setLoadingState}
                                currentTeam={currentTeam}
                            />}
                    </Card>
                    <Card
                        className={`py-3 px-8 shadow-none w-full flex flex-col gap-3 ${labelGroupIsOpen.get("Interaction Types") && "pb-3"}`}
                    >
                        <div className="font-semibold text-[15px] flex items-center justify-between">
                            <div className="flex items-center gap-1.5">
                                Interaction Types
                                <ComponentBooleanIcon
                                    className="w-4 h-4"
                                />
                            </div>
                            {labelGroupIsOpen.get("Interaction Types") ? (
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="text-xs p-0.5"
                                    onClick={() =>
                                        updateLabelGroupIsOpen({
                                            "Interaction Types": false,
                                        })
                                    }
                                >
                                    <CaretUpIcon className="w-4 h-4" />
                                </Button>
                            ) : (
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="text-xs p-0.5"
                                    onClick={() =>
                                        updateLabelGroupIsOpen({
                                            "Interaction Types": true,
                                        })
                                    }
                                >
                                    <CaretDownIcon className="w-4 h-4" />
                                </Button>
                            )}
                        </div>
                        {labelGroupIsOpen.get("Interaction Types") &&
                            <Categories
                                categories={categories}
                                isLoading={isLoading}
                                isError={isError}
                                refetchCategories={refetchCategories}
                                teams={teams}
                                currentTeam={currentTeam} />}
                    </Card>
                </div>
            </Box>
        </div>
    );
};
