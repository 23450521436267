import type { ResponseTimeResponse } from "@/interfaces/serverData";
import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { ChartData } from "../AdminAnalyticsPage";

interface ResponseAnalyticsProps {
    responseTimePerDay: ChartData[];
    responseChartData: ChartData[];
    responseTimeQueryStatus: string;
    responseTimeQueryIsError: boolean;
    responseTimeQueryData: ResponseTimeResponse | undefined;
}

const ResponseAnalyticsComponent = ({
    responseTimePerDay,
    responseChartData,
    responseTimeQueryStatus,
    responseTimeQueryIsError,
    responseTimeQueryData,
}: ResponseAnalyticsProps) => {
    return (
        <div className="flex flex-col gap-2">
            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Response Time Per Day Average
                    </Text>
                    {responseTimeQueryStatus === "loading" && <SkeletonText />}
                    {responseTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {responseTimeQueryData && responseTimePerDay.length > 0 && (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                data={responseTimePerDay.map((item) => {
                                    return {
                                        ...item,
                                        duration: item.duration / 60, // Convert duration from seconds to minutes
                                    };
                                })}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    type="category"
                                    tickFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                />
                                <YAxis />
                                <Tooltip
                                    labelFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                    formatter={(value: number) => [
                                        `${value.toFixed(2)} minutes`,
                                        "Average Response Time",
                                    ]}
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="duration"
                                    stroke="#8884d8"
                                    name="Average Response Time"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default ResponseAnalyticsComponent;
