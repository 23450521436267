import { Input } from "@/component/shadcn/ui/input";
import { Flex } from "@radix-ui/themes";
import type { Table } from "@tanstack/react-table";
import { DataTableViewOptions } from "./data-table-view-options";

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    placeholder: string;
    column: string;
    input: string;
    setInput: (input: string) => void;
}

export function DataTableToolbarDebounce<TData>({
    table,
    placeholder,
    column,
    input,
    setInput,
}: DataTableToolbarProps<TData>) {
    return (
        <div>
            <Flex direction="row" align="center" gap="2">
                <Input
                    placeholder={placeholder}
                    value={input}
                    onChange={(event) => setInput(event.target.value)}
                    className="h-8 w-[300px] lg:w-[450px] px-2 text-xs"
                />

                <DataTableViewOptions table={table} />
            </Flex>
        </div>
    );
}
