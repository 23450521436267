import type React from "react";
import { useNavigate } from "react-router-dom";
import { AlertDialog, AlertDialogContent, AlertDialogTitle, AlertDialogDescription, AlertDialogAction } from "./shadcn/ui/alert-dialog";
import { Button } from "./shadcn/ui/button";

interface PopupProps {
    isDialogOpen: boolean;
    closeDialog: () => void;
    isAdmin: boolean;
}

const FirstSetupDialog: React.FC<PopupProps> = ({
    isDialogOpen,
    // openDialog,
    closeDialog,
    isAdmin,
}) => {
    const navigate = useNavigate();
    const closeDialogAndRedirect = async () => {
        navigate("/admin/manage_integrations");
    };
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handlePointerDownOutside = (event: any) => {
        event.preventDefault();
    };
    return (
        <AlertDialog
            open={isDialogOpen}
            onOpenChange={(open) => closeDialog()}
        >
            <AlertDialogContent onEscapeKeyDown={handlePointerDownOutside}>
                {isAdmin ? (
                    <div>
                        <AlertDialogTitle>Glad You're Here!</AlertDialogTitle>
                        <AlertDialogDescription>
                            Navigate to <b>Manage Integrations</b> to connect
                            Assembly to any integration you'd like!
                        </AlertDialogDescription>
                        <div className="flex justify-end gap-3 mt-4">
                            <AlertDialogAction>
                                <Button
                                    onClick={closeDialogAndRedirect}
                                    color="iris"
                                >
                                    Let's go!
                                </Button>
                            </AlertDialogAction>
                        </div>
                    </div>
                ) : (
                    <div>
                        <AlertDialogTitle>Welcome!</AlertDialogTitle>
                        <AlertDialogDescription>
                            Please contact Admin to set up an integration with
                            Assembly! If you are an Admin, please contact us at
                            support@askassembly.app or reach out to us via
                            Slack.
                        </AlertDialogDescription>
                    </div>
                )}
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default FirstSetupDialog;
