import {
    Box,
    Button,
    Callout,
    Card,
    Flex,
    Link,
    Text,
    TextField,
} from "@radix-ui/themes";
import { usePostHog } from "posthog-js/react";
import type React from "react";
import { useEffect, useState } from "react";
import {
    API,
    ASSEMBLY_COOKIES,
    URLS,
    login_clients,
    pages,
} from "../../constant";
import { useApi } from "../../interfaces/api";
import NavBarComponent from "../../sharedPages/NavBar";
import { setCookie } from "../../utilities/CookieManagement";

import "../User/LoginStyles.css";

import { GitHubLogoIcon, InfoCircledIcon } from "@radix-ui/react-icons";

const AdminLoginPage = () => {
    const api = useApi();

    const [loading, setLoading] = useState<boolean>(false);

    const [accessCode, setAccessCode] = useState<string>("");

    const [invalidAccessCode, setInvalidAccessCode] = useState<boolean>(false);

    const [loginType, setLoginType] = useState<string>("");

    const posthog = usePostHog();

    const handleAccessCodeChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setAccessCode(event.target.value);
    };

    async function loginGitHubAction() {
        setLoading(true);
        setLoginType(login_clients.GITHUB_CLIENT);
    }

    // async function loginGitLabAction() {
    //     setLoading(true);
    //     setLoginType(login_clients.GITLAB_CLIENT);
    // }

    async function accessCodeLogin() {
        posthog?.capture("early_access_code");

        api.get(URLS.serverUrl + API.getAccessCode, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (accessCode === res.data) {
                setCookie(ASSEMBLY_COOKIES.logged_in, "true");
                setInvalidAccessCode(false);
                handleLogin();
            } else {
                setInvalidAccessCode(true);
            }
        });
    }

    useEffect(() => {
        if (accessCode === "") {
            setInvalidAccessCode(false);
        }
    }, [accessCode]);

    const handleLogin = async () => {
        let loginUrl = "";
        posthog?.capture("clicked_log_in");
        switch (loginType) {
            case login_clients.GITHUB_CLIENT: {
                loginUrl = process.env.REACT_APP_GITHUB_REDIRECT_URL
                    ? process.env.REACT_APP_GITHUB_REDIRECT_URL
                    : "";
                setCookie(ASSEMBLY_COOKIES.admin, "true");
                setCookie(
                    ASSEMBLY_COOKIES.login_client,
                    login_clients.GITHUB_CLIENT,
                );
                break;
            }
            case login_clients.GITLAB_CLIENT: {
                setCookie(
                    ASSEMBLY_COOKIES.login_client,
                    login_clients.GITLAB_CLIENT,
                );
                setCookie(ASSEMBLY_COOKIES.admin, "true");
                const gitlabLogin =
                    "http://gitlab.com/oauth/authorize?client_id=";

                const scopes =
                    "email%20read_user%20read_repository%20read_api%20profile";
                loginUrl = `${gitlabLogin}${process.env.REACT_APP_GITLAB_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GITLAB_CALLBACK_URL}&response_type=code&scope=${scopes}`;
                break;
            }
            default: {
                loginUrl = "";
                break;
            }
        }

        window.location.assign(loginUrl);
    };

    const handleExternalLink = (url: string) => {
        window.open(url, "_blank");
    };
    return (
        <div>
            <NavBarComponent state={pages.login} />
            <div
                style={{
                    minHeight: "85vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "60px",
                    }}
                >
                    <Card>
                        <Flex
                            style={{
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            className="cardCSS"
                            direction="column"
                            gap="4"
                        >
                            <Text
                                size={{
                                    sm: "4",
                                    initial: "6",
                                    md: "6",
                                    xl: "7",
                                }}
                                weight="bold"
                            >
                                Admin Login
                            </Text>
                            <Flex
                                direction="column"
                                gap="0"
                                style={{
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Text
                                    size={{
                                        initial: "3",
                                        sm: "2",
                                        md: "3",
                                        xl: "4",
                                    }}
                                    color="gray"
                                    highContrast
                                >
                                    Start by indexing a repository of your
                                    choice.
                                </Text>
                                <Text
                                    size={{
                                        initial: "3",
                                        sm: "2",
                                        md: "3",
                                        xl: "4",
                                    }}
                                    highContrast
                                >
                                    Search for what your code does and why.
                                </Text>
                            </Flex>

                            {!loading && (
                                <Button
                                    variant="soft"
                                    size={{
                                        initial: "3",
                                        sm: "2",
                                        md: "3",
                                        xl: "4",
                                    }}
                                    onClick={loginGitHubAction}
                                    style={{
                                        paddingRight: "70px",
                                        paddingLeft: "70px",
                                    }}
                                >
                                    <GitHubLogoIcon />
                                    Continue With GitHub
                                </Button>
                            )}

                            {/* {!loading && (
                            <PurpleButton
                                sx={{ textTransform: "none" }}
                                startIcon={<GitLabIcon />}
                                onClick={loginGitLabAction}
                                disabled={loading}
                            >
                                Continue With GitLab
                            </PurpleButton>
                        )} */}
                            {loading && (
                                <div className="flex flex-col justify-center items-center">
                                    <Text
                                        size={{
                                            initial: "2",
                                            sm: "1",
                                            md: "2",
                                            xl: "3",
                                        }}
                                    >
                                        {" "}
                                        Please enter the <b>access code</b>{" "}
                                        provided to you by Assembly to continue.{" "}
                                    </Text>
                                    <Text
                                        size={{
                                            initial: "2",
                                            sm: "1",
                                            md: "2",
                                            xl: "3",
                                        }}
                                    >
                                        <i>
                                            Assembly is currently in early
                                            access and not available publicly
                                            yet.
                                        </i>
                                    </Text>
                                    <Text
                                        size={{
                                            initial: "2",
                                            sm: "1",
                                            md: "2",
                                            xl: "3",
                                        }}
                                        style={{ paddingBottom: "20px" }}
                                    >
                                        <i>
                                            Feel free to contact us for access!
                                        </i>
                                    </Text>
                                    <Flex direction={"row"} gap="3">
                                        <TextField.Root
                                            size="3"
                                            className={
                                                invalidAccessCode
                                                    ? "textfield-error"
                                                    : ""
                                            }
                                            radius="small"
                                            placeholder="Access Code"
                                            value={accessCode}
                                            onChange={handleAccessCodeChange}
                                        ></TextField.Root>

                                        {loginType ===
                                            login_clients.GITHUB_CLIENT && (
                                            <Button
                                                variant="soft"
                                                onClick={accessCodeLogin}
                                                size="3"
                                            >
                                                <GitHubLogoIcon />
                                                Next
                                            </Button>
                                        )}

                                        {/* {loginType ===
                                        login_clients.GITLAB_CLIENT && (
                                        <PurpleButton
                                            sx={{
                                                textTransform: "none",
                                                pb: "1%",
                                                pt: "1%",
                                            }}
                                            startIcon={<GitLabIcon />}
                                            onClick={accessCodeLogin}
                                        >
                                            Next
                                        </PurpleButton>
                                    )} */}
                                    </Flex>
                                </div>
                            )}
                        </Flex>
                    </Card>
                </Box>

                <Callout.Root size="1" variant="outline">
                    <Callout.Icon>
                        <InfoCircledIcon />
                    </Callout.Icon>
                    <Callout.Text>
                        Have questions? Reach out to us at
                        support@askassembly.app or check out our{" "}
                        <Link
                            style={{ paddingLeft: 0.5 }}
                            onClick={() =>
                                handleExternalLink(
                                    "https://www.askassembly.app/",
                                )
                            }
                        >
                            website
                        </Link>
                    </Callout.Text>
                </Callout.Root>
            </div>
        </div>
    );
};

export default AdminLoginPage;
