import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import type React from "react";
import { useEffect, useState } from "react";

import * as ToggleGroup from "@radix-ui/react-toggle-group";

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { ContractType, type CustomerCompany } from "@/interfaces/serverData";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSearch } from "../../SearchContext";

import { TeamsDropdown } from "@/Ticket/TeamsDropdown";
import { useToast } from "@/component/shadcn/ui/use-toast";
import {
    type Account,
    type AccountUpdatePayload,
    type AccountsLabel,
    AccountsLabelsType,
    type GetUserResponse,
    type IndividualCreatePayload,
    type Teams,
} from "@/interfaces/serverData";
import { CompanyDropdown } from "@/reusable_components/dropdowns/CompanyDropdown";
import { ContactDropdown } from "@/reusable_components/dropdowns/ContactDropdown";
import type { UseQueryResult } from "@tanstack/react-query";
import type { AccountsListType } from "./Accounts";
import { AssigneeDropdown } from "./AssigneeDropdown";
import { LabelsDropdown } from "./LabelsDropdown";

const formSchemaCompany = z.object({
    companyName: z
        .string()
        .min(1, "Company Name is required")
        .max(50, "Company Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .regex(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid domain format")
        .optional(),
    metadata: z.string().optional(),
    contract_value: z
        .number()
        .min(0, "Contract value must be greater than or equal to 0")
        .optional(),
    contract_type: z.string().optional(),
    image_url: z.string().optional(),
});

const formSchemaCustomer = z.object({
    companyName: z
        .string()
        .min(1, "Account Name is required")
        .max(50, "Account Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .email("This is not a valid email.")
        .optional(),
    metadata: z.string().optional(),
    contract_value: z
        .number()
        .min(0, "Contract value must be greater than or equal to 0")
        .optional(),
    contract_type: z.string().optional(),
    image_url: z.string().optional(),
});

interface AccountPopupProps {
    saveCompany?: () => void;
    icon?: React.ElementType;
    triggerElement: React.ReactNode;
    editing: boolean;
    type: "Company" | "Customer";
    teamsQuery: UseQueryResult<Teams[], Error>;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    userID: string;
    orgID: string;
    listType: AccountsListType;
    company?: Account | CustomerCompany;
    contacts?: Account[];
    teams?: Teams[];
    labels?: Map<AccountsLabelsType, AccountsLabel | undefined>;
    editingObject?: Account;
    updateData?: () => Promise<void>;
    notEditableFields?: string[];
}

const AccountPopup: React.FC<AccountPopupProps> = ({
    saveCompany = () => { },
    icon,
    triggerElement,
    editing,
    type,
    teamsQuery,
    usersQuery,
    userID,
    orgID,
    listType,
    company: propsCompany,
    contacts: propsContacts,
    teams: propsTeams,
    labels: propsLabels,
    editingObject,
    updateData,
    notEditableFields,
}) => {
    let teamID = undefined;
    if (window.location.pathname.split("/")[1] === "teams") {
        teamID = window.location.pathname.split("/")[2] || "";
    }

    const [contacts, setContacts] = useState<Account[]>(propsContacts ?? []);
    const [assigneeID, setAssigneeID] = useState<string>(
        editingObject?.assignee_user_id ?? "",
    );
    const [selectedTeams, setSelectedTeams] = useState<Teams[]>(
        editingObject?.teams ?? propsTeams ?? [],
    );
    const [selectedLabels, setSelectedLabels] = useState<
        Map<AccountsLabelsType, AccountsLabel | undefined>
    >(
        propsLabels ??
        new Map([
            [AccountsLabelsType.Tier, undefined],
            [AccountsLabelsType.Stage, undefined],
            [AccountsLabelsType.CompanyType, undefined],
        ]),
    );

    const { toast } = useToast();

    const formSchema =
        type === "Customer" ? formSchemaCustomer : formSchemaCompany;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companyName: editingObject?.name ?? "",
            companyDomain: editingObject?.domain ?? editingObject?.email ?? "",
            image_url: editingObject?.image_url ?? "",
            contract_value: editingObject?.contract_value ?? 0,
            contract_type: editingObject?.contract_type ?? "",
        },
    });

    const { reset, handleSubmit, getValues } = form;

    const toggleContact = (contact: Account, existingContacts: Account[], setSelectedContacts: React.Dispatch<React.SetStateAction<Account[]>>) => {
        let newContacts = Array.from(existingContacts);
        // Remove if apart of the current contacts
        if (newContacts.map(contact => contact.id).includes(contact.id)) {
            newContacts = newContacts.filter((t) => t.id !== contact.id);
        } else {
            // Add if it's a new customer that we're adding
            newContacts.push(contact);
        }
        setContacts(newContacts);
        setSelectedContacts(newContacts)
    };

    const toggleLabel = (
        label: AccountsLabel,
        selectedLabels: Map<AccountsLabelsType, AccountsLabel | undefined>,
    ) => {
        const toggle = (labelType: AccountsLabelsType) => {
            const newSelectedLabels = new Map(selectedLabels);
            const currentLabel = newSelectedLabels.get(labelType);
            newSelectedLabels.set(
                labelType,
                currentLabel?.id === label.id ? undefined : label,
            );
            setSelectedLabels(newSelectedLabels);
        };

        switch (label.type) {
            case AccountsLabelsType.Tier:
            case AccountsLabelsType.Stage:
            case AccountsLabelsType.CompanyType:
                toggle(label.type);
                break;
        }
    };

    const toggleAssignee = (
        userInfo: GetUserResponse,
        accountAssignee: string,
    ) => {
        setAssigneeID(userInfo.id);
    };

    const toggleTeam = (team: Teams, accountTeams: Teams[]) => {
        const isExistingTeam = accountTeams.find(
            (t) => t.id === team.id && t.team_name === team.team_name,
        );
        let new_teams: Teams[] = [];
        // Handle General Team
        if (team.id === orgID) {
            // Can't untoggle general team, just needs to click another team to untoggle General
            if (!isExistingTeam) {
                // Remove all existing teams
                new_teams = [];
            }
        } else {
            if (isExistingTeam) {
                new_teams = accountTeams.filter((t) => t.id !== team.id);
            } else {
                new_teams = [team, ...accountTeams];
            }
        }
        setSelectedTeams(new_teams);
    };

    const api = useApi();

    function onSubmit() {
        const values = getValues();
        const requestData: IndividualCreatePayload = {
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url,
            contract_value: values.contract_value,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            tier: selectedLabels.get(AccountsLabelsType.Tier)?.id,
            stage: selectedLabels.get(AccountsLabelsType.Stage)?.id,
            assignee_user_id: assigneeID,
            teams: selectedTeams ?? [],
        };

        if (type === "Customer") {
            requestData.company_id = companyID;
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createCustomer.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Created Customer!",
                            description: "Customer created successfully!",
                        });
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    if (updateData) {
                        updateData();
                    }
                    setOpen(false);
                });
        } else {
            requestData.contacts = contacts;
            requestData.company_type = selectedLabels.get(
                AccountsLabelsType.CompanyType,
            )?.id;
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createCompany.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Created Company!",
                            description: "Company created successfully!",
                        });
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    if (updateData) {
                        updateData();
                    }
                    setOpen(false);
                });
        }
    }

    function onEdit() {
        const values = getValues();
        const requestData: AccountUpdatePayload = {
            id: editingObject?.id ?? "",
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url ?? "",
            contract_value: values.contract_value ?? 0,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            tier: selectedLabels.get(AccountsLabelsType.Tier)?.id ?? "",
            stage: selectedLabels.get(AccountsLabelsType.Stage)?.id ?? "",
            assignee_user_id: assigneeID,
            teams: selectedTeams ?? [],
        };

        if (type === "Customer") {
            requestData.company_id = companyID;

            api.patch(
                `${URLS.serverUrl}${ContactsAPI.updateCustomer.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Customer!",
                            description: "Customer updated successfully!",
                        });
                        if (updateData) {
                            updateData();
                        }
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    setOpen(false);
                });
        } else {
            requestData.contacts = contacts;
            requestData.company_type = selectedLabels.get(
                AccountsLabelsType.CompanyType,
            )?.id;
            api.patch(
                `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        toast({
                            title: "Oops! Something's wrong.",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                    } else {
                        toast({
                            title: "Updated Company!",
                            description: "Company updated successfully!",
                        });
                        if (updateData) {
                            updateData();
                        }
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Oops! Something's wrong.",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                })
                .finally(() => {
                    setOpen(false);
                });
        }
    }

    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const [open, setOpen] = useState<boolean>(false);
    const [filteredCompanies, setFilteredCompanies] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
        },
        {
            label: "Google",
            value: "Google",
            color: "",
        },
        {
            label: "GitHubTicket",
            value: "GitHubTicket",
            color: "",
        },
    ]);

    useEffect(() => {
        if (loading) {
            const updatedCompanies = filteredCompanies
                .filter((broadcast) => searchBarFilters.has(broadcast.value))
                .map((broadcast) => {
                    if (broadcast.value === "Google") {
                        return {
                            ...broadcast,
                            label: "Gmail",
                            value: "Gmail",
                        };
                    }
                    return broadcast;
                });
            setFilteredCompanies(updatedCompanies);
        }
    }, [searchBarFilters, loading]);

    const [accountTitle, setAccountTitle] = useState<string>(
        type === "Company" ? "Add New Company" : "Add New Customer",
    );
    const [accountDesc, setAccountDesc] = useState<string>(
        type === "Company"
            ? "Add a new company account to track."
            : "Add a new customer account to track.",
    );

    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);

    useEffect(() => {
        if (editing) {
            setAccountTitle(
                type === "Company" ? "Edit Company" : "Edit Customer",
            );
            setAccountDesc(
                type === "Company"
                    ? "Edit an existing company account."
                    : "Edit an existing customer account.",
            );
        }
    }, [editing, setAccountTitle]);

    const formItemClassName = "flex flex-col";
    const formItemClassNameDiv = "flex flex-row items-center";
    const formLabelCN = "w-56 pt-1";
    const formLabelError = "text-xs";

    const [companyEditing, setCompanyEditing] = useState<boolean>(false);
    const [contactEditing, setContactEditing] = useState<boolean>(false);

    const [companyID, setCompanyID] = useState<string>(propsCompany?.id ?? "");
    const toggleGroupItemClasses =
        "text-xs flex outline outline-1 outline-gray-300 h-[35px] w-[60px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:bg-iris3";

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="pt-10 max-w-[850px] w-full">
                <div className="ml-10 mr-10">
                    <DialogHeader className="justify-left text-left items-left pb-5">
                        <DialogTitle>{accountTitle}</DialogTitle>
                        <DialogDescription>{accountDesc}</DialogDescription>
                    </DialogHeader>

                    <Form {...form}>
                        <form
                            onSubmit={
                                editing
                                    ? handleSubmit(onEdit)
                                    : handleSubmit(onSubmit)
                            }
                            className="space-y-2 flex flex-col gap-3"
                        >
                            <FormField
                                control={form.control}
                                name="companyName"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                {type === "Company"
                                                    ? "Company Name"
                                                    : "Customer Name"}
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder={
                                                        type === "Company"
                                                            ? "Assembly"
                                                            : "Customer Name"
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="companyDomain"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                {type === "Company"
                                                    ? "Email Domain"
                                                    : "Email"}
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder={
                                                        type === "Company"
                                                            ? "askassembly.app"
                                                            : "account@example.com"
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                control={form.control}
                                name="image_url"
                                render={({ field }) => (
                                    <FormItem className={formItemClassName}>
                                        <div className={formItemClassNameDiv}>
                                            <FormLabel className={formLabelCN}>
                                                Image URL
                                            </FormLabel>

                                            <FormControl className="max-w-lg">
                                                <Input
                                                    placeholder="Image URL"
                                                    {...field}
                                                />
                                            </FormControl>
                                        </div>

                                        <FormMessage
                                            className={formLabelError}
                                        />
                                    </FormItem>
                                )}
                            />

                            <div className="flex flex-row gap-3 items-center">
                                <FormField
                                    control={form.control}
                                    name="contract_value"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel className="w-50 pr-[100px] pt-1">
                                                    Contract
                                                </FormLabel>

                                                <FormControl className="w-50 ml-5">
                                                    <Input
                                                        type="number"
                                                        placeholder="$0"
                                                        {...field}
                                                        value={
                                                            field.value || ""
                                                        }
                                                        onChange={(e) => {
                                                            // If the value is empty, set it as undefined, otherwise parse as a number
                                                            const value =
                                                                e.target.value;
                                                            const numericValue =
                                                                value === ""
                                                                    ? undefined
                                                                    : Number(
                                                                        value,
                                                                    );
                                                            field.onChange(
                                                                numericValue,
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="contract_type"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormControl>
                                                    <div className="flex items-center">
                                                        <ToggleGroup.Root
                                                            className="inline-flex bg-mauve6 rounded space-x-px"
                                                            type="single"
                                                            defaultValue={
                                                                field.value ||
                                                                "Month"
                                                            }
                                                            aria-label="Contract Type"
                                                            onValueChange={(
                                                                value,
                                                            ) => {
                                                                field.onChange(
                                                                    value,
                                                                );
                                                            }}
                                                        >
                                                            <ToggleGroup.Item
                                                                className={`${toggleGroupItemClasses} ${field.value ===
                                                                    "Month" &&
                                                                    "bg-iris3"
                                                                    }`}
                                                                value="Month"
                                                                aria-label="Month"
                                                            >
                                                                Month
                                                            </ToggleGroup.Item>
                                                            <ToggleGroup.Item
                                                                className={`${toggleGroupItemClasses} ${field.value ===
                                                                    "Year" &&
                                                                    "bg-iris3"
                                                                    }`}
                                                                value="Year"
                                                                aria-label="Year"
                                                            >
                                                                Year
                                                            </ToggleGroup.Item>
                                                        </ToggleGroup.Root>
                                                    </div>
                                                </FormControl>
                                            </div>
                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                            </div>

                            <FormItem className={formItemClassName}>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <FormLabel>Labels</FormLabel>
                                    <div className="col-span-3">
                                        <FormControl>
                                            <LabelsDropdown
                                                selectedLabels={selectedLabels}
                                                toggleLabel={toggleLabel}
                                                accountType={type}
                                            />
                                        </FormControl>
                                    </div>
                                </div>

                                <FormMessage className={formLabelError} />
                            </FormItem>

                            {type === "Customer" ? (
                                <FormItem>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                        <FormLabel>Company</FormLabel>
                                        <div className="col-span-2">
                                            <FormControl>
                                                <CompanyDropdown
                                                    teamID={teamID}
                                                    companyID={companyID}
                                                    setCompany={(c: CustomerCompany, index?: number) => {
                                                        setCompanyID(c.id);
                                                    }}
                                                    editing={companyEditing}
                                                    setEditing={
                                                        setCompanyEditing
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </FormItem>
                            ) : (
                                <FormItem>
                                    <div className="grid grid-cols-4 items-center gap-4">
                                        <FormLabel>Contacts</FormLabel>
                                        <div className="col-span-3">
                                            <FormControl>
                                                <ContactDropdown
                                                    selectedContactIDs={contacts.map(contact => contact.id)}
                                                    selectedContacts={contacts}
                                                    teamID={teamID}
                                                    setContact={toggleContact}
                                                    editing={contactEditing}
                                                    setEditing={
                                                        setContactEditing
                                                    }
                                                    condensed={false}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                </FormItem>
                            )}

                            <FormItem>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <FormLabel>Assignee</FormLabel>
                                    <div className="col-span-3 ml-1">
                                        <FormControl>
                                            <AssigneeDropdown
                                                selectedUser={assigneeID}
                                                users={usersQuery.data ?? []}
                                                toggleAssignee={toggleAssignee}
                                                userID={userID}
                                                notEditable={notEditableFields?.includes(
                                                    "Assignee",
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </FormItem>

                            <FormItem>
                                <div className="grid grid-cols-4 items-center gap-4">
                                    <FormLabel>Teams</FormLabel>
                                    <div className="col-span-3 ml-1">
                                        <FormControl>
                                            <TeamsDropdown
                                                selectedTeams={selectedTeams}
                                                teams={teamsQuery.data ?? []}
                                                toggleTeam={toggleTeam}
                                                defaultIsWorkspace={false}
                                                orgID={orgID}
                                                notEditable={notEditableFields?.includes(
                                                    "Teams",
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </FormItem>

                            <div className="pt-4 justify-end text-end items-end">
                                <Button className="bg-iris9" type="submit">
                                    Save
                                </Button>
                            </div>
                            <DialogFooter className="flex flex-col">
                                {validationErr && (
                                    <Alert
                                        variant="destructive"
                                        className="mt-4"
                                    >
                                        <div className="flex flex-row gap-3 items-center">
                                            <ExclamationTriangleIcon className="h-4 w-4" />
                                            <div>
                                                <AlertTitle>Error</AlertTitle>
                                                <AlertDescription>
                                                    {errorMsg}
                                                </AlertDescription>
                                            </div>
                                        </div>
                                    </Alert>
                                )}
                            </DialogFooter>
                        </form>
                    </Form>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AccountPopup;
