import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { Card } from "@/component/shadcn/ui/card";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from "@/component/shadcn/ui/context-menu";
import type { Category, Teams } from "@/interfaces/serverData";
import {
    CaretUpIcon,
    ComponentBooleanIcon,
    LinkBreak1Icon,
    Pencil2Icon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { Badge } from "@radix-ui/themes";
import type { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { HotelIcon, UsersIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { CategoriesDialog } from "./CategoriesDialog";

interface CategoriesProps {
    categories: Category[];
    isLoading: boolean;
    isError: boolean;
    refetchCategories: (options?: RefetchOptions) => Promise<QueryObserverResult<Category[], Error>>;
    teams: Teams[];
    currentTeam?: Teams
}

export const Categories: React.FC<CategoriesProps> = ({ categories, isLoading, isError, refetchCategories, teams, currentTeam }) => {
    const { id } = useParams(); // team ID
    return (
        <div>
            <Box>
                {isLoading && (
                    <Skeleton>
                        <Text>Loading...</Text>
                    </Skeleton>
                )}

                {isError && (
                    <Callout.Root color="red">
                        <Callout.Text>
                            Failed to load categories. Please try again later.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!isLoading && !isError && categories.length === 0 && (
                    <Callout.Root color="blue">
                        <Callout.Text>
                            No categories available. Please add one.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!isLoading && !isError && categories.length > 0 && (
                    <div className="space-y-4 w-full">
                        {categories.map((category) => (
                            <Card
                                key={category.id}
                                className={`p-4 shadow-none w-full flex flex-col gap-2 ${category.name === "Unknown" && category.type === "System" && 'bg-muted'}`}
                            >
                                <ContextMenu>
                                    <ContextMenuTrigger>
                                        <div className="flex justify-between items-center">
                                            <div className="mr-8 flex items-center">
                                                <ComponentBooleanIcon
                                                    color={category.color}
                                                    className="w-5 h-5 mr-3"
                                                />
                                                <div className="flex flex-col gap-0.5">
                                                    {category.name === "Unknown" && category.type === "System" && (
                                                        <div className="text-xs flex items-center gap-1 italic text-[#5B5BD6]">
                                                            Default category cannot be edited nor deleted
                                                        </div>
                                                    )}
                                                    <h3 className="font-semibold text-[13px] flex items-center gap-2">
                                                        {category.name}
                                                        <Badge
                                                            color="gray"
                                                            size="1"
                                                            radius="full"
                                                            variant="soft"
                                                            className="m-0.5 text-[11px]"
                                                        >
                                                            <div className="flex flex-row items-center gap-0.5">
                                                                <CaretUpIcon />
                                                                {category.count}
                                                            </div>
                                                        </Badge>
                                                    </h3>
                                                    <p className="text-xs text-gray-500">
                                                        {category.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-2.5">
                                                {category.teams.length === 0 ? (
                                                    <ScnBadge variant="outline" className="gap-1 py-1 px-1.5 text-[11px] font-normal">
                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                            <HotelIcon
                                                                className="text-blue9"
                                                                strokeWidth={1.5}
                                                                size={10}
                                                            />
                                                        </div>
                                                        Workspace
                                                    </ScnBadge>
                                                ) : (
                                                    category.teams.map(team => (
                                                        <ScnBadge variant="outline" className="gap-1 py-1 px-1.5 text-[11px] font-normal" key={team.id}>
                                                            <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                <UsersIcon
                                                                    className="text-red9"
                                                                    strokeWidth={1.5}
                                                                    size={10}
                                                                />
                                                            </div>
                                                            {team?.team_name}
                                                        </ScnBadge>
                                                    ))
                                                )}
                                            </div>
                                        </div>
                                    </ContextMenuTrigger>
                                    {category.name !== "Unknown" && category.type !== "System" && (
                                        <ContextMenuContent className="w-60">
                                            <CategoriesDialog
                                                type={"Edit"}
                                                triggerElement={
                                                    <ContextMenuItem
                                                        className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-2"
                                                        onSelect={(e) => e.preventDefault()}
                                                    >
                                                        <Pencil2Icon className="w-3.5 h-3.5" />
                                                        Edit
                                                    </ContextMenuItem>
                                                }
                                                teams={teams}
                                                refetchCategories={refetchCategories}
                                                editingObject={category}
                                                currentTeam={currentTeam}
                                            />
                                            {id ? (
                                                category.teams.length === 1 && category.teams[0].id === id ? (
                                                    <CategoriesDialog
                                                        type={"Delete"}
                                                        triggerElement={
                                                            <ContextMenuItem
                                                                onSelect={(e) => e.preventDefault()}
                                                                className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                            >
                                                                <TrashIcon className="w-4 h-4" />
                                                                Delete
                                                            </ContextMenuItem>
                                                        }
                                                        teams={teams}
                                                        refetchCategories={refetchCategories}
                                                        editingObject={category}
                                                        currentTeam={currentTeam}
                                                    />
                                                ) : (
                                                    <CategoriesDialog
                                                        type={"Delete"}
                                                        triggerElement={
                                                            <ContextMenuItem
                                                                onSelect={(e) => e.preventDefault()}
                                                                className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                            >
                                                                <LinkBreak1Icon className="w-3.5 h-3.5" />
                                                                Remove From Team
                                                            </ContextMenuItem>
                                                        }
                                                        teams={teams}
                                                        refetchCategories={refetchCategories}
                                                        editingObject={category}
                                                        currentTeam={currentTeam}
                                                        noDialog={true}
                                                    />
                                                )
                                            ) : (
                                                <CategoriesDialog
                                                    type={"Delete"}
                                                    triggerElement={
                                                        <ContextMenuItem
                                                            onSelect={(e) => e.preventDefault()}
                                                            className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                        >
                                                            <TrashIcon className="w-4 h-4" />
                                                            Delete
                                                        </ContextMenuItem>
                                                    }
                                                    teams={teams}
                                                    refetchCategories={refetchCategories}
                                                    editingObject={category}
                                                    currentTeam={currentTeam}
                                                />
                                            )}
                                        </ContextMenuContent>
                                    )}
                                </ContextMenu>
                            </Card>
                        ))}
                    </div>
                )}
            </Box>
        </div>
    );
};

