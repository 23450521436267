import { useToast } from "@/component/shadcn/ui/use-toast";
import { API, ContactsAPI, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import Nango from "@nangohq/frontend";
import {
    type WithAuthInfoProps,
    useAuthInfo,
    withRequiredAuthInfo,
} from "@propelauth/react";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { ReactComponent as SalesforceSvg } from "../../../images/integrations/salesforce.svg";
import { CRMImportType, HubSpotSchemaImport } from "./HubSpotImportPage";
import IntegrationHeader from "./IntegrationHeader";
export const importForm = z.object({
    company_schema_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_domain: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_image_url: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_contract_value: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_contract_frequency: z.object({
        key: z.string(),
        name: z.string(),
    }),
    company_plan_type: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_schema_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_first_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_last_name: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_email: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_image_url: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_contract_value: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_contract_frequency: z.object({
        key: z.string(),
        name: z.string(),
    }),
    individual_plan_type: z.object({
        key: z.string(),
        name: z.string(),
    }),
});

const NANGO_SALESFORCE_INTEGRATION_ID = "salesforce-sandbox";

export const SalesforceIntegration = withRequiredAuthInfo(
    (props: WithAuthInfoProps) => {
        const navigate = useNavigate();
        const loremIpsum =
            "Lorem ipsum sit, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

        const { user } = useAuthInfo();

        const [integrationEnabled, setIntegrationEnabled] =
            useState<boolean>(false);
        const [error, setError] = useState<string | undefined>(undefined);

        const api = useApi();
        const { toast } = useToast();

        const [loadingState, setLoadingState] = useState<number>(0);

        const connect = () => {
            const requestData = {
                user_id: user?.userId,
                user_email: user?.email,
                allowed_integrations: [NANGO_SALESFORCE_INTEGRATION_ID],
            };
            // first, use search server to generate a NangoSession Token
            api.post(URLS.serverUrl + API.getNangoSessionToken, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((getSessionTokenResult) => {
                    // then, use Nango Auth to connect to user account using OAuth
                    if (getSessionTokenResult.status === 200) {
                        const nango = new Nango({
                            connectSessionToken:
                                getSessionTokenResult.data.data.token,
                        });
                        nango
                            .auth(NANGO_SALESFORCE_INTEGRATION_ID)
                            .then((nangoAuthResult) => {
                                console.log("successfully connected to nango!");
                                const createIntegrationData = {
                                    connection_id: nangoAuthResult.connectionId,
                                    provider_config_key:
                                        nangoAuthResult.providerConfigKey,
                                    integration_type: "Salesforce",
                                };
                                // nango OAuth returns a connection_id. We need to store this connection ID in DB
                                // use this connectionID to create a Nango Integration
                                api.post(
                                    URLS.serverUrl + API.createNangoIntegration,
                                    createIntegrationData,
                                    {
                                        headers: {
                                            "Content-Type": "application/json",
                                        },
                                    },
                                ).then((createIntegrationResult) => {
                                    if (
                                        createIntegrationResult.status === 200
                                    ) {
                                        toast({
                                            title: "Successfully created Salesforce Integration",
                                            description:
                                                "The page will refresh shortly.",
                                        });
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 3000); // 3 seconds and then force refreesh the screen
                                    } else {
                                        toast({
                                            title: "Failed to create Salesforce Integration",
                                            description:
                                                "Please try again at a later time.",
                                            variant: "destructive",
                                        });
                                    }
                                });
                            })
                            .catch((error) => {
                                // Show failure UI.
                                console.log(error);
                                toast({
                                    title: "Failed to initialize Salesforce Integration",
                                    description:
                                        "Please try again at a later time.",
                                    variant: "destructive",
                                });
                            });
                    } else {
                        toast({
                            title: "Failed to initialize Salesforce Integration",
                            description: "Please try again at a later time.",
                            variant: "destructive",
                        });
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    toast({
                        title: "Failed to get Nango Session Token",
                        description: "Please try again at a later time.",
                        variant: "destructive",
                    });
                    setLoadingState(2);
                });
        };

        useEffect(() => {
            const requestData = {
                types: ["Salesforce"],
            };
            api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.data) {
                            const orgInfo: OrgInfoResponse = res.data.data;
                            if (orgInfo.Salesforce) {
                                setIntegrationEnabled(true);
                            }
                            setLoadingState(1);
                        }
                    } else {
                        console.log("failed to get results");
                        setLoadingState(2);
                    }
                })
                .catch((res) => {
                    console.log("failed to get repository");
                    setLoadingState(2);
                });
        }, [api]);

        const [showImport, setShowImport] = useState<boolean>(false);

        const [loadingSavedSchema, setLoadingSavedSchema] = useState(
            loadingTypes.loading,
        );

        type GetCRMSavedSchemaResponse = {
            schema: z.infer<typeof importForm>;
            exists: boolean;
        };

        const savedSchemaQuery = useQuery<GetCRMSavedSchemaResponse>({
            queryKey: ["savedSchema"],
            queryFn: async () => {
                const { url } = ContactsAPI.getSavedSchema;
                const queryParams = new URLSearchParams({
                    integration: "Salesforce",
                    unique_name: "Standard",
                });
                const resp = await api.get(
                    `${URLS.serverUrl}${url}?${queryParams}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (resp.status === 200) {
                    setLoadingSavedSchema(loadingTypes.loaded);
                    console.log(resp.data.data);
                    return resp.data.data;
                } else {
                    setLoadingSavedSchema(loadingTypes.error);
                    return null;
                }
            },
        });

        useEffect(() => {
            if (
                loadingSavedSchema === loadingTypes.loaded &&
                savedSchemaQuery.data?.exists === true
            ) {
                setShowImport(true);
            }
        }, [savedSchemaQuery.data, loadingSavedSchema]);

        return (
            <div>
                <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                    <Flex direction={"column"} align={"start"} gap="6">
                        <IntegrationHeader
                            integrationType="Salesforce"
                            description="Connect your Salesforce account to see customer data in Assembly."
                            SvgIcon={SalesforceSvg}
                        />
                        <Flex className="w-full justify-end">
                            <div className="flex flex-col gap-2">
                                {!integrationEnabled ? (
                                    <Button onClick={connect}>Enable</Button>
                                ) : (
                                    <Button disabled>Enabled</Button>
                                )}
                                {integrationEnabled &&
                                    loadingSavedSchema ===
                                        loadingTypes.loaded &&
                                    savedSchemaQuery.data?.exists === false && (
                                        <Button
                                            onClick={() => setShowImport(true)}
                                        >
                                            Import Salesforce Data into Assembly
                                        </Button>
                                    )}
                            </div>
                        </Flex>
                    </Flex>

                    {loadingState === 0 && (
                        <Skeleton>
                            <Text>
                                {[...Array(2)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Text>
                        </Skeleton>
                    )}

                    {(loadingState === 2 || error) && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}

                    {loadingSavedSchema === loadingTypes.error && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </Box>
                <Box mt={"2%"} ml={"8%"} mr={"8%"}>
                    {showImport && (
                        <HubSpotSchemaImport
                            importData={savedSchemaQuery.data?.schema}
                            exists={savedSchemaQuery.data?.exists ?? false}
                            CRMType={CRMImportType.SALESFORCE}
                        />
                    )}
                </Box>
            </div>
        );
    },
);
