import { Progress } from "@/component/shadcn/ui/progress";
import { Button } from "@radix-ui/themes";
import type React from "react";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MultiSelectSearchableDropdown from "../../component/MultiSelectDropdown";
import { API, ASSEMBLY_COOKIES, URLS, pages } from "../../constant";
import { ErrorChip } from "../../design/Chip";
import { useApi } from "../../interfaces/api";
import type {
    IndexDataRequest,
    ScopeResponse,
} from "../../interfaces/serverData";
import NavBarComponent from "../../sharedPages/NavBar";
import { getCookie } from "../../utilities/CookieManagement";
import {
    type integrationInfo,
    integrations,
    integrationsList,
} from "./constant";

const GitHubIndexingPage = () => {
    const api = useApi();

    const [channels, setChannels] = useState<ScopeResponse[]>([]);

    enum loadingTypes {
        loading = 0,
        loaded = 1,
        error = 2,
    }
    const [loading, setLoading] = useState(loadingTypes.loading);

    const navigate = useNavigate();

    const dropdownStyle: React.CSSProperties = {
        position: "relative", // Establishes a new positioning context
        paddingRight: 15,
        maxHeight: "200px", // Adjust this value based on your item height to fit 5 items
        overflowY: "auto",
    };

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    const [jobList, setJobList] = useState<string[]>([]);
    const [progress, setProgress] = useState<number>(0);

    const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);

    const [isSubmit, setIsSubmit] = useState<boolean>(false);

    const [channelsSelected, setChannelsSelected] = useState<string[]>([
        "Select",
    ]);

    const [integrationType, setIntegrationType] = useState("");

    const initialIntegrationInfo: integrationInfo = useMemo(
        () => ({
            title: "",
            description: "",
            buttonInfo: "",
        }),
        [],
    );

    const [info, setInfo] = useState<integrationInfo>(initialIntegrationInfo);

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        const tempInfo: integrationInfo = {
            title: "",
            description: "",
            buttonInfo: "",
        };

        switch (integrationType) {
            case "code":
                tempInfo.title = "Confirm GitHub Organization";
                tempInfo.description =
                    "Confirm your organization below that you'd like to give us access to.";
                tempInfo.buttonInfo = "Select Organization";
                break;
            case "GitHubTicket":
                tempInfo.title = "Confirm GitHub Organization";
                tempInfo.description =
                    "Confirm your organization below that you'd like to give us access to.";
                tempInfo.buttonInfo = "Select Organization";
                break;
        }
        setInfo(tempInfo);
    }, [integrationType, initialIntegrationInfo]);

    useLayoutEffect(() => {
        const integration = integrationsList.get(window.location.pathname);
        if (integration === undefined) {
            navigate("/*");
        }

        const queryString = window.location.search;

        const i = integration ? integration.integrationType : "";
        setIntegrationType((prev) => i);
        const urlParams = new URLSearchParams(queryString);

        if (integration?.integrationType === integrations.Notion) {
            setIsSubmit(true); // already go straight to indexing page for notion only (since we don't need to select channels)
        } else if (
            integration?.integrationType !== integrations.Code &&
            integration?.integrationType !== integrations.GitHubTicket &&
            integration?.integrationType !== integrations.GithubDiscussion
        ) {
            const stateParam = urlParams.get("state");
            if (stateParam !== integration?.integrationCode) {
                navigate("/*");
            }
        }
        let installationIdParam = "";
        if (integration?.integrationType === integrations.GitHubTicket) {
            installationIdParam = urlParams.get("installation_id") ?? "";
        }

        const codeParam = urlParams.get("code");
        if (codeParam === undefined || codeParam === null || codeParam === "") {
            navigate("/*");
        }

        const cookie = getCookie(ASSEMBLY_COOKIES.jira_url);

        const requestData = {
            type: integration?.integrationType,
            info: {
                code: codeParam,
                url: cookie,
                installationID: installationIdParam,
            },
        };

        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        api.post(serverUrl + API.authenticateIntegration, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const channelData = res.data.data;
                setLoading(loadingTypes.loaded);
                setChannels(channelData);
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                console.log(res);
            });
    }, [navigate]); //only run once

    function handleChannelSelect(channels: string[]) {
        setChannelsSelected(channels);
        if (channels?.length === 0) {
            setIsDisabledButton(true);
        } else {
            setIsDisabledButton(false);
        }
    }

    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    function handleSubmitClick() {
        handleSubmit();
    }

    function handleSubmit(intType?: string) {
        const myIntegrationType = intType ? intType : integrationType;
        const channelRequest: ScopeResponse[] = []; // contains channel ids for back-end
        const newPayload: IndexDataRequest[] = [];

        // biome-ignore lint/complexity/noForEach: <explanation>
        channels.forEach((channel) => {
            let finalizedString = "";
            finalizedString = `${channel.owner}`;
            if (channelsSelected.includes(finalizedString)) {
                channelRequest.push(channel);
                newPayload.push({
                    server: "https://api.github.com/",
                    owner: channel.owner ?? "",
                    ref_name: channel.ref_name ?? "",
                    name: channel.name,
                    is_reindex: false,
                    key: "",
                });
            }
        });

        const requestData = {
            names: newPayload,
            is_reindex: false,
            type: myIntegrationType,
            index_first_time: true,
        };

        api.post(URLS.serverUrl + API.index, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then(async (res) => {
            if (res.status === 200) {
                setIsSubmit(true);

                await new Promise((resolve) => setTimeout(resolve, 4000));
                setStartIndexing(true);
            } else {
            }
        });
        if (myIntegrationType === "GitHubTicket") {
            requestData.type = "GithubDiscussion";
            api.post(URLS.serverUrl + API.index, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then(async (res) => {
                if (res.status === 200) {
                    await new Promise((resolve) => setTimeout(resolve, 4000));
                    setStartIndexing(true);
                }
            });
        }
    }

    const fetchProgress = useCallback(async () => {
        navigateToIntegrations();
    }, [navigateToIntegrations]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.index} />

            {isSubmit ? (
                <div className="min-h-[calc(100vh-85px)] flex items-center justify-center">
                    <div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem]">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <h2 className="text-xl font-extrabold">
                                Please don't close this tab or refresh the page!
                            </h2>
                            <p className="text-lg italic">Assembling...</p>
                            <p className="text-[16px]">
                                This will take a few minutes, about as long as
                                it would take you to grab a cup of coffee!
                            </p>
                            <Progress value={progress} className="w-full" />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="min-h-[80vh] flex items-center flex-col justify-center">
                    <div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem]">
                        <div className="flex flex-col justify-center items-center gap-4">
                            <h2 className="text-2xl font-bold">{info.title}</h2>
                            <p className="text-[16px]">{info.description}</p>

                            <div className="w-full">
                                {loading === loadingTypes.loading && (
                                    <Button
                                        size="3"
                                        className="pl-[150px]"
                                        loading
                                        variant="soft"
                                    />
                                )}
                                {true && (
                                    <MultiSelectSearchableDropdown
                                        dropdownContainerClassName="w-[520px]"
                                        items={channels.map((channel) => ({
                                            label: channel.name,
                                            value: channel.name,
                                            disabled: false,
                                        }))}
                                        onItemClick={handleChannelSelect}
                                    />
                                )}
                                {loading === loadingTypes.error && (
                                    <ErrorChip label="Oops! Looks like something's wrong. Try again, or notify us!" />
                                )}
                            </div>

                            <div className="">
                                <Button
                                    size="3"
                                    onClick={handleSubmitClick}
                                    disabled={isDisabledButton}
                                >
                                    {info.buttonInfo}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GitHubIndexingPage;
