import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { GetTopicsResponse, Insight } from "@/interfaces/serverData";
import { memo, useEffect, useState } from "react";

import { Button } from "@/component/shadcn/ui/button";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { FixedSizeList } from "react-window";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import FilterBar from "./FilterBar";
import { InsightDisplay } from "./InsightDisplay";
import InsightsListCard from "./InsightsListCard";
import { arraysAreEqual } from "@/utilities/methods";
import { Dialog, DialogContent, DialogTrigger } from "@/component/shadcn/ui/dialog";

const areEqual = (
    prevProps: SuggestedInsightsListProps,
    nextProps: SuggestedInsightsListProps,
) => {
    return (
        arraysAreEqual(prevProps.suggestedInsights, nextProps.suggestedInsights) &&
        arraysAreEqual(prevProps.generatedInsights, nextProps.generatedInsights) &&
        prevProps.issuesCount === nextProps.issuesCount &&
        prevProps.userID === nextProps.userID &&
        // Function comparison is causing unnecessary re renders
        // prevProps.handleSaveAIGeneratedInsight ===
        // nextProps.handleSaveAIGeneratedInsight &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.isOpen === nextProps.isOpen &&
        prevProps.setIsOpen === nextProps.setIsOpen &&
        prevProps.playgroundIsOpen === nextProps.playgroundIsOpen &&
        prevProps.teamID === nextProps.teamID
    );
};

interface SuggestedInsightsListProps {
    suggestedInsights: Insight[];
    generatedInsights: Insight[];
    issuesCount: number;
    userID: string;
    handleSaveAIGeneratedInsight: (
        insight: Insight,
        teamID?: string,
    ) => Promise<void>;
    topicsMap: Map<string, GetTopicsResponse>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    playgroundIsOpen: boolean;
    teamID?: string;
}

function SuggestedInsightsList({
    suggestedInsights,
    generatedInsights,
    issuesCount,
    userID,
    handleSaveAIGeneratedInsight,
    topicsMap,
    isOpen,
    setIsOpen,
    playgroundIsOpen,
    teamID,
}: SuggestedInsightsListProps) {
    const itemSize = 65;
    const [filteredInsights, setFilteredInsights] =
        useState<Insight[]>(suggestedInsights);

    const [selectedInsight, setSelectedInsight] = useState<Insight | null>(
        null,
    );
    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );
    // Update the pop up height when the window resizes
    useEffect(() => {
        const handleResize = () => setPopUpHeight(window.innerHeight * 0.9);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleInsightClick = (insight: Insight) => {
        setSelectedInsight(insight);
    };

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
            <Card className="m-2 rounded-sm w-full flex flex-col relative">
                <div className="flex items-center justify-between">
                    <div className="flex flex-col gap-0.5 px-7 py-2.5">
                        <div className="flex items-center gap-1 text-[15px] font-semibold">
                            Suggested AI Insights{" "}
                            <img
                                src={SparklesIcon}
                                alt=""
                                className="h-7 w-7 rounded p-1"
                            />
                        </div>
                        <div className="text-xs text-muted-foreground">
                            The top most recommended AI generated insights based
                            on your recent issues.
                        </div>
                    </div>
                    {isOpen ? (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setIsOpen(false)}
                        >
                            <CaretUpIcon className="w-5 h-5" />
                        </Button>
                    ) : (
                        <Button
                            variant="ghost"
                            className="text-xs px-1 absolute top-2 right-2"
                            onClick={() => setIsOpen(true)}
                        >
                            <CaretDownIcon className="w-5 h-5" />
                        </Button>
                    )}
                </div>
                {isOpen && (
                    <div>
                        <FilterBar
                            insights={suggestedInsights}
                            filteredInsights={filteredInsights}
                            setFilteredInsights={setFilteredInsights}
                            teams={[]}
                            isGeneralTeam={false}
                        />
                    </div>
                )}
                {isOpen &&
                    suggestedInsights.length === 0 &&
                    (issuesCount === 0 ? (
                        <div className="flex items-center gap-2 px-7 pb-3 text-xs rounded-lg w-full">
                            No recent data available, please create issues or
                            index past issues.
                        </div>
                    ) : (
                        <div className="flex items-center gap-2 px-7 pb-3 text-xs rounded-lg w-full">
                            Generating AI Insights{" "}
                            <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                        </div>
                    ))}

                {isOpen && filteredInsights.length > 0 && (
                    <ScrollArea className="h-full w-full">
                        <FixedSizeList
                            width={"100%"}
                            height={
                                playgroundIsOpen
                                    ? generatedInsights.length > 0
                                        ? window.innerHeight * 0.27
                                        : window.innerHeight * 0.45
                                    : window.innerHeight * 0.56
                            }
                            itemCount={filteredInsights.length}
                            itemSize={itemSize}
                        >
                            {({ index, style }) => {
                                const insight = filteredInsights[index];
                                return (
                                    <div style={style} key={insight.id}>
                                        <DialogTrigger
                                            onClick={() =>
                                                handleInsightClick(insight)
                                            }
                                            className="w-full"
                                        >
                                            <div>
                                                <InsightsListCard
                                                    insight={insight}
                                                    userID={userID}
                                                    handleSaveAIGeneratedInsight={
                                                        handleSaveAIGeneratedInsight
                                                    }
                                                    saved={false}
                                                    topicsMap={topicsMap}
                                                    teamID={teamID}
                                                />
                                            </div>
                                        </DialogTrigger>
                                    </div>
                                );
                            }}
                        </FixedSizeList>
                    </ScrollArea>
                )}
                <DialogContent className="max-w-[1200px] max-h-[750px] w-full h-full">
                    {selectedInsight && (
                        <InsightDisplay
                            insight={selectedInsight}
                            userID={userID}
                            topicsMap={topicsMap}
                            insightSelectedIsSaved={false}
                            handleSaveAIGeneratedInsight={
                                handleSaveAIGeneratedInsight
                            }
                            issueCounts={selectedInsight?.issue_counts}
                            trend={selectedInsight?.trend}
                            teamID={teamID}
                            setDialogClose={() => setDialogOpen(false)}
                        />
                    )}
                </DialogContent>
            </Card>
        </Dialog>
    );
}

export default memo(SuggestedInsightsList, areEqual);
