import type { SLAResponse } from "@/interfaces/serverData";
import { Card, Flex, Skeleton, Text } from "@radix-ui/themes";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import type { ChartData } from "../AdminAnalyticsPage";

export interface SLAAnalyticsProps {
    slaTimePerDay: ChartData[];
    slaChartData: ChartData[];
    slaTimeQueryStatus: string;
    slaTimeQueryIsError: boolean;
    slaTimeQueryData: SLAResponse | undefined;
}

const SLAAnalyticsComponent = ({
    slaTimePerDay,
    slaChartData,
    slaTimeQueryStatus,
    slaTimeQueryIsError,
    slaTimeQueryData,
}: SLAAnalyticsProps) => {
    return (
        <div className="flex flex-col gap-2">
            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        SLA Breach Duration Per Day Average
                    </Text>
                    {slaTimeQueryStatus === "loading" && <SkeletonText />}
                    {slaTimeQueryIsError && <Text>Error loading data</Text>}
                    {slaTimeQueryData && slaTimePerDay.length > 0 && (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                data={slaTimePerDay.map((item) => {
                                    return {
                                        ...item,
                                        duration: item.duration / 60, // Convert duration from seconds to minutes
                                    };
                                })}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    type="category"
                                    tickFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                />
                                <YAxis />
                                <Tooltip
                                    labelFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                    formatter={(value: number) => [
                                        `${value.toFixed(2)} minutes`,
                                        "Average SLA Breach Duration",
                                    ]}
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="duration"
                                    stroke="#8884d8"
                                    name="Average SLA Time"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default SLAAnalyticsComponent;
