import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API, URLS, pages } from "../../../constant";
import { useApi } from "../../../interfaces/api";
import NavBarComponent from "../../../sharedPages/NavBar";
import { integrationsList } from "../constant";

import type { ScopeResponse } from "../../../interfaces/serverData";

import { Progress } from "@/component/shadcn/ui/progress";
import { useLocation } from "react-router-dom";

const PRIndexingPage = () => {
    const navigate = useNavigate();
    const api = useApi();

    const navigateToIntegrations = useCallback(async () => {
        navigate("/admin/manage_integrations", { replace: true });
    }, [navigate]);

    const location = useLocation();
    const [progress, setProgress] = useState<number>(10);

    const [startIndexing, setStartIndexing] = useState<boolean>(false);

    const [jobList, setJobList] = useState<string[]>([]);

    useEffect(() => {
        const integration = integrationsList.get(window.location.pathname);

        if (integration === undefined) {
            navigate("/*");
        }

        if (integration?.integrationType === "PullRequest") {
            const requestData = {
                type: integration?.integrationType,
            };

            api.post(URLS.serverUrl + API.indexIntegration, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then(async (res) => {
                    if (res.status === 200) {
                        if (
                            res.data !== undefined &&
                            res.data.data !== undefined
                        ) {
                            const jobIdStatuses: Map<string, string> = new Map(
                                Object.entries(res.data.data),
                            );

                            setJobList(Array.from(jobIdStatuses.values()));
                        }

                        await new Promise((resolve) =>
                            setTimeout(resolve, 8000),
                        );
                        setStartIndexing(true);
                    } else {
                        await new Promise((resolve) =>
                            setTimeout(resolve, 8000),
                        );
                        setStartIndexing(true);
                    }
                })
                .catch(async (res) => {
                    // in case it fails, still just go to search page
                    await new Promise((resolve) => setTimeout(resolve, 40000));
                    setStartIndexing(true);
                });
        } else if (integration?.integrationType === "Web") {
            const searchParams = new URLSearchParams(location.search);

            const integrationType = searchParams.get("integration"); // mintlify, etc.
            const url = searchParams.get("url");
            const newlyAdded = searchParams.get("add");
            const requestData = {
                type: integration?.integrationType,
                info: {
                    code: integrationType,
                    url: url,
                },
            };
            api.post(
                URLS.serverUrl + API.authenticateIntegration,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    const channelRequest: ScopeResponse[] = [];

                    channelRequest.push({
                        key: integrationType!,
                        name: url!,
                    });
                    let addNewScopes = false;
                    if (newlyAdded === "true") {
                        addNewScopes = true;
                    }
                    const requestData = {
                        type: integration?.integrationType,
                        info: {
                            scopes: channelRequest,
                        },
                        add_new_scopes: addNewScopes,
                    };
                    api.post(
                        URLS.serverUrl + API.indexIntegration,
                        requestData,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then(async (res) => {
                            if (res.status === 200) {
                                if (
                                    res.data !== undefined &&
                                    res.data.data !== undefined
                                ) {
                                    const jobIdStatuses: Map<string, string> =
                                        new Map(Object.entries(res.data.data));

                                    setJobList(
                                        Array.from(jobIdStatuses.values()),
                                    );
                                }

                                await new Promise((resolve) =>
                                    setTimeout(resolve, 8000),
                                );
                                setStartIndexing(true);
                            } else {
                                await new Promise((resolve) =>
                                    setTimeout(resolve, 8000),
                                );
                                setStartIndexing(true);
                            }
                        })
                        .catch(async (res) => {
                            // in case it fails, still just go to search page
                            await new Promise((resolve) =>
                                setTimeout(resolve, 10000),
                            );
                            setStartIndexing(true);
                        });
                })
                .catch((res) => {
                    console.log(res);
                });
        } else {
            navigate("/*");
        }
    }, [navigate, location.search]);

    const fetchProgress = useCallback(async () => {
        if (!startIndexing) {
            return;
        }

        setProgress((prev) => Math.min(prev + 10, 95));
        if (progress >= 95) {
            setProgress(100);
            navigateToIntegrations();
        } else {
            setTimeout(fetchProgress, 1000);
        }
    }, [startIndexing, navigateToIntegrations, api]);

    useEffect(() => {
        if (startIndexing) {
            fetchProgress();
        }
    }, [startIndexing, fetchProgress]);

    return (
        <div>
            <NavBarComponent state={pages.search} />
            <div className="min-h-[80vh] flex items-center flex-col justify-center">
                <div className="bg-white border border-gray-200 rounded-lg p-4 max-w-[60rem]">
                    <div className="flex flex-col gap-4">
                        <h2 className="text-xl font-extrabold">
                            Please don't close this tab or refresh the page!
                        </h2>
                        <p className="text-lg italic">Assembling...</p>
                        <p className="text-[16px]">
                            This will take a few minutes, about as long as it
                            would take you to grab a cup of coffee!
                        </p>
                        <Progress value={progress} className="w-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PRIndexingPage;
