import DialogTriggerButton from "@/component/DialogTriggerButton";
import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import {
    type BusinessHours,
    BusinessHoursType,
    type GetBusinessHoursPayload,
    type SaveBusinessHoursPayload,
} from "@/interfaces/serverData";
import { zodResolver } from "@hookform/resolvers/zod";
import {
    CaretDownIcon,
    CheckIcon,
    ClockIcon,
    Cross1Icon,
    Pencil2Icon,
    PlusCircledIcon,
    PlusIcon,
    StarIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Callout, Skeleton, Switch, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

interface BusinessHoursProps {
    type: BusinessHoursType;
    id: string;
}

function BusinessHoursComponent({ type, id }: BusinessHoursProps) {
    const api = useApi();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";
    let additionalMessage: string;
    switch (type) {
        case BusinessHoursType.User:
            additionalMessage =
                "If there are no business hours for the user, the business hours of the team that the ticket falls under will be used. If the ticket is a part of more than one team, the organization's business hours will be used.";
            break;
        case BusinessHoursType.Team:
            additionalMessage =
                "If there are no business hours for the team, the organization's business hours will be used.";
            break;
        case BusinessHoursType.Org:
            additionalMessage =
                "If there are no business hours for the organization, the default 24 hours / 7 days a week will be used.";
            break;
        default:
            additionalMessage =
                "If there are no business hours, the default 24 hours / 7 days a week will be used.";
            break;
    }

    const { data: businessHours, refetch } = useQuery({
        queryKey: ["business_hours", type, id],
        queryFn: () => getBusinessHours(),
        refetchInterval: 60000, // refetch every 60 secs
        refetchOnWindowFocus: true,
    });

    // Define the Zod schema for validation
    const TimeRangeSchema = z
        .object({
            timeFrom: z.string().min(1, { message: "Start time is required." }),
            timeTo: z.string().min(1, { message: "End time is required." }),
        })
        .refine(
            (data) => {
                const { timeFrom, timeTo } = data;
                return (
                    new Date(`1970-01-01T${timeTo}:00`) >
                    new Date(`1970-01-01T${timeFrom}:00`)
                );
            },
            {
                message: "End time must be later than start time.",
                path: ["timeTo"], // This is where the error will show
            },
        );

    // Define the Day schema with conditional validation for timeRanges based on toggleOn
    const DaySchema = z
        .object({
            day: z.string(),
            toggleOn: z.boolean(),
            timeRanges: z.array(TimeRangeSchema),
        })
        .superRefine((data, ctx) => {
            const { toggleOn, timeRanges } = data;
            // Check if toggleOn is true and timeRanges is empty
            if (
                toggleOn &&
                (timeRanges.length === 0 ||
                    timeRanges.some((tr) => !tr.timeFrom || !tr.timeTo))
            ) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message:
                        "At least one valid time range is required when toggleOn is true.",
                    path: ["timeRanges"],
                });
            }

            // Check for overlapping time ranges
            for (let i = 0; i < timeRanges.length; i++) {
                const { timeFrom: startA, timeTo: endA } = timeRanges[i];
                for (let j = i + 1; j < timeRanges.length; j++) {
                    const { timeFrom: startB, timeTo: endB } = timeRanges[j];

                    // Check if time ranges overlap
                    if (
                        startA < endB &&
                        endA > startB // If A starts before B ends and A ends after B starts
                    ) {
                        ctx.addIssue({
                            code: z.ZodIssueCode.custom,
                            message: `Time ranges cannot overlap: [${startA} - ${endA}] overlaps with [${startB} - ${endB}]`,
                            path: ["day"],
                        });
                    }
                }
            }
        });

    // Define the Form schema with days
    const FormSchema = z.object({
        timezone: z.string().min(1, { message: "Timezone is required." }),
        days: z
            .array(DaySchema)
            .nonempty({ message: "At least one day is required." }), // Ensure at least one day is provided
    });

    // Usage in your form
    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    const form = useForm<z.infer<typeof FormSchema>>({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            days: days.map((day) => ({
                day,
                toggleOn: !(day === "Saturday" || day === "Sunday"),
                timeRanges: !(day === "Saturday" || day === "Sunday")
                    ? [{ timeFrom: "", timeTo: "" }]
                    : [],
            })),
        },
    });

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        formState: { isSubmitting, isSubmitSuccessful },
    } = form;

    const { fields: dayFields } = useFieldArray({
        control,
        name: "days",
    });

    const onSubmit = (data: z.infer<typeof FormSchema>) => {
        let requestData: SaveBusinessHoursPayload;
        if (businessHours) {
            // Updating the business hours
            requestData = {
                id: businessHours.id,
                operation: "EDIT",
                timezone: data.timezone,
                times: data.days,
                excluded_Dates: [],
            };
        } else {
            // Creating new business hours
            requestData = {
                operation: "CREATE",
                timezone: data.timezone,
                times: data.days,
                type: type,
                type_id: id,
                excluded_Dates: [],
            };
        }

        api.post(URLS.serverUrl + API.saveBusinessHours, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                reset();
                refetch();
                setIsEditing(false);
                console.log(
                    `Created business hours ${res.data.data.id} successfully`,
                );
            } else {
                console.log("Call to create business hours failed");
            }
        });
    };

    function deleteBH(id: string) {
        const requestData: SaveBusinessHoursPayload = {
            operation: "DELETE",
            id: id,
        };
        api.post(URLS.serverUrl + API.saveBusinessHours, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                refetch();
                setIsEditing(false);
                console.log(
                    `Deleted business hours ${res.data.data.id} successfully`,
                );
            } else {
                console.log("Call to delete business hours failed");
            }
        });
    }

    const timezones = Intl.supportedValuesOf("timeZone").map((tz) => ({
        value: tz,
        label: tz.replace("_", " "), // Format for display, replace underscores with spaces
    }));
    const [tzSearch, setTzSearch] = useState(""); // State for search input

    const filteredTimezones = timezones.filter(
        (timezone) =>
            timezone.label.toLowerCase().includes(tzSearch.toLowerCase()) ||
            timezone.value.toLowerCase().includes(tzSearch.toLowerCase()),
    );

    useEffect(() => {
        if (businessHours && !isEditing) {
            businessHours.times?.forEach((day, dayIndex) => {
                form.setValue(`days.${dayIndex}.toggleOn`, day.toggleOn);
                form.setValue(`days.${dayIndex}.timeRanges`, day.timeRanges);
            });
            form.setValue("timezone", businessHours.timezone);
        }
    }, [businessHours, form, isEditing]);
    const formTz = watch("timezone");

    const getBusinessHours = async (): Promise<BusinessHours | null> => {
        const requestData: GetBusinessHoursPayload = {
            type: type,
            type_id: id,
        };
        const response = await api.post(
            URLS.serverUrl + API.getBusinessHours,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );
        setLoadingState(loadingTypes.loaded);
        if (response.status === 200 && response.data.data) {
            setLoadingState(loadingTypes.loaded);
            const businessHours: BusinessHours = response.data.data;
            return businessHours;
        } else {
            return null;
        }
    };

    return (
        <Card className="w-full hover:shadow-lg hover:outline-0.5 hover:outline-offset-0">
            <CardContent className="p-6">
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === loadingTypes.loaded &&
                    (!businessHours && !isEditing ? (
                        <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2 text-md font-semibold">
                                    Business Hours
                                    <ClockIcon className="w-5 h-5" />
                                </div>
                                <DialogTriggerButton
                                    onClick={() => setIsEditing(!isEditing)}
                                >
                                    Add Hours
                                    <PlusIcon className="w-4 h-4" />
                                </DialogTriggerButton>
                            </div>
                            <div className="text-sm text-gray11">
                                These working hours will be used for calculating
                                SLAs. {additionalMessage}
                            </div>
                        </div>
                    ) : (
                        <FormProvider {...form}>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="flex flex-col gap-4"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-2 text-md font-semibold">
                                        Business Hours
                                        <ClockIcon className="w-5 h-5" />
                                    </div>
                                    <div className="flex items-center gap-2.5">
                                        {businessHours && !isEditing && (
                                            <DialogTriggerButton onClick={() =>
                                                deleteBH(businessHours.id)
                                            }>
                                                Delete
                                                <TrashIcon className="w-4 h-4" />
                                            </DialogTriggerButton>
                                        )}
                                        <DialogTriggerButton
                                            onClick={() =>
                                                setIsEditing(!isEditing)
                                            }
                                        >
                                            {isEditing
                                                ? businessHours
                                                    ? "Discard Edits"
                                                    : "Cancel"
                                                : "Edit"}
                                            <Pencil2Icon className="w-4 h-4" />
                                        </DialogTriggerButton>
                                        {isEditing && (
                                            <Button
                                                type="submit"
                                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm"
                                                variant="outline"
                                                size="sm"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting
                                                    ? "Submitting..."
                                                    : isSubmitSuccessful
                                                        ? "Submitted!"
                                                        : "Save"}
                                                <StarIcon className="w-4 h-4" />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <FormField
                                    control={form.control}
                                    name="timezone"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <div className="flex flex-row items-center gap-2">
                                                    <FormLabel>
                                                        Timezone
                                                    </FormLabel>
                                                    {businessHours &&
                                                        !isEditing ? (
                                                        <Button
                                                            variant="outline"
                                                            className="flex items-center gap-1 px-1.5"
                                                            disabled
                                                        >
                                                            {formTz}
                                                        </Button>
                                                    ) : (
                                                        <DropdownMenu>
                                                            <DropdownMenuTrigger
                                                                asChild
                                                            >
                                                                <Button
                                                                    variant="outline"
                                                                    className="flex items-center gap-1 px-1.5"
                                                                >
                                                                    {formTz ||
                                                                        "Select Timezone"}
                                                                    <CaretDownIcon />
                                                                </Button>
                                                            </DropdownMenuTrigger>
                                                            <DropdownMenuContent className="max-h-70 w-45">
                                                                <Command className="rounded-md shadow-md text-xs pb-1">
                                                                    <CommandInput
                                                                        placeholder="Filter timezones"
                                                                        onValueChange={
                                                                            setTzSearch
                                                                        }
                                                                        className="text-sm"
                                                                    />
                                                                    <CommandList className="space-y-1">
                                                                        <CommandEmpty className="px-4 py-2 text-sm">
                                                                            No
                                                                            timezones
                                                                            found
                                                                        </CommandEmpty>
                                                                        {filteredTimezones.map(
                                                                            (
                                                                                timezone,
                                                                            ) => (
                                                                                <CommandItem
                                                                                    key={
                                                                                        timezone.value
                                                                                    }
                                                                                    onSelect={() => {
                                                                                        field.onChange(
                                                                                            timezone.value,
                                                                                        );
                                                                                        setTzSearch(
                                                                                            "",
                                                                                        );
                                                                                    }}
                                                                                    className="px-3 py-1.5"
                                                                                >
                                                                                    <div className="flex items-center gap-2">
                                                                                        {
                                                                                            timezone.label
                                                                                        }
                                                                                        {timezone.value ===
                                                                                            formTz && (
                                                                                                <CheckIcon className="h-4 w-4" />
                                                                                            )}
                                                                                    </div>
                                                                                </CommandItem>
                                                                            ),
                                                                        )}
                                                                    </CommandList>
                                                                </Command>
                                                            </DropdownMenuContent>
                                                        </DropdownMenu>
                                                    )}
                                                </div>
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                {/* biome-ignore lint/complexity/useOptionalChain: <explanation> */}
                                {(businessHours && businessHours.times
                                    ? businessHours.times
                                    : dayFields
                                ).map((day, dayIndex) => {
                                    const toggleOn = watch(
                                        `days.${dayIndex}.toggleOn`,
                                    );
                                    const timeRanges = watch(
                                        `days.${dayIndex}.timeRanges`,
                                    );
                                    return (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <div
                                            key={dayIndex}
                                            className="flex justify-between items-center"
                                        >
                                            <div className="flex items-center gap-2 mr-7">
                                                <FormField
                                                    control={form.control}
                                                    name={`days.${dayIndex}.toggleOn`}
                                                    render={({ field }) => (
                                                        <Switch
                                                            checked={toggleOn}
                                                            onCheckedChange={(
                                                                checked,
                                                            ) => {
                                                                if (isEditing) {
                                                                    // Allow changes only if editing
                                                                    field.onChange(
                                                                        checked,
                                                                    );
                                                                    if (
                                                                        checked
                                                                    ) {
                                                                        const emptyTimeRange =
                                                                        {
                                                                            timeFrom:
                                                                                "",
                                                                            timeTo: "",
                                                                        };
                                                                        const currentTimeRanges =
                                                                            form.getValues(
                                                                                `days.${dayIndex}.timeRanges`,
                                                                            ) ||
                                                                            [];
                                                                        if (
                                                                            currentTimeRanges.length ===
                                                                            0
                                                                        ) {
                                                                            form.setValue(
                                                                                `days.${dayIndex}.timeRanges`,
                                                                                [
                                                                                    emptyTimeRange,
                                                                                ],
                                                                            );
                                                                        }
                                                                    } else {
                                                                        form.setValue(
                                                                            `days.${dayIndex}.timeRanges`,
                                                                            [],
                                                                        );
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name={`days.${dayIndex}.day`}
                                                    render={({ field }) => (
                                                        <FormItem>
                                                            <FormControl>
                                                                <FormLabel>
                                                                    {day.day}
                                                                </FormLabel>
                                                            </FormControl>
                                                            <FormMessage />
                                                        </FormItem>
                                                    )}
                                                />
                                                {toggleOn &&
                                                    !(
                                                        businessHours &&
                                                        !isEditing
                                                    ) && (
                                                        <Button
                                                            type="button"
                                                            variant="ghost"
                                                            onClick={() => {
                                                                const currentTimeRanges =
                                                                    getValues(
                                                                        `days.${dayIndex}.timeRanges`,
                                                                    ) || [];
                                                                const newTimeRanges =
                                                                    [
                                                                        ...currentTimeRanges,
                                                                        {
                                                                            timeFrom:
                                                                                "",
                                                                            timeTo: "",
                                                                        },
                                                                    ];
                                                                setValue(
                                                                    `days.${dayIndex}.timeRanges`,
                                                                    newTimeRanges,
                                                                );
                                                            }}
                                                            className="p-0"
                                                        >
                                                            <PlusCircledIcon className="w-3.5 h-3.5" />
                                                        </Button>
                                                    )}
                                            </div>
                                            <div className="flex flex-col gap-2">
                                                {toggleOn &&
                                                    timeRanges.map(
                                                        (
                                                            timeRange,
                                                            timeIndex,
                                                        ) => (
                                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                            <div
                                                                key={timeIndex}
                                                                className="flex items-center gap-3"
                                                            >
                                                                <FormField
                                                                    control={
                                                                        form.control
                                                                    }
                                                                    name={`days.${dayIndex}.timeRanges.${timeIndex}.timeFrom`}
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <FormItem>
                                                                            <FormControl>
                                                                                <Input
                                                                                    type="time"
                                                                                    {...field}
                                                                                    value={
                                                                                        timeRange.timeFrom
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        field.onChange(
                                                                                            e,
                                                                                        );
                                                                                    }}
                                                                                    className="w-[125px]"
                                                                                    readOnly={
                                                                                        !isEditing
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormMessage />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                <div>to</div>
                                                                <FormField
                                                                    control={
                                                                        form.control
                                                                    }
                                                                    name={`days.${dayIndex}.timeRanges.${timeIndex}.timeTo`}
                                                                    render={({
                                                                        field,
                                                                    }) => (
                                                                        <FormItem>
                                                                            <FormControl>
                                                                                <Input
                                                                                    type="time"
                                                                                    {...field}
                                                                                    value={
                                                                                        timeRange.timeTo
                                                                                    }
                                                                                    onChange={(
                                                                                        e,
                                                                                    ) => {
                                                                                        field.onChange(
                                                                                            e,
                                                                                        );
                                                                                    }}
                                                                                    className="w-[125px]"
                                                                                    readOnly={
                                                                                        !isEditing
                                                                                    }
                                                                                />
                                                                            </FormControl>
                                                                            <FormMessage />
                                                                        </FormItem>
                                                                    )}
                                                                />
                                                                {!(
                                                                    businessHours &&
                                                                    !isEditing
                                                                ) && (
                                                                        <Button
                                                                            type="button"
                                                                            variant="ghost"
                                                                            onClick={() => {
                                                                                const currentTimeRanges =
                                                                                    getValues(
                                                                                        `days.${dayIndex}.timeRanges`,
                                                                                    ) ||
                                                                                    [];
                                                                                const newTimeRanges =
                                                                                    currentTimeRanges.filter(
                                                                                        (
                                                                                            _,
                                                                                            index,
                                                                                        ) =>
                                                                                            index !==
                                                                                            timeIndex,
                                                                                    );
                                                                                setValue(
                                                                                    `days.${dayIndex}.timeRanges`,
                                                                                    newTimeRanges,
                                                                                );
                                                                            }}
                                                                            className="p-0"
                                                                        >
                                                                            <Cross1Icon className="w-3 h-3" />
                                                                        </Button>
                                                                    )}
                                                            </div>
                                                        ),
                                                    )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </form>
                        </FormProvider>
                    ))}

                {/* {err?.days?.[0]?.timeRanges && (
                    <span className="text-red-500">{err.days[0].timeRanges.message}</span>
                )} */}
            </CardContent>
        </Card>
    );
}

export default BusinessHoursComponent;
