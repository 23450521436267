import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
  } from "@/component/shadcn/ui/toast"
  import { useToast } from "@/component/shadcn/ui/use-toast"
  import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons"
  
const AssemblyToastProvider = () => {
    const { toasts } = useToast()
  
    return (
      <ToastProvider>
        {toasts.map(({ id, title, description, action, variant, ...props }) => (
            <Toast key={id} {...props}>
              <div className="flex flex-row gap-2">
                <div className="mt-1">
                  {variant === "destructive" ? <CrossCircledIcon color="red" /> : <CheckCircledIcon color="green" />}
                </div>
                <div className="grid gap-1">
                  {title && <ToastTitle>{title}</ToastTitle>}
                  {description && (
                    <ToastDescription>{description}</ToastDescription>
                  )}
                </div>
              </div>
              {action}
              <ToastClose />
            </Toast>
          ))}
        <ToastViewport />
      </ToastProvider>
    )
  }
  
export default AssemblyToastProvider;