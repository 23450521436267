import DialogTriggerButton from "@/component/DialogTriggerButton";
import { Badge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import { API, TeamsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Teams, Template } from "@/interfaces/serverData";
import { cleanText, formatEmojis, renderVariables } from "@/utilities/methods";
import { useAuthInfo } from "@propelauth/react";
import {
    CaretDownIcon,
    CaretUpIcon,
    LinkBreak1Icon,
    Pencil2Icon,
    PlusIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import { Box, Callout, Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import { HotelIcon, UsersIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import Markdown from "react-markdown";
import { useParams } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkEmoji from "remark-emoji";
import remarkGfm from "remark-gfm";
import { workspace } from "./DataTable/constants";
import { TemplatesDialog } from "./TemplatesDialog";
import { Editor } from "./TemplatesEditor";
import { templatesInitialConfig } from "./constants";
import { LexicalComposer } from "@lexical/react/LexicalComposer";

const TemplatesPage = () => {
    const { id } = useParams(); // team ID
    const api = useApi();
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const [isOpen, setIsOpen] = useState<Map<string, boolean>>(new Map());
    const [previewText, setPreviewText] = useState<Map<string, string>>(
        new Map(),
    );

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listMemberTeams;
            const response = await fetch(
                `${URLS.serverUrl}${url}/${authInfo.user?.userId}`,
                {
                    method: method,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                    },
                },
            );
            const d = await response.json();
            return d.data;
        },
    });

    const [teams, setTeams] = useState<Teams[]>([]);
    useEffect(() => {
        // Check if teamsQuery.data is an array and filter accordingly
        const teamsData = teamsQuery.data || [];
        setTeams([
            workspace,
            ...teamsData.filter((team) => team.team_name !== "General"),
        ]);
    }, [teamsQuery.data, workspace]);

    const [currentTeam, setCurrentTeam] = useState<Teams | undefined>();
    useEffect(() => {
        if (id) {
            const team = teams.filter((team) => team.id === id);
            if (team.length === 1) {
                setCurrentTeam(team[0]);
            } else {
                setCurrentTeam(undefined);
            }
        } else {
            setCurrentTeam(undefined);
        }
    }, [id, teams]);

    const fetchTemplates = async (): Promise<Template[]> => {
        const response: AxiosResponse<{ data: Template[] }> = await api.get(
            `${URLS.serverUrl}${API.getTemplates}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        throw new Error("Failed to fetch data");
    };
    const fetchTeamTemplates = async (): Promise<Template[]> => {
        const response = await api.get(
            `${URLS.serverUrl}${API.getTemplates}/team/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            },
        );
        if (response.status === 200) {
            return response.data.data;
        }
        return [];
    };
    const {
        data = [],
        isLoading,
        isError,
        refetch,
    } = useQuery<Template[]>({
        queryKey: id ? [`teamTemplates_${id}`] : ["templates"],
        queryFn: id ? () => fetchTeamTemplates() : () => fetchTemplates(),
    });

    useEffect(() => {
        const newIsOpen: Map<string, boolean> = new Map(isOpen);
        data.map((template) => {
            if (!newIsOpen.has(template.id)) {
                newIsOpen.set(template.id, false);
            }
        });
        setIsOpen(newIsOpen);
    }, [data]);
    const updateIsOpen = (template: Template) => {
        setIsOpen((prevState) => {
            const newMap = new Map(prevState);
            const currentValue = newMap.get(template.id);
            newMap.set(template.id, !currentValue);
            return newMap;
        });
    };

    const containerRef = useRef<HTMLDivElement | null>(null);
    const truncateTextToFit = (template: Template) => {
        // Clean and format the text before truncating
        console.log("original template", template.template);
        const formattedText = cleanText(formatEmojis(template.template));
        console.log("fomratted text", formattedText);
        if (containerRef.current) {
            const containerWidth = containerRef.current.offsetWidth * 0.97;
            // Create a span to measure the width of the full text without rendering it in the DOM
            const tempSpan = document.createElement("span");
            tempSpan.style.visibility = "hidden";
            tempSpan.style.whiteSpace = "nowrap";
            document.body.appendChild(tempSpan);

            let currentText = "";
            const words = formattedText.split(" ");
            let index = 0;

            // Measure word by word until the text exceeds the container width
            while (index < words.length) {
                currentText += `${words[index]} `;
                tempSpan.textContent = currentText.trim();
                if (tempSpan.offsetWidth > containerWidth) {
                    break;
                }
                index++;
            }

            // Set the truncated text (with ellipsis if needed)
            let truncated = words.slice(0, index).join(" ");
            // Clean up the temporary span
            document.body.removeChild(tempSpan);
            // If the text is truncated and was cut off, add ellipsis
            if (words.length > index) {
                truncated = `${truncated}...`;
            }
            return truncated;
        }
        return formattedText;
    };
    useEffect(() => {
        const newTruncatedText: Map<string, string> = new Map();
        data.map((template) => {
            newTruncatedText.set(template.id, truncateTextToFit(template));
        });
        setPreviewText(newTruncatedText);
    }, [data, containerRef?.current?.offsetWidth]);

    return (
        <div>
            <Box mt={"5%"} ml={"15%"} mr={"15%"}>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                        <h2 className="text-2xl font-semibold">Templates</h2>
                        <p className="text-sm text-gray11 mb-6">
                            Manage your data
                        </p>
                    </div>
                    <TemplatesDialog
                        type={"Create"}
                        triggerElement={
                            <DialogTriggerButton>
                                Add Template
                                <PlusIcon />
                            </DialogTriggerButton>
                        }
                        teams={teams}
                        refetchTemplates={refetch}
                        currentTeam={currentTeam}
                    />
                </div>
                {isLoading && (
                    <Skeleton>
                        <Text>Loading...</Text>
                    </Skeleton>
                )}

                {isError && (
                    <Callout.Root color="red">
                        <Callout.Text>
                            Failed to load data. Please try again later.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {!isLoading && !isError && data.length === 0 && (
                    <Callout.Root color="blue">
                        <Callout.Text>
                            No data available. Please add one.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {!isLoading && !isError && data.length > 0 && (
                    <div className="space-y-4 w-full">
                        {data.map((template) => (
                            <Card
                                key={template.id}
                                className="p-4 shadow-none w-full flex flex-col gap-2"
                            >
                                <ContextMenu>
                                    <ContextMenuTrigger>
                                        <div className="flex flex-col gap-0.5 pt-1 px-1">
                                            <div className="flex justify-between items-center">
                                                <div className="font-semibold text-sm flex items-center gap-2 mr-8">
                                                    {template.name}
                                                </div>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex items-center gap-2.5">
                                                        {template.teams
                                                            .length === 0 ? (
                                                            <Badge
                                                                variant="outline"
                                                                className="gap-1 py-1 px-1.5 text-[11px] font-normal"
                                                            >
                                                                <div className="flex items-center justify-center rounded-lg p-1 bg-blue3 border border-blue4 shadow-sm">
                                                                    <HotelIcon
                                                                        className="text-blue9"
                                                                        strokeWidth={
                                                                            1.5
                                                                        }
                                                                        size={
                                                                            10
                                                                        }
                                                                    />
                                                                </div>
                                                                Workspace
                                                            </Badge>
                                                        ) : (
                                                            template.teams.map(
                                                                (team) => (
                                                                    <Badge
                                                                        variant="outline"
                                                                        className="gap-1 py-1 px-1.5 text-[11px] font-normal"
                                                                        key={
                                                                            team.id
                                                                        }
                                                                    >
                                                                        <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                            <UsersIcon
                                                                                className="text-red9"
                                                                                strokeWidth={
                                                                                    1.5
                                                                                }
                                                                                size={
                                                                                    10
                                                                                }
                                                                            />
                                                                        </div>
                                                                        {
                                                                            team?.team_name
                                                                        }
                                                                    </Badge>
                                                                ),
                                                            )
                                                        )}
                                                    </div>
                                                    {isOpen.get(template.id) ? (
                                                        <Button
                                                            type="button"
                                                            variant="ghost"
                                                            className="text-xs p-0.5"
                                                            onClick={() =>
                                                                updateIsOpen(
                                                                    template,
                                                                )
                                                            }
                                                        >
                                                            <CaretUpIcon className="w-4 h-4" />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            variant="ghost"
                                                            className="text-xs p-0.5"
                                                            onClick={() =>
                                                                updateIsOpen(
                                                                    template,
                                                                )
                                                            }
                                                        >
                                                            <CaretDownIcon className="w-4 h-4" />
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            {isOpen.get(template.id) ? (
                                                <LexicalComposer
                                                    initialConfig={
                                                        templatesInitialConfig
                                                    }
                                                >
                                                    <Editor
                                                        handleEditorChanges={(
                                                            markdwn: string,
                                                        ) => { }}
                                                        initialText={
                                                            template.template
                                                        }
                                                        className="col-span-4 border rounded-sm min-h-[250px]"
                                                        enableToolbar={false}
                                                    />
                                                </LexicalComposer>
                                            ) : (
                                                <div ref={containerRef}>
                                                    <Markdown
                                                        remarkPlugins={[
                                                            remarkGfm,
                                                            remarkEmoji,
                                                        ]}
                                                        rehypePlugins={[
                                                            rehypeRaw,
                                                        ]}
                                                        className="text-xs text-gray-500 overflow-ellipsis whitespace-nowrap overflow-hidden"
                                                        components={{
                                                            a: ({
                                                                node,
                                                                ...props
                                                            }) => (
                                                                <a
                                                                    {...props}
                                                                    style={{
                                                                        textDecoration:
                                                                            "underline",
                                                                        color: "#5B5BD6",
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    >
                                                        {renderVariables(
                                                            previewText.get(
                                                                template.id,
                                                            ) ??
                                                            cleanText(
                                                                template.template,
                                                            ),
                                                        )}
                                                    </Markdown>
                                                </div>
                                            )}
                                        </div>
                                    </ContextMenuTrigger>
                                    <ContextMenuContent className="w-60">
                                        <TemplatesDialog
                                            type={"Edit"}
                                            triggerElement={
                                                <ContextMenuItem
                                                    className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-2"
                                                    onSelect={(e) =>
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <Pencil2Icon className="w-3.5 h-3.5" />
                                                    Edit
                                                </ContextMenuItem>
                                            }
                                            teams={teams}
                                            refetchTemplates={refetch}
                                            editingObject={template}
                                            currentTeam={currentTeam}
                                        />
                                        {id ? (
                                            template.teams.length === 1 &&
                                                template.teams[0].id === id ? (
                                                <TemplatesDialog
                                                    type={"Delete"}
                                                    triggerElement={
                                                        <ContextMenuItem
                                                            onSelect={(e) =>
                                                                e.preventDefault()
                                                            }
                                                            className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                        >
                                                            <TrashIcon className="w-4 h-4" />
                                                            Delete
                                                        </ContextMenuItem>
                                                    }
                                                    teams={teams}
                                                    refetchTemplates={refetch}
                                                    editingObject={template}
                                                    currentTeam={currentTeam}
                                                />
                                            ) : (
                                                <TemplatesDialog
                                                    type={"Delete"}
                                                    triggerElement={
                                                        <ContextMenuItem
                                                            onSelect={(e) =>
                                                                e.preventDefault()
                                                            }
                                                            className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                        >
                                                            <LinkBreak1Icon className="w-3.5 h-3.5" />
                                                            Remove From Team
                                                        </ContextMenuItem>
                                                    }
                                                    teams={teams}
                                                    refetchTemplates={refetch}
                                                    editingObject={template}
                                                    currentTeam={currentTeam}
                                                    noDialog={true}
                                                />
                                            )
                                        ) : (
                                            <TemplatesDialog
                                                type={"Delete"}
                                                triggerElement={
                                                    <ContextMenuItem
                                                        onSelect={(e) =>
                                                            e.preventDefault()
                                                        }
                                                        className="text-[13px] rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                    >
                                                        <TrashIcon className="w-4 h-4" />
                                                        Delete
                                                    </ContextMenuItem>
                                                }
                                                teams={teams}
                                                refetchTemplates={refetch}
                                                editingObject={template}
                                                currentTeam={currentTeam}
                                            />
                                        )}
                                    </ContextMenuContent>
                                </ContextMenu>
                            </Card>
                        ))}
                    </div>
                )}
            </Box>
        </div>
    );
};

export default TemplatesPage;
