import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import { useToast } from "@/component/shadcn/ui/use-toast";
import { URLS } from "@/constant";
import { ContactsAPI } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Account } from "@/interfaces/serverData";
import { notesInitialConfig } from "@/pages/WorkspacePreferences/constants";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { Flex } from "@radix-ui/themes";
import { useState } from "react";
import Markdown from "react-markdown";
import { Editor } from "src/pages/WorkspacePreferences/TemplatesEditor";

export interface NotesProps {
    orgID: string;
    userID: string;
    accountProp: Account;
    accountType: "Company" | "Customer";
}

export const NotesPage = ({
    orgID,
    userID,
    accountProp,
    accountType,
}: NotesProps) => {
    const api = useApi();
    const [isEditing, setIsEditing] = useState(false);
    const [notes, setNotes] = useState<string>(accountProp.notes ?? "");
    const { toast } = useToast();
    const handleSave = async () => {
        const { url } = ContactsAPI.editCompanyNotes;
        await api
            .post(`${URLS.serverUrl}${url}/${accountProp.id}`, {
                notes: notes,
            })
            .then(() => {
                toast({
                    title: "Notes saved successfully!",
                    description: "Your notes have been saved successfully.",
                });
                setIsEditing(false);
            })
            .catch((error) => {
                toast({
                    title: "Failed to save notes",
                    description: "Please try again at a later time.",
                    variant: "destructive",
                });
                console.error("Error saving notes:", error);
            });
    };

    const handleEditorChanges = (markdwn: string) => {
        setNotes(markdwn);
    };

    return (
        <Flex direction="column" gap="3">
            {!accountProp.notes || isEditing ? (
                <LexicalComposer initialConfig={notesInitialConfig}>
                    <Editor
                        handleEditorChanges={handleEditorChanges}
                        initialText={accountProp.notes}
                        className="col-span-4 border rounded-sm min-h-[250px]"
                        source="Notes"
                    />
                </LexicalComposer>
            ) : (
                <Card className="p-4 shadow-none rounded-lg">
                    <Markdown
                        className="text-xs text-gray-500"
                        components={{
                            a: ({ node, ...props }) => (
                                <a
                                    {...props}
                                    style={{
                                        textDecoration: "underline",
                                        color: "#5B5BD6",
                                    }}
                                />
                            ),
                            code(props) {
                                const { children } = props;
                                return (
                                    <code className="text-balance">
                                        {children}
                                    </code>
                                );
                            },
                            ul: ({ children }) => (
                                <ul className="list-disc pl-5">{children}</ul> // Unordered list with bullets
                            ),
                            ol: ({ children }) => (
                                <ol className="list-decimal pl-5">
                                    {children}
                                </ol> // Ordered list with numbers
                            ),
                            li: ({ children }) => (
                                <li className="ml-2">{children}</li> // Spacing for list items
                            ),
                            p: ({ children }) => (
                                <p className="leading-tight">{children}</p> // For paragraphs (if needed)
                            ),
                        }}
                    >
                        {notes}
                    </Markdown>
                </Card>
            )}

            <div className="flex justify-end mb-4">
                {!accountProp.notes ? (
                    <div className="flex gap-2">
                        <Button
                            className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                            size="sm"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                ) : !isEditing ? (
                    <Button
                        className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                        size="sm"
                        variant="outline"
                        onClick={() => setIsEditing(true)}
                    >
                        Edit Notes
                    </Button>
                ) : (
                    <div className="flex gap-2">
                        <Button
                            className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                            size="sm"
                            variant="outline"
                            onClick={() => {
                                setIsEditing(false);
                                setNotes("");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                            size="sm"
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>
        </Flex>
    );
};
