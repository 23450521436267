import { useNavigate } from "react-router-dom";
import { pages } from "../constant";
import logo from "../images/logo.png";

function NavBarComponent({ state = pages.login }) {
    const navigate = useNavigate();
    const handleLogoClick = () => {
        navigate("/"); // Navigate to homepage
    };
    return (
        <div className="flex justify-between items-center p-4">
            <div>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <img
                    src={logo}
                    alt="Assembly."
                    style={{ width: "6rem", height: "auto" }}
                    onClick={handleLogoClick}
                />
            </div>
        </div>
    );
}

export default NavBarComponent;
