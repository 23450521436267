import "@blocknote/mantine/style.css";
import "@blocknote/mantine/style.css";
import "@blocknote/mantine/style.css";
import { Liveblocks } from "@liveblocks/node";
import { LiveblocksProvider } from "@liveblocks/react";
import {
    type WithAuthInfoProps,
    useAuthInfo,
    useRedirectFunctions,
    withRequiredAuthInfo,
} from "@propelauth/react";
import { RedirectToLogin } from "@propelauth/react";
import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Route, Routes, useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { InsightListType } from "./Insights/useInsightsData";
import { NAVBAR_ITEMS } from "./component/SideBar/SideBarComponent";
import { NAVBAR_PREFERENCES_ITEMS } from "./component/SideBar/WorkspaceSideBarComponent";
import UnauthorizedDialog from "./component/UnauthorizedDialog";
import { SidebarProvider } from "./component/shadcn/ui/sidebar";
import { loadingTypes } from "./constant";
import AdminAnalyticsPage from "./pages/Admin/AdminAnalyticsPage";
import AdminInsightsPage from "./pages/Admin/AdminInsightsPage";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import AdminQueriesPage, {
    IssueListType,
} from "./pages/Admin/AdminQueriesPage";
import AdminTicketPage from "./pages/Admin/AdminTicketPage";
import AnnouncementsPage from "./pages/Admin/AnnouncementsPage";
import BotPage from "./pages/Admin/BotPage";
import { AccountsListType, AccountsPage } from "./pages/Admin/CRM/Accounts";
import { CompanyPage } from "./pages/Admin/CRM/CompanyPage";
import { CustomerPage } from "./pages/Admin/CRM/CustomerPage";
import GitHubIndexingPage from "./pages/Admin/GitHubIndexingPage";
import InsightDisplayPage from "./pages/Admin/InsightDisplayPage";
import ChatWidgetIntegration from "./pages/Admin/Integrations/ChatWidgetIntegration";
import CommunitySlackIntegration from "./pages/Admin/Integrations/CommunitySlackIntegration";
import ConfluenceIntegration from "./pages/Admin/Integrations/ConfluenceIntegration";
import DiscordIntegration from "./pages/Admin/Integrations/DiscordIntegration";
import GitHubIntegration from "./pages/Admin/Integrations/GitHubIntegration";
import GitHubTicketIntegration from "./pages/Admin/Integrations/GitHubTicketIntegration";
import GoogleIntegration from "./pages/Admin/Integrations/GoogleIntegration";
import { HubSpotIntegration } from "./pages/Admin/Integrations/HubSpotIntegration";
import IntercomIntegration from "./pages/Admin/Integrations/IntercomIntegration";
import JiraIntegration from "./pages/Admin/Integrations/JiraIntegration";
import LinearIntegration from "./pages/Admin/Integrations/LinearIntegration";
import NotionIntegration from "./pages/Admin/Integrations/NotionIntegration";
import PRIntegration from "./pages/Admin/Integrations/PRIntegration";
import SlackIntegration from "./pages/Admin/Integrations/SlackIntegration";
import WebIntegration from "./pages/Admin/Integrations/WebIntegration";
import AddScopesIndexingPage from "./pages/Admin/IntegrationsIndexing/AddScopesIndexingPage";
import IntegrationsIndexingPage from "./pages/Admin/IntegrationsIndexing/IntegrationsIndexingPage";
import PRIndexingPage from "./pages/Admin/IntegrationsIndexing/PRIndexingPage";
import ReIndexingPage from "./pages/Admin/IntegrationsIndexing/ReindexPage";
import TokenPage from "./pages/Admin/IntegrationsIndexing/TokenPage";
import IntegrationsManagementPage from "./pages/Admin/IntegrationsManagementPage";
import UpdateScopesPage from "./pages/Admin/UpdateScopesListeningPage";
import { AnnouncementsEditingPage } from "./pages/Announcements/AnnouncementsEditingPage";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import { SearchProvider } from "./pages/SearchContext";
import SearchPage from "./pages/SearchPage";
import { NewView } from "./pages/User/NewView";
import { ViewViewer } from "./pages/User/ViewViewer";
import { APIKeyManagement } from "./pages/WorkspacePreferences/APIKeys";
import CustomerGroups from "./pages/WorkspacePreferences/CustomerGroups";
import LabelsPage from "./pages/WorkspacePreferences/Labels";
import Members from "./pages/WorkspacePreferences/Members";
import NewTeam from "./pages/WorkspacePreferences/NewTeam";
import NotificationPreferences from "./pages/WorkspacePreferences/Notifications";
import Profile from "./pages/WorkspacePreferences/Profile";
import SLAs from "./pages/WorkspacePreferences/SLAs";
import Settings from "./pages/WorkspacePreferences/Settings";
import Team from "./pages/WorkspacePreferences/Team";
import TeamSettings from "./pages/WorkspacePreferences/TeamSettings";
import TemplatesPage from "./pages/WorkspacePreferences/Templates";
import Views from "./pages/WorkspacePreferences/Views";
import WorkflowManagement from "./pages/WorkspacePreferences/Workflows";
import NewWorkflow from "./pages/WorkspacePreferences/Workflows/NewWorkflow";
import WorkflowsPage from "./pages/WorkspacePreferences/Workflows/WorkflowsPage";
import AssemblyToastProvider from "./reusable_components/actions/ToastProvider";
import { SalesforceIntegration } from "./pages/Admin/Integrations/SalesforceIntegration";

const AdminIndexingRoutes = () => (
    <Routes>
        <Route path="pullrequest" element={<PRIndexingPage />} />
        <Route path="web" element={<PRIndexingPage />} />
        <Route path="add/*" element={<AddScopesIndexingPage />} />
        <Route path="reindex" element={<ReIndexingPage />} />
        <Route path="linear" element={<TokenPage />} />
        <Route path="jira" element={<TokenPage />} />
        <Route path="confluence" element={<TokenPage />} />
        <Route path="slack" element={<TokenPage />} />
        <Route path="google" element={<TokenPage />} />
        <Route path="*" element={<IntegrationsIndexingPage />} />
        <Route path="githubticket" element={<GitHubIndexingPage />} />
        <Route path="code" element={<GitHubIndexingPage />} />
        <Route path="discord" element={<BotPage />} />
        <Route path="hubspot" element={<TokenPage />} />
        <Route path="salesforce" element={<TokenPage />} />
        <Route path="intercom" element={<TokenPage />} />
        <Route path="update/*" element={<UpdateScopesPage />} />
    </Routes>
);

const AnnouncementsRoutes = () => (
    <Routes>
        <Route path="" element={<AnnouncementsPage />} />
        <Route path="new/:id" element={<AnnouncementsEditingPage />} />
        <Route path="/:id" element={<AnnouncementsEditingPage />} />
    </Routes>
);

interface RouteToViewPageProps {
    orgName: string;
    isAdmin: boolean;
}

const RouteToViewPage: React.FC<RouteToViewPageProps> = ({
    orgName,
    isAdmin,
}) => {
    const { id } = useParams<{ id: string }>();

    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to="/issues" />;
    }
    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={`${NAVBAR_ITEMS.savedView}_${id}`}
            withScroll={false}
            viewID={id}
        >
            <ViewViewer />
        </HomePage>
    );
};

interface RouteToTeamPageProps {
    orgName: string;
    orgID: string;
    isAdmin: boolean;
    userID: string;
    type: string;
}

const RouteToTeamPage: React.FC<RouteToTeamPageProps> = ({
    orgName,
    orgID,
    isAdmin,
    userID,
    type,
}) => {
    const { id } = useParams<{ id: string }>();
    const renderTeamContent = (type: string) => {
        switch (type) {
            case "issues":
                return (
                    <AdminQueriesPage
                        userID={userID}
                        listType={IssueListType.Team}
                        tableView={true}
                        hidePopup={false}
                        tempTeamId={id}
                    />
                );
            case "insights":
                return (
                    <AdminInsightsPage
                        userID={userID}
                        listType={InsightListType.Team}
                        tempTeamId={id}
                    />
                );
            case "analytics":
                return (
                    <AdminAnalyticsPage
                        listType={InsightListType.Team}
                        tempTeamID={id}
                    />
                );
            case "accounts":
                return (
                    <CRMRoutes
                        org_id={orgID}
                        userID={userID}
                        listType={AccountsListType.Team}
                    />
                );
            default:
                return (
                    <AdminQueriesPage
                        userID={userID}
                        listType={IssueListType.Team}
                        tableView={true}
                        hidePopup={false}
                    />
                );
        }
    };
    const getNavBarState = (type: string) => {
        switch (type) {
            case "issues":
                return NAVBAR_ITEMS.queries;
            case "insights":
                return NAVBAR_ITEMS.insights;
            case "analytics":
                return NAVBAR_ITEMS.analytics;
            default:
                return NAVBAR_ITEMS.queries;
        }
    };

    const [activeTeam, setActiveTeam] = useState<string>(id ?? "");
    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to="/issues" />;
    }
    return (
        <SearchProvider>
            <HomePage
                organizationName={orgName}
                isAdmin={isAdmin}
                navBarState={getNavBarState(type)}
                withScroll={false}
                activeTeam={activeTeam}
                setActiveTeam={setActiveTeam}
            >
                {renderTeamContent(type)}
            </HomePage>
        </SearchProvider>
    );
};

interface CRMRoutesProps {
    org_id: string;
    userID: string;
    listType: AccountsListType;
}

// /accounts/*
const CRMRoutes = ({ org_id, userID, listType }: CRMRoutesProps) => (
    <Routes>
        <Route
            path="/:id"
            element={
                <CompanyPage
                    userID={userID}
                    orgID={org_id}
                    listType={listType}
                />
            }
        />
        <Route
            path="/:id/onboarding"
            element={
                <CompanyPage
                    userID={userID}
                    orgID={org_id}
                    listType={listType}
                    tab="onboarding"
                />
            }
        />
        <Route
            path="/:company_id/:customer_id"
            element={
                <CustomerPage
                    userID={userID}
                    orgID={org_id}
                    listType={listType}
                />
            }
        />
        <Route
            path="/individual/:customer_id"
            element={
                <CustomerPage
                    userID={userID}
                    orgID={org_id}
                    listType={listType}
                />
            }
        />
        <Route
            path=""
            element={
                <AccountsPage
                    orgID={org_id}
                    userID={userID}
                    listType={listType}
                />
            }
        />
    </Routes>
);

const AdminRoutes = () => (
    <Routes>
        <Route path="code" element={<GitHubIntegration />} />
        <Route path="web" element={<WebIntegration />} />
        <Route path="jira" element={<JiraIntegration />} />
        <Route path="confluence" element={<ConfluenceIntegration />} />
        <Route path="google" element={<GoogleIntegration />} />
        <Route path="slack" element={<SlackIntegration />} />
        <Route path="communityslack" element={<CommunitySlackIntegration />} />
        <Route path="linear" element={<LinearIntegration />} />
        <Route path="pullrequest" element={<PRIntegration />} />
        <Route path="notion" element={<NotionIntegration />} />
        <Route path="discord" element={<DiscordIntegration />} />
        <Route path="chatwidget" element={<ChatWidgetIntegration />} />
        <Route path="githubticket" element={<GitHubTicketIntegration />} />
        <Route path="hubspot" element={<HubSpotIntegration />} />
        <Route path="salesforce" element={<SalesforceIntegration />} />
        <Route path="intercom" element={<IntercomIntegration />} />
        <Route path="api" element={<APIKeyManagement />} />
    </Routes>
);

const PreferencesRoutes = () => (
    <Routes>
        <Route path="teams/new" element={<NewTeam />} />
        <Route path="teams/:id" element={<Team />} />
        <Route path="teams/:id/settings" element={<TeamSettings />} />
        <Route path="teams/:id/labels" element={<LabelsPage />} />
        <Route path="teams/:id/templates" element={<TemplatesPage />} />
        <Route path="teams/:id/views" element={<Views />} />
        <Route
            path="teams/:id/notifications"
            element={<NotificationPreferences />}
        />
    </Routes>
);

interface RouteToTicketPageProps {
    orgName: string;
    isAdmin: boolean;
    userID: string;
}

const RouteToTicketPage: React.FC<RouteToTicketPageProps> = ({
    orgName,
    isAdmin,
    userID,
}) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const from =
        location.state?.from || sessionStorage.getItem("from") || "issues";

    const { team_id } = useParams<{ team_id: string }>();
    const { view_id } = useParams<{ view_id: string }>();
    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to={`/${from}`} />;
    }

    let listType: IssueListType;
    let navbar: string;
    switch (from) {
        case "inbox":
            listType = IssueListType.Inbox;
            navbar = NAVBAR_ITEMS.inbox;
            break;
        case "issues":
            listType = IssueListType.Issues;
            navbar = NAVBAR_ITEMS.queries;
            break;
        case "team":
            listType = IssueListType.Team;
            navbar = NAVBAR_ITEMS.queries;
            break;
        case "view":
            listType = IssueListType.View;
            navbar = `${NAVBAR_ITEMS.savedView}_${view_id}`;
            break;
        default:
            listType = IssueListType.Issues;
            navbar = NAVBAR_ITEMS.queries;
    }
    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={navbar}
            activeTeam={team_id}
            viewID={view_id}
        >
            <AdminTicketPage
                identifier={id}
                userID={userID}
                listType={listType}
                teamID={team_id}
                viewID={view_id}
            />
        </HomePage>
    );
};

interface RouteToInsightPageProps {
    orgName: string;
    isAdmin: boolean;
    userID: string;
}

const RouteToInsightPage: React.FC<RouteToInsightPageProps> = ({
    orgName,
    isAdmin,
    userID,
}) => {
    const { id } = useParams<{ id: string }>();
    const { team_id } = useParams<{ team_id: string }>();
    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to={"/insights"} />;
    }

    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={NAVBAR_ITEMS.insights}
            withScroll={false}
            activeTeam={team_id}
        >
            <InsightDisplayPage
                id={id}
                userID={userID}
                listType={
                    team_id ? InsightListType.Team : InsightListType.General
                }
                teamID={team_id}
            />
        </HomePage>
    );
};

const queryClient = new QueryClient();

const App = withRequiredAuthInfo((props: WithAuthInfoProps) => {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [orgName, setOrgName] = useState<string>("");
    const [orgID, setOrgID] = useState<string>("");
    const [userID, setUserID] = useState<string>("");

    const { loading, isLoggedIn, user, orgHelper } = useAuthInfo();

    const { redirectToLoginPage } = useRedirectFunctions();
    const [appLoading, setLoading] = useState<number>(loadingTypes.loading);
    // only run and check once someone has logged in to our system
    const liveblocks = new Liveblocks({
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        secret: process.env.REACT_APP_LIVEBLOCKS_SECRET_KEY!,
    });

    useLayoutEffect(() => {
        if (!props.isLoggedIn) {
            return;
        }
        if (props.accessHelper === undefined || props.orgHelper === undefined) {
            navigate("/*");
            return;
        }

        const orgs = props.orgHelper.getOrgs();
        if (orgs === undefined || orgs.length !== 1) {
            navigate("/*");
            return;
        }
        const orgId = orgs[0].orgId;
        console.log("orgid is", orgID);
        setOrgID(orgId);
        const name = orgs[0].orgName;
        setOrgName(name);

        const isAdminTemp = props.accessHelper.isAtLeastRole(orgId, "Admin");
        const isOwnerTemp = props.accessHelper.isAtLeastRole(orgId, "Owner");

        setIsAdmin(isAdminTemp || isOwnerTemp);
        setUserID(props.user.userId);
        setLoading(loadingTypes.loaded);
    }, [
        props.accessHelper,
        props.orgHelper,
        props.isLoggedIn,
        props.user,
        navigate,
    ]);

    useEffect(() => {
        if (!props.isLoggedIn) {
            const options = {
                postLoginRedirectUrl: `${process.env.REACT_APP_CALLBACK_URL}admin/inbox`,
            };
            redirectToLoginPage(options);
        }
        setLoading(loadingTypes.loaded);
    }, [props, redirectToLoginPage]);

    if (props.isLoggedIn) {
        return (
            <LiveblocksProvider
                authEndpoint={async (room) => {
                    // Start an auth session inside your endpoint
                    if (loading === false && isLoggedIn) {
                        const session = liveblocks.prepareSession(
                            user.userId,
                            {
                                userInfo: {
                                    name: user.firstName,
                                    picture: user.pictureUrl,
                                },
                            }, // Optional
                        );
                        const orgs = orgHelper.getOrgs();
                        if (orgs === undefined || orgs.length !== 1) {
                            return {
                                error: "forbidden",
                                reason: "Invalid organization or user not part of exactly one organization.",
                            };
                        }
                        const orgId = orgs[0].orgId;

                        session.allow(
                            `${orgId}:announcements:*`,
                            session.FULL_ACCESS,
                        );

                        // Authorize the user and return the result
                        const { status, body: token } =
                            await session.authorize();

                        return new Response(token, { status }).json();
                    } else {
                        return {
                            error: "forbidden",
                            reason: "User is not logged in or loading is not complete.",
                        };
                    }
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <SidebarProvider>
                        <UnauthorizedDialog />
                        {appLoading === loadingTypes.loaded && (
                            <Routes>
                                <Route
                                    path=""
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.inbox
                                                }
                                                withScroll={false}
                                            >
                                                <AdminQueriesPage
                                                    userID={userID}
                                                    listType={
                                                        IssueListType.Inbox
                                                    }
                                                    tableView={true}
                                                    hidePopup={false}
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="my_accounts"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.myAccounts
                                                }
                                                withScroll={true}
                                            >
                                                <AccountsPage
                                                    orgID={orgID}
                                                    userID={userID}
                                                    listType={
                                                        AccountsListType.Inbox
                                                    }
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/admin_login"
                                    element={<AdminLoginPage />}
                                />
                                <Route
                                    path="/admin/integrations/*"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.integrations
                                                }
                                            >
                                                <AdminIndexingRoutes />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/admin/manage_integrations/*"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.integrations
                                                }
                                                isWorkspace={true}
                                            >
                                                <AdminRoutes />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/admin/manage_integrations"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/inbox" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.integrations
                                                    }
                                                    isWorkspace={true}
                                                >
                                                    <IntegrationsManagementPage />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />
                                <Route
                                    path="/views/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToViewPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/views/create"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.queries
                                                }
                                                withScroll={false}
                                            >
                                                <NewView />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/announcements/*"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.announcements
                                                }
                                            >
                                                <AnnouncementsRoutes />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/analytics"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.analytics
                                                }
                                            >
                                                <AdminAnalyticsPage
                                                    listType={
                                                        InsightListType.General
                                                    }
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/accounts/*"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.crm
                                                }
                                                withScroll={true}
                                            >
                                                <CRMRoutes
                                                    org_id={orgID}
                                                    userID={userID}
                                                    listType={
                                                        AccountsListType.Accounts
                                                    }
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/insights"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.insights
                                                }
                                                withScroll={false}
                                            >
                                                <AdminInsightsPage
                                                    userID={userID}
                                                    listType={
                                                        InsightListType.General
                                                    }
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/teams/:id/insights"
                                    element={
                                        <RouteToTeamPage
                                            orgName={orgName}
                                            orgID={orgID}
                                            isAdmin={isAdmin}
                                            userID={userID}
                                            type="insights"
                                        />
                                    }
                                />
                                <Route
                                    path="/teams/:id/analytics"
                                    element={
                                        <RouteToTeamPage
                                            orgName={orgName}
                                            orgID={orgID}
                                            isAdmin={isAdmin}
                                            userID={userID}
                                            type="analytics"
                                        />
                                    }
                                />
                                <Route
                                    path="/insight/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToInsightPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/teams/:team_id/insight/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToInsightPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/issues"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.queries
                                                }
                                                withScroll={false}
                                            >
                                                <AdminQueriesPage
                                                    userID={userID}
                                                    listType={
                                                        IssueListType.Issues
                                                    }
                                                    tableView={true}
                                                    hidePopup={false}
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/teams/:id/issues"
                                    element={
                                        <RouteToTeamPage
                                            orgName={orgName}
                                            orgID={orgID}
                                            isAdmin={isAdmin}
                                            userID={userID}
                                            type="issues"
                                        />
                                    }
                                />
                                <Route
                                    path="/teams/:id/accounts/*"
                                    element={
                                        <RouteToTeamPage
                                            orgName={orgName}
                                            orgID={orgID}
                                            isAdmin={isAdmin}
                                            userID={userID}
                                            type="accounts"
                                        />
                                    }
                                />
                                <Route
                                    path="/inbox"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.inbox
                                                }
                                                withScroll={false}
                                            >
                                                <AdminQueriesPage
                                                    userID={userID}
                                                    listType={
                                                        IssueListType.Inbox
                                                    }
                                                    tableView={true}
                                                    hidePopup={false}
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/issue/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToTicketPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/teams/:team_id/issue/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToTicketPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/views/:view_id/issue/:id"
                                    element={
                                        <SearchProvider>
                                            <RouteToTicketPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/search"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_ITEMS.search
                                                }
                                            >
                                                <SearchPage
                                                    isAdmin={isAdmin}
                                                />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/preferences/*"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_PREFERENCES_ITEMS.profile
                                                }
                                                orgID={orgID}
                                                isWorkspace={true}
                                            >
                                                <PreferencesRoutes />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/preferences/profile"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_PREFERENCES_ITEMS.profile
                                                }
                                                orgID={orgID}
                                                isWorkspace={true}
                                            >
                                                <Profile />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />

                                <Route
                                    path="/preferences/labels"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.profile
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <LabelsPage />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/templates"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.profile
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <TemplatesPage />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/customergroups"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/customergroups" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.customergroups
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <CustomerGroups />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/manage_integrations/api"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.apiKeys
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <APIKeyManagement />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/notifications"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.profile
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <NotificationPreferences />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/members"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.members
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <Members
                                                        org_id={orgID}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/sla"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.sla
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <SLAs orgID={orgID} />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/workflows_old"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.workflows
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <WorkflowManagement />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/workflows"
                                    element={
                                        <SearchProvider>
                                            <HomePage
                                                organizationName={
                                                    orgName
                                                }
                                                isAdmin={isAdmin}
                                                navBarState={
                                                    NAVBAR_PREFERENCES_ITEMS.workflows
                                                }
                                                orgID={orgID}
                                                isWorkspace={true}
                                                withScroll={false}
                                            >
                                                <WorkflowsPage isAdmin={isAdmin} />
                                            </HomePage>
                                        </SearchProvider>
                                    }
                                />
                                <Route
                                    path="/preferences/workflows/create"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.workflows
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <NewWorkflow />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="/preferences/settings"
                                    element={
                                        !isAdmin ? (
                                            <Navigate to="/preferences/profile" />
                                        ) : (
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={
                                                        orgName
                                                    }
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.settings
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <Settings />
                                                </HomePage>
                                            </SearchProvider>
                                        )
                                    }
                                />

                                <Route
                                    path="*"
                                    element={<NotFound />}
                                />
                            </Routes>
                        )}
                        <AssemblyToastProvider />
                    </SidebarProvider>
                </QueryClientProvider>
            </LiveblocksProvider>
        );
    } else {
        return (
            <RedirectToLogin
                postLoginRedirectUrl={`${process.env.REACT_APP_CALLBACK_URL}search`}
            />
        );
    }
});

export default App;
